import { ClientRecordType, CommunicationPreference, DvaCardType, ProfileType } from './enums';

interface ClientKeyContact {
  _id: string;
  firstName?: string;
  lastName?: string;
  mobileNumber?: string;
  email?: string;
  tags?: string[];
}

export interface MedicareStatus {
  code: number;
  message?: string;
}

export interface MedicareInformation {
  number?: number;
  irn?: number;
  dva?: string;
  dvaCardDetails?: {
    type?: DvaCardType;
    expiryDate?: string;
  };
  firstName: string;
  lastName: string;
  dateOfBirth?: string;
  expiryDate?: string;
  status?: {
    medicare?: MedicareStatus;
    dva?: MedicareStatus;
  };
}

export enum ReferrerType {
  Self = 'self',
  FriendFamily = 'friendFamily',
  Organisation = 'organisation',
  Professional = 'professional'
}

export interface ReferralDetail {
  files: {
    bucketName: string;
    fileName: string;
    fileUrl: string;
  }[];
  isReferredByGP: boolean;
  isHaveTreatmentPlan: boolean;
  name?: string;
  practiceName?: string;
  medicareProviderNumber?: string;
  generalPractitioner?: {
    name?: string;
    practiceName?: string;
    medicareProviderNumber?: string;
    email?: string; //new?
  };
  date?: string;
  isGPSentReferral?: boolean;
  addToReferralGroup?: boolean;
  treatmentPlanFiles?: {
    bucketName: string;
    fileName: string;
    fileUrl: string;
    treatmentPlanDate?: string;
  }[];

  //new fields
  firstName?: string;
  lastName?: string;
  relationship?: string;
  role?: string;
  mobile?: string;
  email?: string;

  expiryDate?: string;
}

export interface Address {
  line1?: string;
  line2?: string;
  state?: string;
  suburb?: string;
  postcode?: string;
  country?: string;
}

export interface PatientProfile {
  _id: string;
  recordType: ClientRecordType;
  avatar?: string;
  mobileNumber?: string;
  email?: string;
  name?: string;
  pronouns?: string;
  dateOfBirth?: string;
  firstName?: string;
  lastName?: string;
  medicare?: MedicareInformation;
  keyClientContacts?: ClientKeyContact[];
  referral?: ReferralDetail;
  timeZone?: string;
  isAccountMedicareEnabled?: boolean;
  isAccountSmpEnabled?: boolean;
  isIp6Enabled?: boolean;
  hasSavedCard?: boolean;
  gpSurveyCompleted?: boolean;
  isReferralSentByGP?: boolean;
  appointmentQuota?: number;
  employerCode?: string;
  renewalDate?: string;
  isEmergencyContactSetup?: boolean;
  address?: Address;
  isRiskScreeningComplete?: boolean;
  communicationPreference?: CommunicationPreference;
}

export interface ClientProfile {
  clientAuth0Id?: string;
  _id: string;
  createdAt: string;
  hashedMobileNumber: string;
  avatar?: string;
  initials: string;
  initialsBackgroundColor: string;
  isEmailThirdParty: boolean;
  isMobileNumberThirdParty: boolean;
  name: string;
  firstName: string;
  lastName: string;
  dateOfBirth?: string;
  onboardingSettings?: {
    facilitationUrl: string;
    firstAssessmentId: string;
    onboardingMethod: string;
  };
  profileType: ProfileType;
  signupInvitation?: {
    isSent: boolean;
    createdAt: string;
    inviteDate: string;
    sendAt: string;
    updatedAt: string;
    signupDate: string;
  };
  updatedAt: string;
  isPrimaryContact?: boolean;
  role?: string;
}

export interface ClientRecord {
  clientProfiles: ClientProfile[];
  communicationPreference?: CommunicationPreference;
  createdAt: string;
  recordStatus: 'active' | 'closed';
  recordType: ClientRecordType;
  referral: {
    source?: string;
    isReferredByGP?: boolean;
  };
  invoiceSummary?: {
    count: number;
    order: number;
    settings: {
      automatedInvoicing: {
        active: boolean;
        dueAt: number;
        includeAppointments: string;
        issueAt: string;
        note: string;
        sendToClient: boolean;
        type: string;
        sendToOther: any;
      };
      discount?: {
        active: boolean;
        type: 'percent' | 'amount';
        value: number;
      };
    };
  };
  updatedAt: string;
  _id: string;
  isCompleteRecords: boolean;
}
