/* eslint-disable jsx-a11y/anchor-is-valid */
import CheckBox from 'components/CheckBox/CheckBox';
import styles from './CommunicationPreference.module.scss';
import { brandConfig } from 'brand/config';

interface CommunicationPreferenceProps {
  values: { email: boolean; sms: boolean; policy: boolean };
  checkValidation: boolean;
  onChangeValue: (value: { email: boolean; sms: boolean; policy: boolean }) => void;
}

const CommunicationPreferenceForm = ({ values, checkValidation, onChangeValue }: CommunicationPreferenceProps) => {
  const { brand } = brandConfig;

  return (
    <div className={styles.container}>
      <div className={styles.checkBoxText}>
        <CheckBox
          id="email"
          className={styles.checkBox}
          label="I am happy to receive emails from the team"
          onChange={(e) => {
            onChangeValue({ ...values, email: e.target.checked });
          }}
          value={values.email}
        />
      </div>

      <div className={styles.checkBoxText}>
        <CheckBox
          id="sms"
          className={styles.checkBox}
          label="I am happy to receive SMS from the team"
          value={values.sms}
          onChange={(e) => {
            onChangeValue({ ...values, sms: e.target.checked });
          }}
        />
      </div>

      <div className={styles.policy}>
        <div className={styles.title}>Managing your information*</div>
        <div className={styles.desc}>
          We process your information securely in line with our <a href="#">privacy policy</a>{' '}
        </div>
      </div>

      <div className={styles.checkBoxText}>
        <CheckBox
          id="policy"
          className={styles.checkBox}
          label={`I agree to the ${brand} privacy policy`}
          value={values.policy}
          onChange={(e) => {
            onChangeValue({ ...values, policy: e.target.checked });
          }}
        />
      </div>
      {checkValidation && !values.policy && <div className={styles.error}>Please agree to the privacy policy</div>}
    </div>
  );
};

export default CommunicationPreferenceForm;
