import { combineReducers } from 'redux';
import { clinicianProfileServicesApiSlice } from './services/clinicianProfileServicesApiSlice';
import { checkInServicesApiSlice } from './services/checkInServicesApiSlice';
import { patientProfileServicesApiSlice } from './services/patientProfileServiceApisSlice';

const rootReducer = combineReducers({
  // add reducers here
  [checkInServicesApiSlice.reducerPath]: checkInServicesApiSlice.reducer,
  [clinicianProfileServicesApiSlice.reducerPath]: clinicianProfileServicesApiSlice.reducer,
  [patientProfileServicesApiSlice.reducerPath]: patientProfileServicesApiSlice.reducer
});

export default rootReducer;
