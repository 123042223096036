import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { security } from 'utils/security';

export enum PPSTagTypes {
  ClientRecord = 'clientRecord'
}

export const patientProfileServicesApiSlice = createApi({
  reducerPath: 'patientProfileServices',
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_PATIENT_PROFILE_SERVICE_URL,
    prepareHeaders: async (headers) => {
      const token = await security.getAccessTokenSilently().catch(() => '');
      if (token) {
        headers.set('Authorization', `Bearer ${token}`);
      }
      return headers;
    }
  }),
  tagTypes: [PPSTagTypes.ClientRecord],
  endpoints: () => ({})
});
