import Input from 'components/Input/Input';
import React, { useEffect, useMemo, useState } from 'react';
import styles from './MedicareForm.module.scss';
import medicare from 'assets/images/medicare.png';
import DateInput from 'components/DateInput/DateInput';
import { MedicareInformation } from 'interfaces/Client';
import { debounce } from 'lodash';
import { useGetAccessToken } from 'utils/hooks/useGetAccessToken';
import { usePutClientMedicareMutation } from 'reduxToolkit/endpoints/clinicianProfileService/client';
import VerificationStatus from './VerificationStatus/VerificationStatus';
import dayjs, { DateFormat } from 'utils/dayjsExtended';
import classNames from 'classnames';
import { DvaCardType } from 'interfaces/Client/enums';
import Select from 'components/Select/Select';

interface MedicareFormProps {
  medicareValue: MedicareInformation;
  onChangeValue: (data: MedicareInformation) => void;
  setIsMedicareError?: (value: boolean) => void;
  checkValidate?: boolean;
  showDva?: boolean;
}

const MedicareForm = ({ onChangeValue, medicareValue, setIsMedicareError, showDva }: MedicareFormProps) => {
  const [isMedicareValueChanged, setIsMedicareValueChanged] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [medicareErrorMessage, setMedicareErrorMessage] = useState<MedicareInformation['status']>();
  const [putClientMedicare] = usePutClientMedicareMutation();

  const { token } = useGetAccessToken();

  const handleFieldChange = (fieldName: keyof MedicareInformation, value: string | number | object) => {
    const updatedField = { ...medicareValue, [fieldName]: value };
    onChangeValue(updatedField);
    setIsMedicareValueChanged(true);
  };

  const saveMedicareInfo = async (medicare: MedicareInformation) => {
    setIsLoading(true);
    try {
      const data = await putClientMedicare({
        ...medicare,
        expiryDate: medicare.expiryDate,
        dva: medicare.dva,
        dateOfBirth: dayjs(medicare.dateOfBirth, DateFormat.DAY_MONTH_YEAR).format(DateFormat.YEAR_MONTH_DAY)
      }).unwrap();
      setMedicareErrorMessage(data?.status);
    } catch (error) {
      setIsMedicareError && setIsMedicareError(true);
      setMedicareErrorMessage({
        medicare: {
          code: 9633
        }
      });
      setIsLoading(false);
    }

    setIsMedicareValueChanged(false);
  };

  const callMedicareUpdating = useMemo(
    () =>
      debounce((formValues: MedicareInformation) => {
        if (token) {
          saveMedicareInfo(formValues);
        }
      }, 2000),
    // eslint-disable-next-line
    [token]
  );

  useEffect(() => {
    if (isMedicareValueChanged && medicareValue) {
      const { number, irn, expiryDate, dva } = medicareValue;
      if ((number && irn && expiryDate !== '') || dva) {
        setIsLoading(true);
        callMedicareUpdating(medicareValue);
      }
    }
    // eslint-disable-next-line
  }, [isMedicareValueChanged, medicareValue]);

  return (
    <div className={styles.container}>
      <div className={styles.fields}>
        <Input
          id="medicareFirstName"
          className={styles.fieldWrapper}
          label="First Name*"
          name="firstName"
          required
          labelClass={styles.inputLabel}
          onChange={(e) => {
            handleFieldChange('firstName', e.target.value);
          }}
        />
        <Input
          id="medicareLastName"
          className={styles.fieldWrapper}
          label="Last Name*"
          name="lastName"
          required
          labelClass={styles.inputLabel}
          onChange={(e) => {
            handleFieldChange('lastName', e.target.value);
          }}
        />

        <div className={styles.medicareInfo}>
          <Input
            className={styles.medicareNumber}
            id={'medicareNumber'}
            label={'Medicare Card Number'}
            onChange={(e) => handleFieldChange('number', parseInt(e.target.value, 10))}
          />
          <Input
            className={styles.irn}
            id={'irn'}
            label={'IRN'}
            onChange={(e) => handleFieldChange('irn', parseInt(e.target.value, 10))}
          />
        </div>

        <div className={styles.fieldWrapper}>
          <DateInput
            label={'Expiry Date'}
            value={medicareValue.expiryDate || ''}
            onChange={(e) => handleFieldChange('expiryDate', e)}
            format={DateFormat.MONTH_YEAR}
          />
        </div>

        {showDva && (
          <>
            <div className={styles.fieldContainer}>
              <Input
                label={'DVA File Number'}
                id={'dva'}
                onChange={(e) => {
                  handleFieldChange('dva', e.target.value);
                  setIsMedicareValueChanged?.(true);
                }}
                value={medicareValue.dva}
              />
            </div>

            <div className={classNames(styles.fieldContainer, styles.selectDropdown)}>
              <Select
                id={'dvaCardType'}
                label={'DVA Card Type'}
                isSearchable={false}
                options={Object.values(DvaCardType).map((val) => ({
                  label: val.toUpperCase(),
                  value: val
                }))}
                value={medicareValue.dvaCardDetails?.type || ''}
                onChange={(value) =>
                  handleFieldChange('dvaCardDetails', { ...medicareValue.dvaCardDetails, type: value })
                }
              />
            </div>

            <div className={classNames(styles.fieldContainer, styles.date)}>
              <DateInput
                format={DateFormat.MONTH_YEAR}
                label={'DVA expiry date'}
                id="dvaExpiryDate"
                value={medicareValue.dvaCardDetails?.expiryDate || ''}
                onChange={(value: string) => {
                  handleFieldChange('dvaCardDetails', {
                    ...medicareValue.dvaCardDetails,
                    expiryDate: value
                  });
                  setIsMedicareValueChanged?.(true);
                }}
              />
            </div>
          </>
        )}

        <div className={styles.medicareCheck}>
          <div className={styles.checkNumberStatus}>
            <VerificationStatus status={medicareErrorMessage} isLoading={isLoading} hideDesc />
          </div>
        </div>
      </div>

      <div className={styles.medicareImg}>
        <div className={styles.desc}>
          <i className={`material-icons ${styles.menuIcon}`}>info</i>How to read your Medicare card
        </div>
        <img className={styles.medicareImg} src={medicare} alt={'Medicare card Intro'} />
      </div>
    </div>
  );
};

export default MedicareForm;
