import { debounce } from 'lodash';
import { useEffect, useState } from 'react';

/**
 * @returns [window.innerWidth, window.innerHeight]
 */
export const useWindowSize = () => {
  const [size, setSize] = useState<[number, number]>([0, 0]);

  useEffect(() => {
    const handleResize = debounce(() => setSize([window.innerWidth, window.innerHeight]), 100);

    window.addEventListener('resize', handleResize);
    handleResize();
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return size;
};
