import { useEffect, useState } from 'react';
import { GetTokenSilentlyOptions, useAuth0 } from '@auth0/auth0-react';
import { useLocation } from 'react-router-dom';
import queryString from 'query-string';
import { jwtDecode } from 'jwt-decode';
import { retrieveUserSession } from 'utils/userSession';
import { brandConfig } from 'brand/config';

export interface FacilitatedTokenInterface {
  'https://tacklit.com/clientRecordId': string;
  'https://tacklit.com/clinicianId': string;
  'https://tacklit.com/initials': string;
  'https://tacklit.com/initialsBackgroundColor': string;
  'https://tacklit.com/senderName': string;
  'https://tacklit.com/childFirstName'?: string;
}

export const useGetAccessToken = (allowParameterToken?: boolean, skipLoginWithRedirect?: boolean) => {
  const [token, setToken] = useState('');
  const { user, getAccessTokenSilently, isAuthenticated, isLoading, loginWithRedirect } = useAuth0();
  const { search } = useLocation();
  const [userInfo, setUserInfo] = useState({
    clientRecordId: '',
    initials: '',
    initialsBackgroundColor: '',
    childFirstName: ''
  });
  const { token: queryParamToken } = queryString.parse(search) as { token?: string };

  const fetchAccessTokenAndInfo = async (options?: GetTokenSilentlyOptions) => {
    try {
      if (queryParamToken && allowParameterToken) {
        setToken(queryParamToken);
        const decodeObj = jwtDecode<FacilitatedTokenInterface>(queryParamToken);
        const clientRecordId = decodeObj['https://tacklit.com/clientRecordId'];
        const initials = decodeObj['https://tacklit.com/initials'];
        const initialsBackgroundColor = decodeObj['https://tacklit.com/initialsBackgroundColor'];
        const childFirstName = decodeObj['https://tacklit.com/childFirstName'];
        setUserInfo({
          clientRecordId,
          initials,
          initialsBackgroundColor,
          childFirstName: childFirstName ?? ''
        });
        return queryParamToken;
      } else if (isAuthenticated) {
        const loginToken = await getAccessTokenSilently({
          authorizationParams: {
            audience: process.env.REACT_APP_API_AUDIENCE
          },
          ...options
        });
        setToken(loginToken);
        const clientRecordId = user?.['https://tacklit.com/clientRecordId'];
        const initials = user?.['https://tacklit.com/initials'];
        const initialsBackgroundColor = user?.['https://tacklit.com/initialsBackgroundColor'];
        setUserInfo({
          ...userInfo,
          clientRecordId,
          initials,
          initialsBackgroundColor
        });
        return loginToken;
      } else {
        const storedTempAuthToken = retrieveUserSession(brandConfig.userSessionKey).authToken;
        if (storedTempAuthToken) {
          setToken(storedTempAuthToken);
          return;
        }
        if (!skipLoginWithRedirect) {
          loginWithRedirect({ appState: { returnTo: window.location.pathname } });
        }
      }
    } catch (ex) {
      console.error(ex);
    }
  };

  useEffect(() => {
    if (!isLoading) {
      fetchAccessTokenAndInfo();
    }

    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading]);

  return { token, isQueryParamToken: !!queryParamToken, userInfo, fetchAccessTokenAndInfo };
};
