import styles from './EmergencyContactForm.module.scss';

import Input from 'components/Input/Input';
import Select from 'components/Select/Select';
import MobilePhoneInput from 'components/MobilePhoneInput/MobilePhoneInput';
import { RELATIONSHIP_OPTIONS } from 'routes/constants';
import { EmergencyContactFieldErrors } from 'pages/RequireInfoPage/constants';

export interface EmergencyContactField {
  firstName: string;
  lastName: string;
  mobileNumber?: string;
  relationship: string;
}

interface EmergencyContactFormInterface {
  values: EmergencyContactField;
  onChangeValue: (values: EmergencyContactField) => void;
  checkValidation: boolean;
  errors?: EmergencyContactFieldErrors;
}

const EmergencyContactForm = ({ values, onChangeValue, checkValidation, errors }: EmergencyContactFormInterface) => {
  const handleFieldChange = (fieldName: keyof EmergencyContactField, value: string) => {
    const updatedField = {
      ...values,
      [fieldName]: value
    };
    onChangeValue(updatedField);
  };

  return (
    <div>
      <div className={styles.rowWrapper}>
        <Input
          id="firstName"
          className={styles.fieldWrapper}
          label="Contact First Name*"
          name="firstName"
          required
          labelClass={styles.inputLabel}
          onChange={(e) => {
            handleFieldChange('firstName', e.target.value);
          }}
          errorMessage={checkValidation ? errors?.firstName : ''}
        />
        <Input
          id="lastName"
          className={styles.fieldWrapper}
          label="Contact Last Name*"
          name="lastName"
          required
          labelClass={styles.inputLabel}
          onChange={(e) => {
            handleFieldChange('lastName', e.target.value);
          }}
          errorMessage={checkValidation ? errors?.lastName : ''}
        />
      </div>

      <div className={styles.rowWrapper}>
        <div className={styles.fieldWrapper}>
          <MobilePhoneInput
            id={'mobileNumber'}
            label={'Contact Mobile Phone Number'}
            value={values.mobileNumber || ''}
            onChange={(value) => {
              handleFieldChange('mobileNumber', value);
            }}
          />
        </div>
        <div className={styles.fieldWrapper}>
          <Select
            className={styles.customSelect}
            labelClass={styles.selectInputLabel}
            label={'Your Relationship*'}
            placeholder={'Select '}
            isSearchable={false}
            name="relationship"
            options={RELATIONSHIP_OPTIONS}
            styles={{
              container: (base) => ({
                ...base,
                width: '100%'
              }),
              control: (controlBase: any) => ({
                ...controlBase,
                minHeight: '30px',
                backgroundColor: 'transparent',
                borderRadius: 4,
                boxShadow: 'none',
                // border: checkValidation && !!errors?.relationship ? 'none' : '1px solid #b5b8bd',
                // '&:hover': {
                //   borderColor: '#818e9b'
                // },
                padding: '0 10px'
              }),
              valueContainer: (base) => ({ ...base, paddingLeft: 0 }),
              option: (base, state) => ({
                ...base,
                color: '#003A5D',
                fontWeight: '600',
                cursor: 'pointer',
                ...(state.isSelected ? { backgroundColor: '#F2F7F5' } : {}),
                ...(state.isFocused ? { backgroundColor: '#F2F7F5' } : {}),
                '&:active': {
                  backgroundColor: '#F2F7F5'
                }
              })
            }}
            onChange={(e) => {
              e && handleFieldChange('relationship', e.value);
            }}
            //hasError={checkValidation && !!errors?.relationship}
          />
          {/* <ErrorMessage error={errors?.relationship || ''} visible={checkValidation && !!errors?.relationship} /> */}
        </div>
      </div>
    </div>
  );
};

export default EmergencyContactForm;
