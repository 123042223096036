import styles from './WelcomeMessage.module.scss';
import { useAuth0 } from '@auth0/auth0-react';
import { useMemo } from 'react';
import { useGetCheckInStatusQuery } from 'reduxToolkit/endpoints/checkInService/checkIn';
import {
  useGetAttachedClinicianDetails,
  useGetClientProfileDetails
} from 'utils/hooks/clinicianProfileService/getClientDetails';
import { sanitizeHTMLString } from 'utils/sanitizeHTMLString';

const WelcomeMessage = () => {
  const { user } = useAuth0();
  const { attachedClinicianDetail } = useGetAttachedClinicianDetails();
  const { clientProfileData } = useGetClientProfileDetails();

  const clinicianSlugUrlOrId =
    attachedClinicianDetail?.clinician?._id || attachedClinicianDetail?.clinician?.slugUrl || '';

  const { data: checkInStatus } = useGetCheckInStatusQuery(
    { clinicianSlugUrlOrId },
    {
      skip: !clinicianSlugUrlOrId,
      pollingInterval: 10 * 60 * 1000 // 10 minutes
    }
  );

  const clientName = clientProfileData?.firstName || user?.name || '';

  const description = useMemo(() => {
    const customiseWelcomeMessage = attachedClinicianDetail?.clinician?.customisations?.checkIn;

    if (!checkInStatus && customiseWelcomeMessage?.firstCheckInDescription) {
      const clinicianName = attachedClinicianDetail?.clinician?.name
        ? `${attachedClinicianDetail.clinician?.title} ${attachedClinicianDetail.clinician?.name}`
        : attachedClinicianDetail?.practice?.name || '';

      return sanitizeHTMLString(
        customiseWelcomeMessage.firstCheckInDescription
          .replace(/\$CLIENT_NAME/g, clientName)
          .replace(/\$CLINICIAN_NAME/g, clinicianName)
      );
    } else if (customiseWelcomeMessage?.checkInDescription) {
      return sanitizeHTMLString(customiseWelcomeMessage.checkInDescription.replace(/\$CLIENT_NAME/g, clientName));
    }
  }, [
    attachedClinicianDetail?.clinician?.customisations?.checkIn,
    attachedClinicianDetail?.clinician?.name,
    attachedClinicianDetail?.clinician?.title,
    attachedClinicianDetail?.practice?.name,
    clientName,
    checkInStatus
  ]);

  return (
    <div className={styles.container}>
      {description ? (
        <span dangerouslySetInnerHTML={{ __html: description }} />
      ) : (
        <div className={styles.welcomeMessage}>
          Welcome{user?.['https://tacklit.com/loginsCount'] > 1 && ` back`}
          {clientName && ` ${clientName}!`}
        </div>
      )}
    </div>
  );
};

export default WelcomeMessage;
