import { Address } from 'interfaces/Client';
import styles from './AddressForm.module.scss';
import Input from 'components/Input/Input';
import { useEffect, useRef, useState } from 'react';
import AddressSuggestion from './components/AddressSuggestion/AddressSuggestion';

interface AddressFormProps {
  value: Address;
  onChange: (value: Address) => void;
}

const AddressForm = ({ value, onChange }: AddressFormProps) => {
  const [isMobileAddressSuggestionVisible, setIsMobileAddressSuggestionVisible] = useState(true);

  const line1ContainerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClick = (e: any) => {
      if (line1ContainerRef.current?.contains(e.target)) {
        return;
      }
      setIsMobileAddressSuggestionVisible(false);
    };

    document.addEventListener('mousedown', handleClick);
    return () => {
      document.removeEventListener('mousedown', handleClick);
    };
  });

  const handleChange = (key: keyof Address, newValue: string) => {
    onChange({
      ...value,
      [key]: newValue
    });

    if (key === 'line1') {
      setIsMobileAddressSuggestionVisible(true);
    }
  };

  const handleSelectAddressSuggestion = (address: Address) => {
    onChange(address);
    setIsMobileAddressSuggestionVisible(false);
  };

  return (
    <div className={styles.container}>
      <div className={styles.title}>ADDRESS</div>
      <div className={styles.form}>
        <div className={styles.desktopRow}>
          <div className={styles.column}>
            <Input
              containerRef={line1ContainerRef}
              label={'Address 1'}
              id={'address.line1'}
              onChange={(e) => handleChange('line1', e.target.value)}
              value={value.line1 || ''}
              required
              autoComplete={'off'}
            />
            <Input
              label={'Address 2'}
              id={'address.line2'}
              onChange={(e) => handleChange('line2', e.target.value)}
              value={value.line2 || ''}
              required
              autoComplete={'off'}
            />
          </div>
          <AddressSuggestion
            mobileAttachRef={line1ContainerRef}
            searchValue={value.line1 || ''}
            mobileVisible={isMobileAddressSuggestionVisible}
            onSelect={handleSelectAddressSuggestion}
          />
        </div>
        <div className={styles.row}>
          <Input
            label={'Suburb'}
            id={'address.suburb'}
            onChange={(e) => handleChange('suburb', e.target.value)}
            value={value.suburb || ''}
            required
            autoComplete={'off'}
          />
          <Input
            label={'Post code'}
            id={'address.postcode'}
            onChange={(e) => handleChange('postcode', e.target.value)}
            value={value.postcode || ''}
            required
            autoComplete={'off'}
          />
        </div>
        <div className={styles.row}>
          <Input
            label={'State'}
            id={'address.state'}
            onChange={(e) => handleChange('state', e.target.value)}
            value={value.state || ''}
            required
            autoComplete={'off'}
          />
          <Input
            label={'Country'}
            id={'address.country'}
            onChange={(e) => handleChange('country', e.target.value)}
            value={value.country || ''}
            required
            autoComplete={'off'}
          />
        </div>
      </div>
    </div>
  );
};

export default AddressForm;
