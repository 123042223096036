import styles from './ClinicianAvatar.module.scss';
import classnames from 'classnames';
import { Link } from 'react-router-dom';

interface ClinicianAvatarProps {
  profileImg: string;
  className?: string;
  classNameImage?: string;
  classNameFrame?: string;
  detailPath: string;
}

const ClinicianAvatar = ({ profileImg, className, classNameImage, detailPath }: ClinicianAvatarProps) => {
  return (
    <div className={classnames(styles.container, className && className)}>
      <Link to={detailPath} className={styles.imageContainer}>
        {profileImg ? (
          <img
            className={classnames(styles.image, classNameImage && classNameImage)}
            src={profileImg}
            alt={'profile'}
          />
        ) : (
          <div className={`material-symbols-outlined ${styles.imagePlaceholder}`}>person</div>
        )}
      </Link>
    </div>
  );
};

export default ClinicianAvatar;
