import { Dayjs } from 'dayjs';
import dayjs from './dayjsExtended';

export const DEFAULT_TIMEZONE = process.env.REACT_APP_GAE_REGION === 'gb' ? 'Europe/London' : 'Australia/Melbourne';

// copied from moment
// more info: https://stackoverflow.com/a/11888430
export const isDaylightSavingTime = (originalDayjs: Dayjs, originalTimezone = DEFAULT_TIMEZONE) =>
  originalDayjs.utcOffset() > dayjs().month(0).tz(originalTimezone).utcOffset() ||
  originalDayjs.utcOffset() > dayjs().month(5).tz(originalTimezone).utcOffset();

interface TimezoneDetails {
  dstCode: string;
  nonDstCode: string;
  desc: string;
  shortDesc: string;
  nonDstHr: string;
  dstHr: string;
  label: string;
}

const UK_TIMEZONES: (TimezoneDetails & { tzId: string })[] = [
  {
    tzId: 'Europe/London',
    nonDstCode: 'GMT',
    dstCode: 'BST',
    desc: 'London / England, Wales, Scotland, Northern Ireland',
    shortDesc: 'London',
    nonDstHr: '0',
    dstHr: '1',
    label: 'London'
  }
];

const AU_TIMEZONES: (TimezoneDetails & { tzId: string })[] = [
  {
    tzId: 'Australia/Sydney',
    nonDstCode: 'AEST',
    dstCode: 'AEDT',
    desc: 'NSW, VIC & ACT / Sydney, Melbourne & Canberra',
    shortDesc: 'NSW, VIC & ACT',
    nonDstHr: '10',
    dstHr: '11',
    label: 'New South Wales/Sydney'
  },
  {
    tzId: 'Australia/Melbourne',
    nonDstCode: 'AEST',
    dstCode: 'AEDT',
    desc: 'NSW, VIC & ACT / Sydney, Melbourne & Canberra',
    shortDesc: 'NSW, VIC & ACT',
    nonDstHr: '10',
    dstHr: '11',
    label: 'Victoria/Melbourne'
  },
  {
    tzId: 'Australia/Brisbane',
    nonDstCode: 'AEST',
    dstCode: 'AEST',
    desc: 'Queensland/Brisbane',
    shortDesc: 'Queensland',
    nonDstHr: '10',
    dstHr: '10',
    label: 'Queensland/Brisbane'
  },
  {
    tzId: 'Australia/Queensland',
    nonDstCode: 'AEST',
    dstCode: 'AEST',
    desc: 'Queensland/Brisbane',
    shortDesc: 'Queensland',
    nonDstHr: '10',
    dstHr: '10',
    label: 'Queensland/Brisbane'
  },
  {
    tzId: 'Australia/Adelaide',
    nonDstCode: 'ACST',
    dstCode: 'ACDT',
    desc: 'South Australia/Adelaide',
    shortDesc: 'South Australia',
    nonDstHr: '9.5',
    dstHr: '10.5',
    label: 'South Australia/Adelaide'
  },
  {
    tzId: 'Australia/Darwin',
    nonDstCode: 'ACST',
    dstCode: 'ACST',
    desc: 'Northern Territory/Darwin',
    shortDesc: 'Northern Territory',
    nonDstHr: '9.5',
    dstHr: '9.5',
    label: 'Northern Territory/Darwin'
  },
  {
    tzId: 'Australia/Perth',
    nonDstCode: 'AWST',
    dstCode: 'AWST',
    desc: 'Western Australia/Perth',
    shortDesc: 'Western Australia',
    nonDstHr: '8',
    dstHr: '8',
    label: 'Western Australia/Perth'
  },
  {
    tzId: 'Pacific/Norfolk',
    nonDstCode: 'NFT',
    dstCode: 'NFDT',
    desc: 'Norfolk Island/Kingston',
    shortDesc: 'Norfolk Island',
    nonDstHr: '11',
    dstHr: '12',
    label: 'Norfolk Island/Kingston'
  },
  {
    tzId: 'Indian/Christmas',
    nonDstCode: 'CXT',
    dstCode: 'CXT',
    desc: 'Christmas Island',
    shortDesc: 'Christmas Island',
    nonDstHr: '7',
    dstHr: '7',
    label: 'Christmas Island'
  },
  {
    tzId: 'Indian/Cocos',
    nonDstCode: 'CCT',
    dstCode: 'CCT',
    desc: 'Cocos Islands',
    shortDesc: 'Christmas Island',
    nonDstHr: '6.5',
    dstHr: '6.5',
    label: 'Cocos Islands'
  }
];

const TIMEZONE_MAP: Record<string, TimezoneDetails | undefined> = [...UK_TIMEZONES, ...AU_TIMEZONES].reduce(
  (currMap, { tzId, ...timezoneDetails }) => ({
    ...currMap,
    [tzId]: timezoneDetails
  }),
  {}
);

// export const getTimezoneCode = (timezone: string) => {
//   const timezoneObj = TIMEZONE_MAP[timezone];
//   return timezoneObj && (isDaylightSavingTime(dayjs(), timezone) ? timezoneObj.dstCode : timezoneObj.nonDstCode);
// };

const generateTimeZoneLabel = (timezone: string) => {
  const getTimeZoneLabel = TIMEZONE_MAP[timezone];
  if (!getTimeZoneLabel) {
    return timezone;
  }

  const isDst = isDaylightSavingTime(dayjs(), timezone);
  const dstCode = isDst ? getTimeZoneLabel.dstCode : getTimeZoneLabel.nonDstCode;
  const dstHr = isDst ? getTimeZoneLabel.dstHr : getTimeZoneLabel.nonDstHr;
  return `${getTimeZoneLabel.label} (${dstCode}) +${dstHr}`;
};

export const AU_TIMEZONE_OPTIONS = AU_TIMEZONES.map(({ tzId }) => ({
  value: tzId,
  label: generateTimeZoneLabel(tzId)
}));
