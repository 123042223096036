import styles from './GPForm.module.scss';
import { useCallback, useState } from 'react';
import { getDownloadFileName } from './utils/utils';
import classNames from 'classnames';
import FilePreview from 'components/FilePreview/FilePreview';
import Input from 'components/Input/Input';
import MobilePhoneInput from 'components/MobilePhoneInput/MobilePhoneInput';
import CheckBox from 'components/CheckBox/CheckBox';
import UploadFiles from 'components/UploadFiles/UploadFiles';
import { GPReferralErrors } from 'pages/RequireInfoPage/constants';
import { useGetAccessToken } from 'utils/hooks/useGetAccessToken';

export interface UploadFile {
  bucketName: string;
  fileName: string;
  fileUrl: string;
}

export interface GPReferral {
  name?: string;
  email: string;
  medicareProviderNumber?: string;
  phoneNumber?: string;
  files?: File[];
  isNotRegularGP?: boolean;
}

interface AddGPReferralFormProps {
  values: GPReferral;
  errors?: GPReferralErrors;
  checkValidation?: boolean;
  setProvideReferralDocuments: (value: string) => void;
  setGPValues: (value: GPReferral) => void;
  className?: string;
  classNameUploadArea?: string;
  callText?: string;
  onChangeValue: (values: GPReferral) => void;
}

const GPForm = ({
  values,
  setGPValues,
  setProvideReferralDocuments,
  errors,
  checkValidation,
  className,
  classNameUploadArea,
  onChangeValue
}: AddGPReferralFormProps) => {
  const [selectedFile, setSelectedFile] = useState<UploadFile | undefined>(undefined);
  const [isPreviewModalVisible, setIsPreviewModalVisible] = useState(false);
  const { token } = useGetAccessToken();

  const handleFieldChange = useCallback(
    (fieldName: keyof GPReferral, value: string | boolean) => {
      const updatedField = {
        ...values,
        [fieldName]: value
      };
      onChangeValue(updatedField);
    },
    [values, onChangeValue]
  );

  return (
    <div className={classNames(styles.container, className)}>
      <div className={styles.row}>
        <Input
          id="name"
          className={styles.fieldWrapper}
          label="Name of GP"
          name="name"
          labelClass={styles.inputLabel}
          required
          value={values.name}
          onChange={(e) => {
            handleFieldChange('name', e.target.value);
          }}
        />
        <Input
          id="providerNumber"
          className={styles.fieldWrapper}
          label="Provider Number"
          name="providerNumber"
          value={values.medicareProviderNumber}
          labelClass={styles.inputLabel}
          required
          onChange={(e) => {
            handleFieldChange('medicareProviderNumber', e.target.value);
          }}
        />
      </div>
      <div className={styles.row}>
        <Input
          id="gpEmail"
          className={styles.fieldWrapper}
          label="GP email address*"
          name="email"
          value={values.email}
          labelClass={styles.inputLabel}
          required
          onChange={(e) => {
            handleFieldChange('email', e.target.value);
          }}
          errorMessage={checkValidation ? errors?.email : ''}
        />
        <div className={styles.field}>
          <MobilePhoneInput
            label={'GP Mobile Phone Number'}
            value={values.phoneNumber || ''}
            onChange={(e) => {
              handleFieldChange('phoneNumber', e);
            }}
          />
        </div>
      </div>
      <div className={styles.row}>
        {/* Upload GP files button */}
        <div className={styles.content}>
          <>
            <div className={classNames(styles.uploadArea, classNameUploadArea)}>
              {/* Referral file */}
              <UploadFiles
                buttonText={'Upload MHTP Documents'}
                files={values.files || []}
                handleChangeFiles={(files) => {
                  setGPValues({ ...values, files });
                }}
                id={'gpDocuments'}
              />
            </div>
          </>
        </div>
        <div className={styles.checkBoxText}>
          <CheckBox
            id="notRegularGP"
            value={!!values.isNotRegularGP}
            onChange={(e) => handleFieldChange('isNotRegularGP', e.target.checked)}
            className={styles.checkBox}
            label="I don’t have a regular GP."
          />
        </div>
      </div>

      {token && selectedFile && (
        <FilePreview
          title={getDownloadFileName(selectedFile.fileName)}
          visible={isPreviewModalVisible}
          token={token}
          file={selectedFile}
          onCancel={() => {
            setIsPreviewModalVisible(false);
            setSelectedFile(undefined);
          }}
        />
      )}
    </div>
  );
};

export default GPForm;
