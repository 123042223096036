import HelmetWrapper from 'components/HelmetWrapper/HelmetWrapper';
import styles from './RequireInfoPage.module.scss';
import React, { useCallback, useState } from 'react';
import ContentLayout from 'components/BaseComponent/ContentLayout/ContentLayout';
import ClientHeader from 'components/ClientHeader/ClientHeader';
import ProgressBar from 'pages/SignUp/components/ProgressBar/ProgressBar';
import AppointmentDetails from 'pages/SignUp/components/AppointmentDetails/AppointmentDetails';
import EmergencyContactForm, { EmergencyContactField } from './components/EmergencyContactForm/EmergencyContactForm';
import GPForm, { GPReferral } from './components/GPForm/GPForm';
import MedicareForm from './components/MedicareForm/MedicareForm';
import AddressForm from 'components/AddressForm/AddressForm';
import ReferralForm, { ReferralFields } from './components/ReferralForm/ReferralForm';
import { brandConfig } from 'brand/config';
import { InfoFieldType } from 'interfaces/signupConfigInterface';
import Button from 'components/Button/Button';
import { Address, MedicareInformation, ReferralDetail } from 'interfaces/Client';
import {
  EmergencyContactFieldErrors,
  GPReferralErrors,
  InfoErrorType,
  InfoFormInterface,
  initialInfoFormError,
  MEDICARE_CARD_DEFAULT_VALUE,
  ReferralDetailErrors,
  validationInfoForm
} from './constants';
import { useTranslation } from 'react-i18next';
import CommunicationPreferenceForm from './components/CommunicationPreference/CommunicationPreference';
import {
  usePatchClientCommunicationPreferenceMutation,
  usePostKeyContactMutation,
  usePutClientAddressMutation,
  usePutClientReferralDetailMutation
} from 'reduxToolkit/endpoints/clinicianProfileService/client';
import { notification } from 'antd';
import { uploadDocumentWithEncryption } from 'utils/http/upload';
import { useGetAccessToken } from 'utils/hooks/useGetAccessToken';
import { retrieveUserSession } from 'utils/userSession';
import { useGetClientProfileDetails } from 'utils/hooks/clinicianProfileService/getClientDetails';
import { CommunicationPreference } from 'interfaces/Client/enums';
import dayjs from 'dayjs';
import { DateFormat } from 'utils/dayjsExtended';
import { useNavigate } from 'react-router-dom';
import { ENGAGE_ROUTES_LIST } from 'routes/constants';
import CheckBox from 'components/CheckBox/CheckBox';

const RequireInfoPage = () => {
  const navigate = useNavigate();
  const [checkValidate, setCheckValidate] = useState(false);
  const [t] = useTranslation();
  const { token } = useGetAccessToken();
  const { clientProfileData: clientProfile } = useGetClientProfileDetails();
  const retrieveUser = retrieveUserSession(brandConfig.userSessionKey);
  const retrieveUserProfile = retrieveUser.clientRecord?.clientProfiles || [];
  const { SIGNUP } = ENGAGE_ROUTES_LIST;

  const massageMedicareDefaultValue: MedicareInformation = {
    ...MEDICARE_CARD_DEFAULT_VALUE,
    firstName: retrieveUserProfile[0]?.firstName || '',
    lastName: retrieveUserProfile[0]?.lastName || '',
    dateOfBirth: retrieveUserProfile[0]?.dateOfBirth || ''
  };

  const clientProfileData: MedicareInformation = {
    ...massageMedicareDefaultValue,
    ...(clientProfile && {
      firstName: clientProfile.firstName,
      lastName: clientProfile.lastName,
      dateOfBirth: clientProfile.dateOfBirth,
      ...clientProfile.medicare
    })
  };

  //Forms
  const [emergencyContactValue, setEmergencyContactValue] = useState<EmergencyContactField>({
    firstName: '',
    lastName: '',
    mobileNumber: '',
    relationship: ''
  });
  const [GPValue, setGPValue] = useState<GPReferral>({
    name: '',
    email: '',
    phoneNumber: '',
    files: []
  });
  const [addressValue, setAddressValue] = useState<Address>({
    line1: '',
    line2: '',
    state: '',
    postcode: '',
    country: '',
    suburb: ''
  });
  const [referralDetailValue, setReferralDetailValue] = useState<ReferralFields>({
    firstName: '',
    lastName: '',
    files: [],
    email: ''
  });
  const [communicationFields, setCommunicationFields] = useState<{ email: boolean; sms: boolean; policy: boolean }>({
    email: false,
    sms: false,
    policy: false
  });
  const [showDva, setShowDva] = useState(false);

  const [errors, setErrors] = useState<InfoFormInterface>(initialInfoFormError);

  const [medicare, setMedicare] = useState<MedicareInformation>(
    clientProfile ? clientProfileData : massageMedicareDefaultValue
  );
  const [isMedicareError, setIsMedicareError] = useState(false);

  const [postKeyContact] = usePostKeyContactMutation();
  const [putClientReferralDetail] = usePutClientReferralDetailMutation();
  const [putClientAddress] = usePutClientAddressMutation();
  const [patchCommunicationPreference] = usePatchClientCommunicationPreferenceMutation();

  const { engageConfig } = brandConfig;

  const validateField = useCallback(
    (newVal: InfoErrorType, formKey: keyof InfoFormInterface) => {
      const { validationErrors, cleanValidationError } = validationInfoForm(newVal, t, formKey);

      setErrors((errors) => ({ ...errors, [formKey]: validationErrors || {} }));

      return cleanValidationError;
    },
    [t]
  );

  const resetValidateForm = (formKey: keyof InfoFormInterface) => {
    setErrors((errors) => ({ ...errors, [formKey]: {} }));
  };

  const handleChangeAddressValues = (value: Address) => {
    setAddressValue(value);
  };

  const handleChangeGP = (value: GPReferral) => {
    setGPValue(value);

    if (value.isNotRegularGP) {
      resetValidateForm('gpReferralErrors');
      return;
    }

    validateField({ ...GPValue, ...value } as GPReferralErrors, 'gpReferralErrors');
  };

  const handleChangeEmergencyContact = (value: EmergencyContactField) => {
    setEmergencyContactValue(value);

    validateField(value as EmergencyContactFieldErrors, 'emergencyContactFieldErrors');
  };

  const handleChangeMedicare = (value: MedicareInformation) => {
    setMedicare(value);
    //validateField({ ...medicare, ...value } as MedicareInformationErrors, 'medicareInformationErrors');
  };

  const handleChangeReferralDetail = (value: ReferralFields) => {
    setReferralDetailValue(value);

    if (value.isNoReferral) {
      resetValidateForm('referralDetailErrors');
      return;
    }

    validateField({ ...referralDetailValue, ...value } as ReferralDetailErrors, 'referralDetailErrors');
  };

  const mapInfoFieldConfig: Record<InfoFieldType, { submitFunc: () => void; element: JSX.Element }> = {
    [InfoFieldType.EmergencyContact]: {
      submitFunc: async () => {
        try {
          await postKeyContact({
            ...emergencyContactValue,
            tags: ['Emergency Contact']
          }).unwrap();
        } catch (error) {
          notification.error({ message: 'Failed to save emergency contact' });
        }
      },
      element: (
        <>
          <div className={styles.infoContainer}>
            <div className={styles.title}>Emergency Contact*</div>
            <div className={styles.desc}>
              To provide you with support we need you to provide the detail of one emergency contact. We would only
              contact this person should we be concerned about your safety. You can add additional contacts, and update
              information in your client profile.
            </div>
            <EmergencyContactForm
              values={emergencyContactValue}
              onChangeValue={(e) => {
                handleChangeEmergencyContact(e);
              }}
              checkValidation={checkValidate}
              errors={errors.emergencyContactFieldErrors}
            />
          </div>
        </>
      )
    },
    [InfoFieldType.GPInformation]: {
      submitFunc: async () => {
        if (GPValue.isNotRegularGP) {
          return;
        }

        try {
          //add email and phone number in BE
          const payload: ReferralDetail = {
            files: [],
            isReferredByGP: true,
            isHaveTreatmentPlan: !!GPValue?.files?.length,
            practiceName: GPValue.name,
            name: GPValue.name,
            medicareProviderNumber: GPValue.medicareProviderNumber,
            treatmentPlanFiles: []
          };
          if (GPValue.files?.length) {
            payload.treatmentPlanFiles = await Promise.all(
              GPValue.files?.map(
                (file) =>
                  uploadDocumentWithEncryption(token, file) as Promise<{
                    bucketName: string;
                    fileName: string;
                    fileUrl: string;
                  }>
              )
            );
          }

          await putClientReferralDetail(payload).unwrap();
        } catch (error) {
          notification.error({ message: 'Failed to save GP information' });
        }
      },
      element: (
        <div className={styles.infoContainer}>
          <div className={styles.title}>GP Information*</div>
          <div className={styles.desc}>Please provide your regular GP details here</div>
          <GPForm
            values={GPValue}
            onChangeValue={handleChangeGP}
            setProvideReferralDocuments={() => {}}
            errors={errors.gpReferralErrors}
            checkValidation={checkValidate}
            setGPValues={setGPValue}
          />
        </div>
      )
    },
    [InfoFieldType.Medicare]: {
      submitFunc: () => {},
      element: (
        <div className={styles.infoContainer}>
          <div className={styles.title}>Medicare</div>
          <div className={styles.desc}>
            Please provide your medicare details
            <div className={styles.checkBoxText}>
              <CheckBox
                id="showDva"
                value={showDva}
                onChange={(e) => setShowDva(e.target.checked)}
                className={styles.checkBox}
                label="Add DVA card"
              />
            </div>
          </div>
          <MedicareForm
            medicareValue={medicare}
            onChangeValue={handleChangeMedicare}
            setIsMedicareError={setIsMedicareError}
            checkValidate={checkValidate}
            showDva={showDva}
          />
        </div>
      )
    },
    [InfoFieldType.Address]: {
      submitFunc: async () => {
        try {
          await putClientAddress(addressValue).unwrap();
        } catch (error) {
          notification.error({ message: 'Failed to save emergency contact' });
        }
      },
      element: (
        <div className={styles.infoContainer}>
          <div className={styles.title}>Address*</div>
          <div className={styles.desc}>
            We need your primary address as part of assessing our ability to offer you the right services
          </div>
          <AddressForm value={addressValue} onChange={handleChangeAddressValues} />
        </div>
      )
    },
    [InfoFieldType.ReferralInformation]: {
      submitFunc: async () => {
        if (referralDetailValue.isNoReferral) {
          return;
        }
        try {
          //add email and phone number in BE
          //add first name & last name?
          const payload: ReferralDetail = {
            files: [],
            isReferredByGP: false,
            isHaveTreatmentPlan: false,
            firstName: referralDetailValue.firstName,
            lastName: referralDetailValue.lastName,
            email: referralDetailValue.email,
            mobile: referralDetailValue.mobile,
            date: dayjs(referralDetailValue.referralDate).format(DateFormat.DAY_MONTH_YEAR),
            expiryDate: dayjs(referralDetailValue.expiryDate).format(DateFormat.DAY_MONTH_YEAR),
            medicareProviderNumber: referralDetailValue.providerNumber
            //role
          };
          if (GPValue.files?.length) {
            payload.files = await Promise.all(
              GPValue.files?.map(
                (file) =>
                  uploadDocumentWithEncryption(token, file) as Promise<{
                    bucketName: string;
                    fileName: string;
                    fileUrl: string;
                  }>
              )
            );
          }

          await putClientReferralDetail(payload).unwrap();
        } catch (error) {
          notification.error({ message: 'Failed to save GP information' });
        }
      },
      element: (
        <div className={styles.infoContainer}>
          <div className={styles.title}>Referral Information*</div>
          <div className={styles.desc}>Please provide your referral details here</div>
          <ReferralForm
            onChangeValue={handleChangeReferralDetail}
            values={referralDetailValue}
            errors={errors.referralDetailErrors}
            checkValidation={checkValidate}
          />
        </div>
      )
    },
    [InfoFieldType.CommunicationPreference]: {
      submitFunc: async () => {
        const clientRecord = retrieveUser.clientRecord;
        if (clientRecord) {
          await patchCommunicationPreference({
            communicationPreference: communicationFields.sms
              ? CommunicationPreference.SMS
              : communicationFields.email
                ? CommunicationPreference.Email
                : CommunicationPreference.NoPreference
          }).unwrap();
        }
      },
      element: (
        <div className={styles.infoContainer}>
          <div className={styles.title}>Communication Preference</div>
          <div className={styles.desc}>
            We will send you appointment reminders, and other important information via email and SMS. Please let us
            know your preference.
          </div>
          <CommunicationPreferenceForm
            onChangeValue={setCommunicationFields}
            values={communicationFields}
            checkValidation={checkValidate}
          />
        </div>
      )
    }
  };

  const handleSubmit = async () => {
    setCheckValidate(true);
    engageConfig?.infoFields.includes(InfoFieldType.EmergencyContact) &&
      validateField({ ...emergencyContactValue }, 'emergencyContactFieldErrors');
    engageConfig?.infoFields.includes(InfoFieldType.GPInformation) &&
      !GPValue.isNotRegularGP &&
      validateField({ ...GPValue } as GPReferralErrors, 'gpReferralErrors');
    engageConfig?.infoFields.includes(InfoFieldType.ReferralInformation) &&
      !referralDetailValue.isNoReferral &&
      validateField({ ...referralDetailValue } as ReferralDetailErrors, 'referralDetailErrors');

    const checkFieldHaveError = Object.values(errors).some((form) => Object.values(form).some((field) => !!field));

    if (
      checkFieldHaveError ||
      isMedicareError ||
      (!communicationFields.policy && engageConfig?.infoFields.includes(InfoFieldType.CommunicationPreference))
    ) {
      return;
    }

    const promises = engageConfig?.infoFields.map((field) => {
      return mapInfoFieldConfig[field].submitFunc();
    });
    await Promise.all(promises);

    navigate(SIGNUP.PAYMENT);
  };

  return (
    <HelmetWrapper title={'Signup'}>
      <div className={styles.container}>
        <ContentLayout>
          <ClientHeader darkFont />
          <ProgressBar
            steps={[
              { id: 'aboutYou', name: 'About You', status: 'completed' },
              { id: 'requiredInfo', name: 'Required Info', status: 'current' },
              { id: 'payment', name: 'Payment' },
              { id: 'complete', name: 'Complete' }
            ]}
          />
          <div className={styles.contents}>
            <div className={styles.leftContent}>
              {engageConfig?.infoFields.map((field) => {
                return mapInfoFieldConfig[field].element;
              })}

              <Button
                className={styles.continueButton}
                //status={buttonStatus}
                onClick={() => {
                  handleSubmit();
                }}
              >
                Continue
              </Button>
            </div>
            <div className={styles.rightContent}>
              <AppointmentDetails appointment={{}} />
            </div>
          </div>
        </ContentLayout>
      </div>
    </HelmetWrapper>
  );
};

export default RequireInfoPage;
