export enum PaymentOption {
  SaveCardOnly = 'saveCardOnly',
  PayAndSaveCard = 'payAndSaveCard',
  PayOnly = 'payOnly',
  NoPayment = 'noPayment'
}

export enum InfoFieldType {
  EmergencyContact = 'emergencyContact',
  GPInformation = 'gpInformation',
  Medicare = 'medicare',
  ReferralInformation = 'referralInformation',
  CommunicationPreference = 'communicationPreference',
  Address = 'address'
}
