import { useMemo } from 'react';

import styles from './PasswordRules.module.scss';

interface PasswordRulesProps {
  password: string;
  onChangePasswordValid: (isValid: boolean) => void;
}

const PasswordRules = ({ password, onChangePasswordValid }: PasswordRulesProps) => {
  const { passwordLengthValid, passwordIncludesLowercaseUppercaseNumber, passwordIncludesSpecialCharacters } =
    useMemo(() => {
      const passwordLengthValid = password.length >= 8;
      const passwordIncludesLowercaseUppercaseNumber = /(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{0,}/g.test(password);
      const passwordIncludesSpecialCharacters = /.*[!@#$%^&*-]+.*/g.test(password);

      onChangePasswordValid(
        passwordLengthValid && passwordIncludesLowercaseUppercaseNumber && passwordIncludesSpecialCharacters
      );
      return { passwordLengthValid, passwordIncludesLowercaseUppercaseNumber, passwordIncludesSpecialCharacters };

      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [password]);

  // const handleUpdatePasswordStrength = (passwordScore: number) => {
  //   setPasswordScore(passwordScore);
  //   onChangePasswordScore(passwordScore);
  // };

  const strongPassword =
    passwordLengthValid && passwordIncludesLowercaseUppercaseNumber && passwordIncludesSpecialCharacters;

  return (
    <div className={styles.container}>
      <div className={passwordIncludesLowercaseUppercaseNumber ? styles.suggestionSuccess : styles.suggestion}>
        <i className={`material-icons ${styles.checkIcon}`}>{passwordIncludesLowercaseUppercaseNumber ? 'done' : ''}</i>
        <span className={styles.label}>Contains uppercase characters, lowercase characters, and numbers</span>
      </div>
      <div className={passwordIncludesSpecialCharacters ? styles.suggestionSuccess : styles.suggestion}>
        <i className={`material-icons ${styles.checkIcon}`}>{passwordIncludesSpecialCharacters ? 'done' : ''}</i>
        <span className={styles.label}>Contains special characters (!@#$%^&*-)</span>
      </div>
      <div className={strongPassword ? styles.suggestionSuccess : styles.suggestion}>
        <i className={`material-icons ${styles.checkIcon}`}>{strongPassword ? 'done' : ''}</i>
        <div className={styles.passwordStrengthWrapper}>
          <div className={styles.label}>
            Password must be <strong>STRONG</strong>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PasswordRules;
