import React, { useEffect } from 'react';
import ProgressBar from './components/ProgressBar/ProgressBar';
import HelmetWrapper from 'components/HelmetWrapper/HelmetWrapper';
import ContentLayout from 'components/BaseComponent/ContentLayout/ContentLayout';
import ClientHeader from 'components/ClientHeader/ClientHeader';
import styles from './SignUpPage.module.scss';
import SignupForm from './components/SignupForm/SignupForm';
import AppointmentDetails from './components/AppointmentDetails/AppointmentDetails';

const SignUpPage = () => {
  useEffect(() => {
    // Initialization code here
  }, []);

  return (
    <HelmetWrapper title={'Signup'}>
      <div className={styles.container}>
        <ContentLayout>
          <ClientHeader darkFont />
          <ProgressBar
            steps={[
              { id: 'aboutYou', name: 'About You', status: 'current' },
              { id: 'requiredInfo', name: 'Required Info' },
              { id: 'payment', name: 'Payment' },
              { id: 'complete', name: 'Complete' }
            ]}
          />
          <div className={styles.contents}>
            <div className={styles.leftContent}>
              <SignupForm />
            </div>
            <div className={styles.rightContent}>
              <AppointmentDetails appointment={{}} includeWhyUsSection />
            </div>
          </div>
        </ContentLayout>
      </div>
    </HelmetWrapper>
  );
};

export default SignUpPage;
