export const ENGAGE_ROUTES_LIST = {
  PRACTITIONER: {
    LISTING: '/psychologists',
    LISTING_WITH_FILTER: '/psychologists/:filter',
    DETAILS: '/psychologists-profile/:clinicianId'
  },
  SIGNUP: {
    BASE: '/signup',
    REQUIRED_INFO: '/required-info',
    PAYMENT: '/payment',
    CONFIRM_BOOKING: '/booking-confirm',
    WELCOME_BOOKING: '/welcome'
  }
};

export const PORTAL_ROUTES_LIST = {
  INVOICE: {
    VIEW: '/invoice/:invoiceId'
  },
  REPORT: {
    VIEW: '/report/:reportId'
  },

  CONSENT: {
    BASE: '/consent',
    DETAILS: '/consent/:consentFormId'
  },
  SURVEY: {
    BASE: '/surveys',
    DETAILS: '/surveys/:surveyId'
  },
  ONBOARDING: {
    BASE: '/onboarding'
  },
  PSYCHOMETRIC: {
    BASE: '/psychometrics',
    DETAILS: '/:clinicianSlugUrlOrId/assessment/:assessmentId'
  },
  CHECKIN: '/:clinicianSlugUrlOrId/checkin',

  LOGIN: '/login',
  AVATAR: '/avatar',
  HOME: '/',
  PENDING_ACTIONS: '/pending-actions',
  UPDATE_PROFILE: '/profile',
  APPOINTMENT_OVERVIEW: '/appointment/overview',
  REFERRAL: '/referral',
  MY_PSYCHOLOGIST: '/my-psychologist'
};

export const RELATIONSHIP_OPTIONS = [
  { label: 'Mother', value: 'mother' },
  { label: 'Father', value: 'father' },
  { label: 'Husband', value: 'husband' },
  { label: 'Wife', value: 'wife' },
  { label: 'Partner', value: 'partner' },
  { label: 'Step Mother', value: 'stepMother' },
  { label: 'Step Father', value: 'stepFather' },
  { label: 'Grandparent', value: 'grandparent' },
  { label: 'Son', value: 'son' },
  { label: 'Daughter', value: 'daughter' },
  { label: 'Guardian', value: 'guardian' },
  { label: 'Sibling', value: 'sibling' },
  { label: 'Teacher', value: 'teacher' },
  { label: 'Friend', value: 'friend' },
  { label: 'Employer', value: 'employer' },
  { label: 'Case Worker', value: 'caseWorker' },
  { label: 'Other', value: 'other' }
];
