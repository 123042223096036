import { RefObject, useRef } from 'react';
import styles from './AddressSuggestion.module.scss';
import { createPortal } from 'react-dom';
import { Address, useAddressSuggestion } from 'utils/hooks/useAddressSuggestion';
import { useWindowSize } from 'utils/hooks/useWindowSize';

interface AddressSuggestionProps {
  mobileAttachRef?: RefObject<HTMLElement> | null;
  searchValue: string;
  mobileVisible?: boolean;
  onSelect?: (address: Address['details']) => void;
}

const AddressSuggestion = ({
  mobileAttachRef,
  searchValue,
  mobileVisible = true,
  onSelect
}: AddressSuggestionProps) => {
  const [windowWidth] = useWindowSize();
  const { addresses, isLoading } = useAddressSuggestion(searchValue, process.env.REACT_APP_GAE_REGION);

  const containerRef = useRef<HTMLDivElement>(null);

  const isMobileView = windowWidth < 820;

  return (
    <div ref={containerRef}>
      {createPortal(
        <div className={styles.container}>
          <div className={styles.title}>Suggestions</div>
          {isLoading && !addresses.length ? (
            <div className={styles.loading}>Loading...</div>
          ) : addresses.length === 0 && searchValue.length > 0 ? (
            <div className={styles.loading}>No address found. Please try other keywords.</div>
          ) : addresses.length === 0 && searchValue.length === 0 ? (
            <div className={styles.loading}>Start typing to see suggestions</div>
          ) : (
            (!isMobileView || mobileVisible) &&
            searchValue.length > 0 && (
              <div className={styles.addressesContainer}>
                {addresses.map((address, i) => (
                  <div
                    key={i + address.description}
                    className={styles.address}
                    onClick={() => onSelect?.(address.details)}
                  >
                    <i className={`material-icons ${styles.icon}`}>location_on</i>
                    <span>
                      <span className={styles.darkAddress}>{address.details.line1}</span>&nbsp;
                      <span className={styles.lightAddress}>
                        {address.details.suburb}, {address.details.state}, {address.details.country}
                      </span>
                    </span>
                  </div>
                ))}
              </div>
            )
          )}
        </div>,
        (isMobileView && mobileAttachRef?.current) ||
          containerRef.current ||
          // this is here in case refs are null
          // refs can be null, but shouldn't be
          document.body
      )}
    </div>
  );
};

export default AddressSuggestion;
