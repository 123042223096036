import { PatientProfile } from 'interfaces/Client';
import { CommunicationPreference } from 'interfaces/Client/enums';

export type ProfileDetailsForm = Required<
  Pick<
    PatientProfile,
    | 'firstName'
    | 'lastName'
    | 'name'
    | 'dateOfBirth'
    | 'mobileNumber'
    | 'email'
    | 'address'
    | 'timeZone'
    | 'communicationPreference'
  >
> &
  Pick<PatientProfile, 'pronouns'> & {
    avatar: string;
  };

const DEFAULT_PROFILE_DETAILS: ProfileDetailsForm = {
  firstName: '',
  lastName: '',
  name: '',
  dateOfBirth: '',
  mobileNumber: '',
  email: '',
  // TODO: USE DEFAULT_TIMEZONE
  timeZone: process.env.REACT_APP_GAE_REGION === 'gb' ? 'Europe/London' : 'Australia/Melbourne',
  address: {
    line1: '',
    line2: '',
    state: '',
    suburb: '',
    postcode: '',
    country: ''
  },
  communicationPreference: CommunicationPreference.NoPreference,
  avatar: ''
};

export const getProfileDetailsFormFromPatientProfile = (patientProfile?: PatientProfile): ProfileDetailsForm => {
  return {
    firstName: patientProfile?.firstName || DEFAULT_PROFILE_DETAILS.firstName,
    lastName: patientProfile?.lastName || DEFAULT_PROFILE_DETAILS.lastName,
    name: patientProfile?.name || DEFAULT_PROFILE_DETAILS.name,
    pronouns: patientProfile?.pronouns,
    dateOfBirth: patientProfile?.dateOfBirth || DEFAULT_PROFILE_DETAILS.dateOfBirth,
    mobileNumber: patientProfile?.mobileNumber || DEFAULT_PROFILE_DETAILS.mobileNumber,
    email: patientProfile?.email || DEFAULT_PROFILE_DETAILS.email,
    timeZone: patientProfile?.timeZone || DEFAULT_PROFILE_DETAILS.timeZone,
    address: { ...DEFAULT_PROFILE_DETAILS.address, ...patientProfile?.address },
    communicationPreference: patientProfile?.communicationPreference || DEFAULT_PROFILE_DETAILS.communicationPreference,
    avatar: DEFAULT_PROFILE_DETAILS.avatar
  };
};

const COMMUNICATION_PREFERENCE_LABELS: Record<CommunicationPreference, string> = {
  [CommunicationPreference.NoPreference]: 'No Preference',
  [CommunicationPreference.SMS]: 'Only send SMS, no E-mail',
  [CommunicationPreference.Email]: 'Only send E-mail, no SMS',
  [CommunicationPreference.None]: 'Don’t send either SMS or E-mail'
};

export const COMMUNICATION_PREFERENCE_OPTIONS = Object.values(CommunicationPreference).map((value) => ({
  value,
  label: COMMUNICATION_PREFERENCE_LABELS[value]
}));
