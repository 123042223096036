import { useEffect, useState } from 'react';
import styles from './CircularTimer.module.scss';
import './ant-progress-override.scss';
import TimerProgress from './components/TimerProgress/TimerProgress';
import LoadingDot from 'components/LoadingDot/LoadingDot';

interface CircularTimerProps {
  className?: string;
  timeLabelClass?: string;
  timeStrokeClass?: string;
  onlyText?: boolean;
  minutes?: number;
  startTime?: string;
  width?: number;
  strokeWidth?: number;
  strokeColor?: string;
  trailColor?: string;
  onExpire?: () => void;
}

const CircularTimer = ({ startTime, ...props }: CircularTimerProps) => {
  const [reInit, setReInit] = useState(false);

  useEffect(() => {
    setReInit(true);
    let timeoutId = setTimeout(() => setReInit(false), 1000);
    return () => clearTimeout(timeoutId);
  }, [startTime]);

  return !reInit ? (
    <TimerProgress {...props} startTime={startTime} />
  ) : (
    <div className={styles.loading}>
      <LoadingDot />
    </div>
  );
};

export default CircularTimer;
