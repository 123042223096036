import DateInput from 'components/DateInput/DateInput';
import Input from 'components/Input/Input';
import styles from './ReferralForm.module.scss';
import Select from 'components/Select/Select';
import MobilePhoneInput from 'components/MobilePhoneInput/MobilePhoneInput';
import ToggleSwitch from 'components/ToggleSwitch/ToggleSwitch';
import UploadFiles from 'components/UploadFiles/UploadFiles';
import { ReferralDetailErrors } from 'pages/RequireInfoPage/constants';
import { ReferrerType } from 'interfaces/Client';
import CheckBox from 'components/CheckBox/CheckBox';

export interface ReferralFields {
  referralDate?: string;
  expiryDate?: string;
  firstName?: string;
  lastName?: string;
  role?: string;
  providerNumber?: string;
  files?: File[];
  email?: string;
  mobile?: string;
  referrerType?: ReferrerType;
  isNoReferral?: boolean;
}

interface ReferralFormProps {
  values: ReferralFields;
  errors?: ReferralDetailErrors;
  checkValidation?: boolean;
  onChangeValue: (data: ReferralFields & {}) => void;
}

const ReferralForm = ({ values, errors, checkValidation, onChangeValue }: ReferralFormProps) => {
  const handleFieldChange = (fieldName: keyof ReferralFields, value: string | File[] | boolean) => {
    const updatedField: ReferralFields = {
      ...values,
      [fieldName]: value
    };

    onChangeValue(updatedField);
  };

  return (
    <div className={styles.container}>
      <div className={styles.row}>
        <DateInput
          label={'Referral Date'}
          value={values.referralDate || ''}
          onChange={(e) => {
            handleFieldChange('referralDate', e);
          }}
        />
        <DateInput
          label={'Expiry Date (If Applicable)'}
          value={values.expiryDate || ''}
          onChange={(e) => {
            handleFieldChange('expiryDate', e);
          }}
        />
        <div className={styles.toggle}>
          <label>Referrer Type</label>
          <ToggleSwitch
            id={'referrerType'}
            toggleList={[
              {
                label: 'Professional',
                id: ReferrerType.Professional,
                isActive: values.referrerType === ReferrerType.Professional
              },
              {
                label: 'Organizations',
                id: ReferrerType.Organisation,
                isActive: values.referrerType === ReferrerType.Organisation
              }
            ]}
            onChangeStatus={(e) => {
              handleFieldChange('referrerType', e.id);
            }}
          />
        </div>
      </div>

      <div className={styles.row}>
        <Input
          id="referralFirstName"
          className={styles.fieldWrapper}
          label="Referral First Name*"
          name="referralFirstName"
          labelClass={styles.inputLabel}
          required
          onChange={(e) => {
            handleFieldChange('firstName', e.target.value);
          }}
          errorMessage={checkValidation ? errors?.firstName : ''}
        />
        <Input
          id="referralLastName"
          className={styles.fieldWrapper}
          label="Referral Last Name*"
          name="referralLastName"
          labelClass={styles.inputLabel}
          required
          onChange={(e) => {
            handleFieldChange('lastName', e.target.value);
          }}
          errorMessage={checkValidation ? errors?.lastName : ''}
        />
      </div>

      <div className={styles.row}>
        {/* Role */}
        <div className={styles.field}>
          <Select
            className={styles.customSelect}
            labelClass={styles.selectInputLabel}
            label={'Role'}
            placeholder={'Select Role'}
            isSearchable={false}
            name="role"
            options={[{ value: 'role', label: 'Role' }]}
            styles={{
              container: (base) => ({
                ...base,
                width: '100%'
              }),
              control: (controlBase: any) => ({
                ...controlBase,
                minHeight: '30px',
                backgroundColor: 'transparent',
                borderRadius: 4,
                boxShadow: 'none',
                // border: checkValidation && !!errors?.role ? 'none' : '1px solid #b5b8bd',
                // '&:hover': {
                //   borderColor: '#818e9b'
                // },
                padding: '0 10px'
              }),
              valueContainer: (base) => ({ ...base, paddingLeft: 0 }),
              option: (base, state) => ({
                ...base,
                color: '#003A5D',
                fontWeight: '600',
                cursor: 'pointer',
                ...(state.isSelected ? { backgroundColor: '#F2F7F5' } : {}),
                ...(state.isFocused ? { backgroundColor: '#F2F7F5' } : {}),
                '&:active': {
                  backgroundColor: '#F2F7F5'
                }
              })
            }}
            onChange={(e) => {
              e && handleFieldChange('role', e.value);
            }}
            //hasError={checkValidation && !!errors?.relationship}
          />
          {/* <ErrorMessage error={errors?.relationship || ''} visible={checkValidation && !!errors?.relationship} /> */}
        </div>
        <Input
          id="referralProviderNumber"
          className={styles.fieldWrapper}
          label="Provider Number"
          name="providerNumber"
          labelClass={styles.inputLabel}
          required
          onChange={(e) => {
            handleFieldChange('providerNumber', e.target.value);
          }}
        />
      </div>

      <div className={styles.row}>
        <Input
          id="referralEmail"
          className={styles.fieldWrapper}
          label="Referral Email address*"
          name="referralEmail"
          labelClass={styles.inputLabel}
          required
          onChange={(e) => {
            handleFieldChange('email', e.target.value);
          }}
        />
        <div className={styles.field}>
          <MobilePhoneInput
            label={'Referral Mobile Phone Number'}
            value={values.mobile || ''}
            onChange={(e) => handleFieldChange('mobile', e)}
          />
        </div>
      </div>

      <div className={styles.row}>
        <div className={styles.field}>
          <UploadFiles
            id={'referralDocuments'}
            buttonText={'Upload Referral Documents'}
            files={values.files || []}
            handleChangeFiles={(e) => {
              handleFieldChange('files', e);
            }}
          />
        </div>

        <div className={styles.checkBoxText}>
          <CheckBox
            id="isNoReferral"
            value={!!values.isNoReferral}
            onChange={(e) => handleFieldChange('isNoReferral', e.target.checked)}
            className={styles.checkBox}
            label="I have a referral but it has been sent to you directly."
          />
        </div>
      </div>
    </div>
  );
};

export default ReferralForm;
