import { components, DropdownIndicatorProps } from 'react-select';
import styles from './DropdownIndicator.module.scss';

const DropdownIndicator = ({ children, ...props }: DropdownIndicatorProps<any>) => {
  return (
    <components.DropdownIndicator {...props}>
      <i className={`material-icons ${styles.icon}`}>arrow_drop_down</i>
    </components.DropdownIndicator>
  );
};

export default DropdownIndicator;
