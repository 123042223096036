import React from 'react';
import styles from './ProgressBar.module.scss';
import classNames from 'classnames';

interface ProgressBarProps {
  steps: { id: string; name: string; status?: 'current' | 'completed' }[];
}

const ProgressBar = ({ steps }: ProgressBarProps) => {
  return (
    <div className={styles.progressBar}>
      <div className={styles.stepRow}>
        {steps.map(({ status }, i) => (
          <>
            <div key={`step-${i}`} className={classNames(styles.step, styles[status || ''])} />
            {i !== steps.length - 1 && <div className={styles.line} />}
          </>
        ))}
      </div>
      <div className={styles.row}>
        {steps.map(({ status, name }, i) => (
          <div key={`name-${i}`} className={classNames(styles.label, styles[status || ''])}>
            {name}
          </div>
        ))}
      </div>
    </div>
  );
};
export default ProgressBar;
