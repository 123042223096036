import { Modal } from 'antd';
import styles from './ChangeAvatarModal.module.scss';
import Button from 'components/Button/Button';
import AvatarSelector from 'components/AvatarSelector/AvatarSelector';
import { useState } from 'react';

interface ChangeAvatarModalProps {
  open: boolean;
  onClose: () => void;
  onSelectAvatar: (dataString: string) => void;
}

const ChangeAvatarModal = ({ open, onClose, onSelectAvatar }: ChangeAvatarModalProps) => {
  const [selectedAvatar, setSelectedAvatar] = useState('');

  const handleSubmit = () => {
    onSelectAvatar(selectedAvatar);
    onClose();
  };

  return (
    <Modal title={null} footer={null} width={800} open={open} onCancel={onClose}>
      <div className={styles.container}>
        <div className={styles.title}>Choose your new avatar</div>
        <AvatarSelector onSelectAvatar={setSelectedAvatar} />
        <Button className={styles.saveButton} onClick={handleSubmit}>
          Save
        </Button>
      </div>
    </Modal>
  );
};

export default ChangeAvatarModal;
