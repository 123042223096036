import { ClientRecord } from 'interfaces/Client';
import { patientProfileServicesApiSlice } from 'reduxToolkit/services/patientProfileServiceApisSlice';

interface CreateClientRecordResponse {
  clientRecord: ClientRecord;
  authToken: string;
}

export interface CreateClientRecordPayload {
  accountId: string;
  payload: {
    clinicianId?: string;
    clientRecord: {
      avatar: string;
      mobile: string;
      email: string;
      name: string;
      password: string;
      dateOfBirth: string;
      firstName: string;
      lastName: string;
      postcode: string;
      communicationPreference: string;
      tag?: string;
      timeZone?: string;
      appointmentTypeId?: string;
    };
  };
}

const patientProfilesApiSlice = patientProfileServicesApiSlice.injectEndpoints({
  endpoints: (builder) => ({
    createClientRecordWithoutInvitation: builder.mutation<CreateClientRecordResponse, CreateClientRecordPayload>({
      query: ({ accountId, payload }) => ({
        url: `/accounts/${accountId}/patients`,
        method: 'POST',
        body: payload
      })
    }),

    createRecordOnlyWithoutInvitation: builder.mutation<CreateClientRecordResponse, CreateClientRecordPayload>({
      query: ({ accountId, payload }) => ({
        url: `/accounts/${accountId}/patients:recordOnly`,
        method: 'POST',
        body: payload
      })
    })
  })
});

export const { useCreateClientRecordWithoutInvitationMutation, useCreateRecordOnlyWithoutInvitationMutation } =
  patientProfilesApiSlice;
