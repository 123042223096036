import { FitDaysList } from 'interfaces/Engage/fitFilter';

export const sortWeekDays = (days: FitDaysList[]) => {
  const weekDays = [
    FitDaysList.monday,
    FitDaysList.tuesday,
    FitDaysList.wednesday,
    FitDaysList.thursday,
    FitDaysList.friday,
    FitDaysList.weekend
  ];
  return weekDays.filter((each) => days.includes(each));
};

export const getDaysFromQuery = (days?: FitDaysList[]) => {
  return days?.length ? (days?.toString().split(',') as FitDaysList[]) : undefined;
};
