import { ChangeEvent, useCallback, useEffect, useState } from 'react';
import styles from './DateInput.module.scss';
import dayjs, { DateFormat } from 'utils/dayjsExtended';
import ErrorMessage from 'components/ErrorMessage/ErrorMessage';
import classNames from 'classnames';

interface DateInputProps {
  id?: string;
  label: string;
  value: string;
  error?: string;
  format?: DateFormat;
  onChange: (value: string) => void;
  onError?: (hasError: boolean) => void;
}

const getSeparatedDate = (date: string, format: DateFormat) => {
  const dateDayjs = dayjs(date, format);

  return (
    dateDayjs.isValid() &&
    dateDayjs.format(format) === date && {
      ...(format !== DateFormat.MONTH_YEAR && { day: dateDayjs.format('DD') }),
      month: dateDayjs.format('MM'),
      year: dateDayjs.format('YYYY')
    }
  );
};

const getCombinedDate = (day: string | undefined, month: string, year: string, format: DateFormat) => {
  if (format === DateFormat.MONTH_YEAR) {
    return `${month}/${year}`;
  }

  if (format === DateFormat.DAY_MONTH_YEAR) {
    return `${day}/${month}/${year}`;
  }

  if (format === DateFormat.YEAR_MONTH_DAY) {
    return `${year}-${month}-${day}`;
  }

  // this is here because of typescript
  // how can it even reach here?
  return '';
};

const DateInput = ({
  id,
  label,
  value,
  error: propsError,
  format = DateFormat.YEAR_MONTH_DAY,
  onChange
}: DateInputProps) => {
  const [day, setDay] = useState('');
  const [month, setMonth] = useState('');
  const [year, setYear] = useState('');

  const [dayError, setDayError] = useState('');
  const [monthError, setMonthError] = useState('');
  const [yearError, setYearError] = useState('');
  const [error, setError] = useState('');
  const [touched, setTouched] = useState(false);

  useEffect(() => {
    const separatedDate = getSeparatedDate(value, format);

    if (separatedDate) {
      setDay(separatedDate.day || '');
      setMonth(separatedDate.month);
      setYear(separatedDate.year);
    }
  }, [format, value]);

  const validateDate = useCallback(() => {
    const combinedDate = getCombinedDate(day, month, year, format);

    const parsedDayjs = dayjs(combinedDate, format);

    if (combinedDate === parsedDayjs.format(format)) {
      setDayError('');
      setMonthError('');
      setYearError('');
      setError('');
    } else {
      const dayNumber = Number(day);
      const monthNumber = Number(month);

      const isMonthError = month.length === 2 && (monthNumber < 1 || monthNumber > 12);
      const isYearError = year.length > 0 && year.length < 4;

      const correctDaysInMonth = dayjs()
        .year(Number(year))
        // month starts at 0
        .month(monthNumber - 1)
        .daysInMonth();

      if (
        format !== DateFormat.MONTH_YEAR &&
        day.length === 2 &&
        !isYearError &&
        !isMonthError &&
        ((parsedDayjs.isValid() && dayNumber > correctDaysInMonth) || dayNumber < 1 || dayNumber > 31)
      ) {
        setDayError(`Date must be 01-${correctDaysInMonth || 31}`);
      } else {
        setDayError('');
      }

      if (isMonthError) {
        setMonthError('Month must be 01-12');
      } else {
        setMonthError('');
      }

      if (isYearError) {
        setYearError('Year must be in YYYY format');
      } else {
        setYearError('');
      }

      setError('Invalid date');
    }

    return combinedDate;
  }, [day, format, month, year]);

  useEffect(() => {
    const combinedDate = validateDate();
    combinedDate !== value && onChange(combinedDate);
  }, [onChange, validateDate, value]);

  const handleFocus = () => {
    setTouched(true);
  };

  const handleDayBlur = () => {
    if (day && day.length < 2 && !isNaN(Number(day))) {
      setDay(day.padStart(2, '0'));
    }
  };
  const handleMonthBlur = () => {
    if (month && month.length < 2 && !isNaN(Number(month))) {
      setMonth(month.padStart(2, '0'));
    }
  };

  const handleDayChange = (e: ChangeEvent<HTMLInputElement>) => {
    setDay(e.target.value.replaceAll(/\D/g, '') || '');
    setTouched(true);
  };
  const handleMonthChange = (e: ChangeEvent<HTMLInputElement>) => {
    setMonth(e.target.value.replaceAll(/\D/g, '') || '');
    setTouched(true);
  };
  const handleYearChange = (e: ChangeEvent<HTMLInputElement>) => {
    setYear(e.target.value.replaceAll(/\D/g, '') || '');
    setTouched(true);
  };

  const showBaseError = touched && !(dayError || monthError || yearError) && !!(error || propsError);

  return (
    <div className={classNames(styles.container, showBaseError && styles.error)} id={id}>
      <label className={styles.label} htmlFor={`${id}-day-field`}>
        {label}
      </label>
      <div className={styles.inputRow}>
        {format !== DateFormat.MONTH_YEAR && (
          <>
            <input
              className={classNames(styles.input, styles.day, touched && !!dayError && styles.error)}
              id={`${id}-day-field`}
              placeholder="dd"
              value={day}
              maxLength={2}
              onBlur={handleDayBlur}
              onFocus={handleFocus}
              onChange={handleDayChange}
            />
            <div className={styles.separator}>/</div>
          </>
        )}

        <input
          className={classNames(styles.input, styles.month, touched && !!monthError && styles.error)}
          id={`${id}-month-field`}
          placeholder="mm"
          value={month}
          maxLength={2}
          onBlur={handleMonthBlur}
          onFocus={handleFocus}
          onChange={handleMonthChange}
        />
        <div className={styles.separator}>/</div>
        <input
          className={classNames(styles.input, styles.year, touched && !!yearError && styles.error)}
          id={`${id}-year-field`}
          placeholder="yyyy"
          value={year}
          maxLength={4}
          onFocus={handleFocus}
          onChange={handleYearChange}
        />
      </div>
      <ErrorMessage error={dayError} visible={touched && !!dayError} padLeft />
      <ErrorMessage error={monthError} visible={touched && !!monthError} padLeft />
      <ErrorMessage error={yearError} visible={touched && !!yearError} padLeft />
      <ErrorMessage error={error || propsError} visible={showBaseError} padLeft />
    </div>
  );
};

export default DateInput;
