import classNames from 'classnames';

import styles from './CardWrapper.module.scss';
import { ReactNode } from 'react';

interface CardWrapperProps {
  children: ReactNode;
  className?: string;
}

const CardWrapper = ({ children, className }: CardWrapperProps) => {
  return <div className={classNames(styles.container, className)}>{children}</div>;
};

export default CardWrapper;
