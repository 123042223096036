import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import isBetween from 'dayjs/plugin/isBetween';

dayjs.extend(customParseFormat);
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(isBetween);

export const isDST = (timezone: string) => {
  const now = dayjs().tz(timezone);
  const standardTimeOffset = dayjs(now).startOf('year').tz(timezone, true).utcOffset();
  return now.utcOffset() !== standardTimeOffset;
};

export default dayjs;

export enum DateFormat {
  YEAR_MONTH_DAY = 'YYYY-MM-DD',
  DAY_MONTH_YEAR = 'DD/MM/YYYY',
  MONTH_YEAR = 'MM/YYYY'
}
