import { Route, Routes } from 'react-router-dom';
import { ENGAGE_ROUTES_LIST } from './constants';
import PsychologistListing from 'pages/Engage/PsychologistListing/PsychologistListing';
import SignUpPage from 'pages/SignUp/SignUpPage';
import RequireInfoPage from 'pages/RequireInfoPage/RequireInfoPage';

const { PRACTITIONER, SIGNUP } = ENGAGE_ROUTES_LIST;

const EngageRoutes = () => {
  return (
    <Routes>
      <Route>
        <Route path={PRACTITIONER.LISTING} element={<PsychologistListing />} />
        <Route path={PRACTITIONER.LISTING_WITH_FILTER} element={<PsychologistListing />} />
        <Route path={SIGNUP.BASE} element={<SignUpPage />} />
        <Route path={SIGNUP.REQUIRED_INFO} element={<RequireInfoPage />} />
      </Route>
    </Routes>
  );
};

export default EngageRoutes;
