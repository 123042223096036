import styles from './PsychologistSpecialisation.module.scss';
import classNames from 'classnames';
import { Link } from 'react-router-dom';

interface PsychologistSpecialisationProps {
  specialisationsDetails: string[];
  detailPath?: string;
  selectedValue?: string[];
  vertical?: boolean;
  badgeClassName?: string;
  matchedSpecialisations?: string[];
}

const PsychologistSpecialisation = ({
  specialisationsDetails,
  detailPath,
  selectedValue,
  vertical,
  badgeClassName,
  matchedSpecialisations
}: PsychologistSpecialisationProps) => {
  const formattedList = specialisationsDetails.sort((a) => (selectedValue?.includes(a) ? -1 : 1));

  const limit = 2;

  return (
    <div className={styles.container}>
      <div className={vertical ? styles.specialisationBadgeVerticalWrapper : styles.specialisationBadgeWrapper}>
        <div className={styles.badgeList}>
          {formattedList.slice(0, limit).map((obj, index) => (
            <div
              key={index}
              className={classNames(
                selectedValue?.includes(obj) || matchedSpecialisations?.includes(obj)
                  ? styles.specialisationBadgeSelected
                  : styles.specialisationBadge,
                badgeClassName
              )}
            >
              {obj}
            </div>
          ))}
        </div>
        {formattedList.length > limit && (
          <Link to={detailPath || ''} className={styles.seeMoreSlot}>
            ...+{formattedList.length - limit} MORE
          </Link>
        )}
      </div>
    </div>
  );
};

export default PsychologistSpecialisation;
