import { UserContextProvider } from 'contexts/UserContextProvider';
import AuthLayout from 'layouts/AuthLayout/AuthLayout';
import HomePage from 'pages/HomePage/HomePage';
import { Route, Routes } from 'react-router-dom';
import { PORTAL_ROUTES_LIST } from './constants';
import UpdateProfile from 'pages/UpdateProfile/UpdateProfile';
import EngageRoutes from './EngageRoutes';

const { HOME, UPDATE_PROFILE } = PORTAL_ROUTES_LIST;

const AuthRoutes = () => {
  return (
    <UserContextProvider>
      <Routes>
        <Route path={'/*'} element={<EngageRoutes />} />
        <Route element={<AuthLayout />}>
          <Route path={HOME} element={<HomePage />} />
          <Route path={UPDATE_PROFILE} element={<UpdateProfile />} />
        </Route>
      </Routes>
    </UserContextProvider>
  );
};

export default AuthRoutes;
