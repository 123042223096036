import { useRef, useState } from 'react';

import styles from './TimeZoneDropdown.module.scss';
import classNames from 'classnames';
import useOnClickOutside from 'utils/hooks/useOnClickOutside';
import { Options } from 'interfaces/common/general';

interface TimeZoneDropdownProps {
  listData: Options[];
  selectedValue: Options;
  onChangeValue: (val: Options) => void;
  disabled?: boolean;
  selectClassName?: string;
  direction?: 'vertical';
  className?: string;
}

const TimeZoneDropdown = ({
  listData,
  selectedValue,
  onChangeValue,
  disabled,
  selectClassName,
  direction,
  className
}: TimeZoneDropdownProps) => {
  const timeZoneMenuRef = useRef<HTMLDivElement>(null);
  const [showList, setShowList] = useState(false);

  const handleCloseMenu = () => {
    setShowList(false);
  };

  const handleSelectButtonClick = () => {
    if (disabled) {
      return;
    }

    if (showList) {
      handleCloseMenu();
    } else {
      setShowList(true);
    }
  };

  const clickOutsideHandler = () => {
    handleCloseMenu();
  };

  useOnClickOutside(timeZoneMenuRef, clickOutsideHandler);

  const handleChangeFilter = (val: Options) => {
    onChangeValue(val);
    handleCloseMenu();
  };

  return (
    <div ref={timeZoneMenuRef} className={classNames(styles.container, className)}>
      <div
        className={classNames(
          styles.selectButton,
          selectClassName,
          disabled && styles.disabled,
          direction === 'vertical' && styles.vertical
        )}
        onClick={handleSelectButtonClick}
      >
        <div className={styles.selectedValue}>
          <div className={`material-symbols-outlined ${styles.icon}`}>public</div>
          {selectedValue.label}
          <div className={`material-symbols-outlined ${styles.dropdownIcon}`}>arrow_drop_down</div>
        </div>
      </div>
      <div className={styles.menuWrapper}>
        <div className={showList ? styles.menuBoxShow : styles.menuBoxHide}>
          {listData.map((listObj, index) => (
            <div key={index} className={styles.listBox} onClick={() => handleChangeFilter(listObj)}>
              <div className={styles.title}>{listObj.label}</div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default TimeZoneDropdown;
