import { useAuth0 } from '@auth0/auth0-react';
import classNames from 'classnames';
import { useMemo } from 'react';
import { Link, generatePath } from 'react-router-dom';
import HeaderMenu, { HeaderMenuItem } from './components/HeaderMenu/HeaderMenu';
import styles from './ClientHeader.module.scss';
import { Skeleton } from 'antd';
import { useCustomLogo } from 'utils/hooks/useCustomLogo';
import { useGetAccessToken } from 'utils/hooks/useGetAccessToken';
import { useLogout } from 'utils/hooks/logout';
import { ENGAGE_ROUTES_LIST, PORTAL_ROUTES_LIST } from 'routes/constants';
import { useGetCheckInStatusQuery } from 'reduxToolkit/endpoints/checkInService/checkIn';
import {
  useGetAttachedClinicianDetails,
  useGetClientProfileDetails
} from 'utils/hooks/clinicianProfileService/getClientDetails';
import { useRedirectStripeUpdatePaymentDetails } from 'utils/hooks/billingService/useRedirectStripeUpdatePaymentDetails';
import LoadingDots from 'components/LoadingDots/LoadingDots';
import ClientProfileAvatar from 'components/ClientProfileAvatar/ClientProfileAvatar';
import { IS_DEVELOPMENT } from 'utils/featureToggle';
import { brandConfig } from 'brand/config';

const { APPOINTMENT_OVERVIEW, CHECKIN, HOME, PENDING_ACTIONS, REFERRAL, UPDATE_PROFILE } = PORTAL_ROUTES_LIST;
const { PRACTITIONER } = ENGAGE_ROUTES_LIST;

interface ClientHeaderProps {
  withMenu?: boolean;
  noPadding?: boolean;
  darkFont?: boolean;
  hideLoginMenu?: boolean;
}

const ClientHeader = ({ withMenu, noPadding, darkFont, hideLoginMenu = false }: ClientHeaderProps) => {
  const { user, isAuthenticated, loginWithRedirect } = useAuth0();

  const { logout } = useLogout();
  const {
    userInfo: { initials },
    isQueryParamToken
  } = useGetAccessToken(true, true);

  const { darkLogo: darkCustomLogo, lightLogo: lightCustomLogo, isLogoLoading } = useCustomLogo();
  const { attachedClinicianDetail } = useGetAttachedClinicianDetails();
  const { clientProfileData } = useGetClientProfileDetails();
  const { redirectToStripeUpdatePaymentDetailsLink, isLoading: isStripeLoading } =
    useRedirectStripeUpdatePaymentDetails();

  const clinicianSlugUrlOrId =
    attachedClinicianDetail?.clinician?._id || attachedClinicianDetail?.clinician?.slugUrl || '';

  const { data: checkInStatus } = useGetCheckInStatusQuery(
    { clinicianSlugUrlOrId },
    {
      skip: !clinicianSlugUrlOrId,
      pollingInterval: 10 * 60 * 1000 // 10 minutes
    }
  );

  const {
    brand,
    link,
    pageConfig: { headerMenu: headerMenuConfig }
  } = brandConfig;

  const menuItems: HeaderMenuItem[] = useMemo(
    () => [
      { name: 'Home', url: HOME, isExternal: false },
      { name: 'Actions', url: PENDING_ACTIONS, isExternal: false },
      { name: 'Edit profile', url: UPDATE_PROFILE, isExternal: false },
      ...(IS_DEVELOPMENT && checkInStatus && clinicianSlugUrlOrId
        ? [{ name: 'View my journal (dev)', url: generatePath(CHECKIN, { clinicianSlugUrlOrId }), isExternal: false }]
        : []),
      { name: 'Manage appointments', url: APPOINTMENT_OVERVIEW, isExternal: false },
      ...(IS_DEVELOPMENT ? [{ name: 'Referral details (dev)', url: REFERRAL, isExternal: false }] : []),
      ...(headerMenuConfig.allowEditStripeCard && isAuthenticated
        ? [{ name: 'Payment details', isExternal: true, onClick: redirectToStripeUpdatePaymentDetailsLink }]
        : []),
      ...(link.contactUsUrl ? [{ name: 'Contact team', url: link.contactUsUrl, isExternal: true }] : [])
    ],
    [
      checkInStatus,
      clinicianSlugUrlOrId,
      headerMenuConfig,
      link,
      redirectToStripeUpdatePaymentDetailsLink,
      isAuthenticated
    ]
  );

  const bookNewAppointmentUrl = attachedClinicianDetail?.clinician
    ? generatePath(PRACTITIONER.DETAILS, {
        clinicianId: attachedClinicianDetail.clinician._id
      })
    : PRACTITIONER.LISTING;

  console.log(isAuthenticated, '>>> isAuthenticated');

  return (
    <>
      {isStripeLoading && (
        <div className={styles.loading}>
          <LoadingDots />
        </div>
      )}
      <div className={classNames(styles.container, noPadding && styles.noPadding)}>
        {isLogoLoading ? (
          <div className={styles.logoLoading}>
            <Skeleton.Input active className={styles.logoLoadingImg} />
          </div>
        ) : (
          <Link className={styles.logoImageWrapper} to={HOME}>
            <img className={styles.logoImage} src={darkFont ? darkCustomLogo : lightCustomLogo} alt={`${brand} logo`} />
          </Link>
        )}
        {!hideLoginMenu && (
          <>
            {isAuthenticated ? (
              <div className={styles.profileWrapper}>
                <div className={styles.profileBox}>
                  <ClientProfileAvatar
                    avatarClassName={styles.profile}
                    avatarUrl={clientProfileData?.avatar || user?.picture}
                    initialsName={initials}
                    tokenUser={isQueryParamToken}
                    fullAvatarBg
                  />
                  {!isQueryParamToken && withMenu && isAuthenticated && (
                    <HeaderMenu menuItems={menuItems} bookNewAppointmentUrl={bookNewAppointmentUrl} />
                  )}
                </div>
                {!isQueryParamToken && !withMenu && (
                  <div className={styles.logOutBtn} onClick={logout}>
                    Log out <i className={`material-icons ${styles.icon}`}>exit_to_app</i>
                  </div>
                )}
              </div>
            ) : (
              <div className={styles.loginWrapper}>
                <div
                  onClick={() => loginWithRedirect({ appState: { returnTo: window.location.pathname } })}
                  className={classNames(darkFont ? styles.login : styles.loginWhite)}
                >
                  LOGIN
                </div>
              </div>
            )}
          </>
        )}
      </div>
    </>
  );
};

export default ClientHeader;
