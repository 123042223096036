import { ReactNode } from 'react';
import { Helmet } from 'react-helmet-async';
import { brandConfig } from '../../brand/config';

interface HelmetWrapperProps {
  title?: string;
  children?: ReactNode;
}

const HelmetWrapper = ({ title, children }: HelmetWrapperProps) => {
  return (
    <>
      <Helmet>
        <title>
          {brandConfig.metaInfo.title}
          {title ? ` | ${title}` : ''}
        </title>
      </Helmet>
      {children}
    </>
  );
};

export default HelmetWrapper;
