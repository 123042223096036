import React, { ChangeEvent } from 'react';
import styles from './UploadFiles.module.scss';
import { checkReferralFileSize } from './utils/utils';
import { notification } from 'antd';

interface UploadFilesProps {
  id?: string;
  buttonText: string;
  files: File[];
  handleChangeFiles: (files: File[]) => void;
}

const UploadFiles = ({ id, files, buttonText, handleChangeFiles }: UploadFilesProps) => {
  const renderFileName = (index: number, fileName: string, buttonText: string, onClick: () => void) => {
    return (
      <div key={index} className={styles.fileNameContainer}>
        <div className={styles.fileName}>{fileName}</div>
        <i className={`material-icons ${styles.removeButton}`} onClick={onClick}>
          close
        </i>
      </div>
    );
  };

  const handleUploadFile = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      const uploadedFiles = Array.from(event.target.files);
      console.log(uploadedFiles);
      const validatedFiles: File[] = [];
      uploadedFiles
        .filter(({ name }) => !files.map((item) => item.name).includes(name))
        .forEach((file) => {
          const fileSizeValidate = checkReferralFileSize(file);
          if (!fileSizeValidate.valid) {
            notification.error({ message: fileSizeValidate.message });
          } else {
            validatedFiles.push(file);
          }
        });

      handleChangeFiles([...files, ...validatedFiles]);
    }
    event.target.value = '';
  };

  return (
    <div className={styles.container}>
      <div className={styles.uploadButtonContainer}>
        <input
          className={styles.uploadInput}
          type={'file'}
          id={`files-${id}`}
          onChange={handleUploadFile}
          accept={'.pdf,.docx,image/png,image/jpeg'}
          multiple
        />
        <label htmlFor={`files-${id}`}>
          <div className={styles.uploadButton}>
            <i className={`material-icons`}>upload</i>
            <div className={styles.publishLabel}>{buttonText}</div>
          </div>
        </label>
      </div>
      <div className={styles.fileList}>
        {files &&
          files.map((file, index) =>
            renderFileName(index, file.name, 'Remove', () =>
              handleChangeFiles(files.filter((fil) => fil.name !== file.name))
            )
          )}
      </div>
    </div>
  );
};

export default UploadFiles;
