import { useState } from 'react';

import styles from './FitFilter.module.scss';
import FitFilterMobilePopup, { FitFilterInterface } from './components/FitFilterMobilePopup/FitFilterMobilePopup';

import classNames from 'classnames';
import {
  generateDaysList,
  generateGenderList,
  generateLanguageList,
  generateStylesList,
  generateTimesList
} from 'components/EngageComponent/utils/generateFitFilters';
import ContentLayout from '../../BaseComponent/ContentLayout/ContentLayout';
import FilterDropdown, { listInterface } from '../FilterDropdown/FilterDropdown';
import {
  FIT_DAYS_LIST_LABELS,
  FIT_LANGUAGE_LIST_LABELS,
  FIT_PSYCHOLOGIST_GENDER_LIST_LABELS,
  FIT_TIMES_LIST_LABELS,
  FIT_STYLE_LIST_LABELS,
  FitDaysList,
  FitLanguageList,
  FitPsychologistGenderList,
  FitTimesList,
  FitStyleList,
  PsychologistFitFilter,
  listingViewType
} from 'interfaces/Engage/fitFilter';
import FilterDropdownMultiSelect from '../FilterDropdown/components/FilterDropdownMultiSelect/FilterDropdownMultiSelect';

interface FitFilterProps {
  selectedFitValue?: PsychologistFitFilter;
  onChangeFitValue: (value: PsychologistFitFilter) => void;
  handleFitResetValue: () => void;
  isLoading?: boolean;
}

const FitFilter = ({ selectedFitValue, onChangeFitValue, handleFitResetValue, isLoading }: FitFilterProps) => {
  const [isFilterModalShow, setIsFilterModalShow] = useState(false);
  const [filterIsSelected, setFilterIsSelected] = useState<string>('');

  const generateList = (id?: any, listLabel?: any, listEnum?: any) => {
    return id ? { value: id, label: listLabel[id as keyof typeof listEnum] } : undefined;
  };

  const massageFitGender = generateList(
    selectedFitValue?.gender,
    FIT_PSYCHOLOGIST_GENDER_LIST_LABELS,
    FitPsychologistGenderList
  );
  const massageFitDays = selectedFitValue?.days?.map((item) => ({ value: item, label: FIT_DAYS_LIST_LABELS[item] }));
  const massageFitTimes = generateList(selectedFitValue?.time, FIT_TIMES_LIST_LABELS, FitTimesList);
  const massageFitLanguage = generateList(selectedFitValue?.language, FIT_LANGUAGE_LIST_LABELS, FitLanguageList);
  const massageFitStyles = generateList(selectedFitValue?.style, FIT_STYLE_LIST_LABELS, FitStyleList);
  const [scrollCount, setScrollCount] = useState<number>(0);

  const handleChangeGender = (value: listInterface | undefined) => {
    const massageFitCollection: PsychologistFitFilter = {
      ...selectedFitValue,
      gender: value?.value as FitPsychologistGenderList
    };
    onChangeFitValue(massageFitCollection);
  };

  const handleChangeDays = (value: listInterface) => {
    const isRemoveAction = selectedFitValue?.days?.some((item) => item === value.value);
    const days = isRemoveAction
      ? selectedFitValue?.days?.filter((item) => item !== value.value)
      : [...(selectedFitValue?.days || []), value.value as FitDaysList];

    onChangeFitValue({ ...selectedFitValue, days });
  };

  const handleChangeTimes = (value: listInterface | undefined) => {
    const massageFitCollection: PsychologistFitFilter = {
      ...selectedFitValue,
      time: value?.value as FitTimesList
    };
    onChangeFitValue(massageFitCollection);
  };

  const handleChangeLanguage = (value: listInterface | undefined) => {
    const massageFitCollection: PsychologistFitFilter = {
      ...selectedFitValue,
      language: value?.value as FitLanguageList
    };
    onChangeFitValue(massageFitCollection);
  };

  const handleChangeStyles = (value: listInterface | undefined) => {
    const massageFitCollection: PsychologistFitFilter = {
      ...selectedFitValue,
      style: value?.value as FitStyleList
    };
    onChangeFitValue(massageFitCollection);
  };

  const handleChangeView = (value: listingViewType) => {
    const massageFitCollection: PsychologistFitFilter = {
      ...selectedFitValue,
      view: value
    };
    onChangeFitValue(massageFitCollection);
  };

  const onFilterClickHandle = (filterName: string) => {
    setFilterIsSelected(filterName);
    setIsFilterModalShow(true);
  };

  const selectedFilters: FitFilterInterface = {
    gender: massageFitGender,
    days: massageFitDays,
    time: massageFitTimes,
    language: massageFitLanguage,
    style: massageFitStyles
  };

  return (
    <div className={styles.container}>
      <ContentLayout>
        <div className={styles.content}>
          <div className={classNames(styles.labelWrapper, styles.showWhenMobile)}>
            <div className={styles.desc}>Filter psychologists by...</div>
          </div>
          <div className={styles.filterWrapper} onScroll={() => setScrollCount(scrollCount + 1)}>
            <div className={classNames(styles.labelWrapper, styles.hideWhenMobile)}>
              <div className={styles.title}>REFINE YOUR FIT</div>
              <div className={styles.desc}>Filter psychologists by...</div>
            </div>
            <div className={styles.fitFilterWrapper}>
              <FilterDropdown
                label={'Gender'}
                noFloatLabel
                badgeStyles
                listData={generateGenderList()}
                onChangeValue={handleChangeGender}
                selectedValue={massageFitGender}
                className={styles.gender}
                refreshPosition={scrollCount}
                onClick={() => onFilterClickHandle('gender')}
                disabled={isLoading}
                dropdownClassName={styles.dropdownStyle}
              />
              <FilterDropdownMultiSelect
                id="dayValues"
                label="Days"
                listData={generateDaysList()}
                onChangeValue={handleChangeDays}
                selectedMultipleValue={massageFitDays || []}
                className={styles.days}
                onClick={() => onFilterClickHandle('days')}
                disabled={isLoading}
                menuWrapperClass={styles.daysMenuWrapperClass}
                moreLabel="days"
                maxDisplayItem={2}
                dropdownClassName={styles.dropdownStyle}
                hideTitle
              />
              <FilterDropdown
                label="Timing"
                noFloatLabel
                badgeStyles
                listData={generateTimesList()}
                onChangeValue={handleChangeTimes}
                selectedValue={massageFitTimes}
                className={styles.times}
                refreshPosition={scrollCount}
                onClick={() => onFilterClickHandle('time')}
                disabled={isLoading}
                dropdownClassName={styles.dropdownStyle}
              />
              <FilterDropdown
                label={'Language'}
                noFloatLabel
                badgeStyles
                listData={generateLanguageList()}
                onChangeValue={handleChangeLanguage}
                selectedValue={massageFitLanguage}
                className={styles.language}
                refreshPosition={scrollCount}
                onClick={() => onFilterClickHandle('language')}
                disabled={isLoading}
                dropdownClassName={styles.dropdownStyle}
              />
              <FilterDropdown
                label={'Style'}
                noFloatLabel
                badgeStyles
                listData={generateStylesList()}
                onChangeValue={handleChangeStyles}
                selectedValue={massageFitStyles}
                className={styles.style}
                refreshPosition={scrollCount}
                onClick={() => onFilterClickHandle('style')}
                disabled={isLoading}
                dropdownClassName={styles.dropdownStyle}
              />
              <div
                className={classNames(styles.fitReset, isLoading && styles.disabled)}
                onClick={() => {
                  !isLoading && handleFitResetValue();
                }}
              >
                Clear All
              </div>
            </div>
            <div
              onClick={() => {
                handleChangeView(
                  selectedFitValue?.view === listingViewType.gridView
                    ? listingViewType.listView
                    : listingViewType.gridView
                );
              }}
              className={`material-symbols-outlined ${styles.viewIcon}`}
            >
              {selectedFitValue?.view === listingViewType.gridView ? 'view_list' : 'grid_view'}
            </div>
          </div>
        </div>
        <FitFilterMobilePopup
          visible={isFilterModalShow}
          filterIsSelected={filterIsSelected}
          selectedFiltersProp={selectedFilters}
          genderOptions={generateGenderList()}
          dayOptions={generateDaysList()}
          timeOptions={generateTimesList()}
          languageOptions={generateLanguageList()}
          styleOptions={generateStylesList()}
          onView={onChangeFitValue}
          onClearAll={handleFitResetValue}
          onCancel={() => setIsFilterModalShow(false)}
        />
      </ContentLayout>
    </div>
  );
};

export default FitFilter;
