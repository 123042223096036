import React from 'react';
import styles from './AppointmentDetails.module.scss';
import Timer from '../Timer/Timer';

interface AppointmentDetailsProps {
  appointment: any;
  includeWhyUsSection?: boolean;
}

const AppointmentDetails = ({ appointment, includeWhyUsSection = false }: AppointmentDetailsProps) => {
  return (
    <div className={styles.container}>
      <div className={styles.timerContainer}>
        <Timer isReservedAppointmentFetching={false} />
        <div className={styles.timerLabel}>Please complete registration to confirm your appointment</div>
      </div>
      <div className={styles.appointmentInfo}>
        <div className={styles.row}>
          <div className={styles.fieldData}>
            <div className={styles.title}>SERVICE</div>
            <div className={styles.detail}>General Psychologist Bulk Bill for 50 mins (start on half hour)</div>
          </div>

          <div className={styles.fieldData}>
            <div className={styles.title}>FEE</div>
            <div className={styles.detail}>$136.50</div>
          </div>
        </div>
        <div className={styles.row}>
          <div className={styles.fieldData}>
            <div className={styles.title}>PRACTITIONER</div>
            <div className={styles.detail}>Juliet Gallagher</div>
          </div>

          <div className={styles.fieldData}>
            <div className={styles.onHoldStatus}>ON HOLD</div>
          </div>
        </div>
        <div className={styles.row}>
          <div className={styles.fieldData}>
            <div className={styles.title}>DATE</div>
            <div className={styles.detail}>03 / 07 / 2024</div>
          </div>

          <div className={styles.fieldData}>
            <div className={styles.title}>DAY</div>
            <div className={styles.detail}>Wednesday</div>
          </div>
        </div>
        <div className={styles.row}>
          <div className={styles.fieldData}>
            <div className={styles.title}>START</div>
            <div className={styles.detail}>8:30PM</div>
            <div className={styles.timeZone}>AEDT (VIC, NSW)</div>
          </div>

          <div className={styles.fieldData}>
            <div className={styles.title}>END</div>
            <div className={styles.detail}>9:20PM</div>
          </div>
        </div>
      </div>

      {includeWhyUsSection && (
        <div className={styles.whyUs}>
          <div className={styles.title}>WHY US</div>
          <div className={styles.detail}>
            We are dedicated to providing compassionate, personalised care to support your mental well-being. Our team
            of experienced psychologists specialises in a range of therapeutic approaches tailored to meet your unique
            needs. We offer a safe, confidential environment where you can explore your thoughts and feelings without
            judgment. Our commitment is to empower you with the tools and strategies to navigate life’s challenges and
            enhance your quality of life. By choosing us, you’re taking a positive step toward healing and personal
            growth. Let us partner with you on your journey toward a healthier, happier you.
          </div>
        </div>
      )}
    </div>
  );
};

export default AppointmentDetails;
