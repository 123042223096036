import HelmetWrapper from 'components/HelmetWrapper/HelmetWrapper';
import styles from './UpdateProfile.module.scss';
import ContentLayout from 'components/BaseComponent/ContentLayout/ContentLayout';
import ClientHeader from 'components/ClientHeader/ClientHeader';
import TacklitFooter from 'components/TacklitFooter/TacklitFooter';
import ProfileDetails from './components/ProfileDetails/ProfileDetails';

const UpdateProfile = () => {
  return (
    <HelmetWrapper title="Profile">
      <div className={styles.container}>
        <div>
          <ContentLayout className={styles.content}>
            <ClientHeader withMenu />
            <ProfileDetails />
          </ContentLayout>
        </div>
        <TacklitFooter wrapperClassName={styles.footer} darkFooter />
      </div>
    </HelmetWrapper>
  );
};

export default UpdateProfile;
