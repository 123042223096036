import styles from './PsychologistAvailabilityCalendar.module.scss';
import { Link } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import dayjs from 'utils/dayjsExtended';
import { PractitionerDetailsInterface } from 'interfaces/Engage/publicProfile';
import { AppointmentType, AppointmentTypeList } from 'interfaces/Clinician/enums';

interface PractitionerAvailabilityCalendarProps {
  practitionerDetails: PractitionerDetailsInterface;
  nextAvailabilityDetails: PractitionerDetailsInterface['nextAvailabilities'];
  detailPath: string;
  selectedSessionType?: AppointmentType;
  noFilter: boolean;
  cardWidth: number;
}

const todayDate = dayjs();

const PsychologistAvailabilityCalendar = ({
  practitionerDetails,
  nextAvailabilityDetails,
  detailPath,
  selectedSessionType,
  noFilter,
  cardWidth
}: PractitionerAvailabilityCalendarProps) => {
  const { isAuthenticated } = useAuth0();
  const selectedAppointmentTypeName =
    (selectedSessionType &&
      AppointmentTypeList.find((obj) => encodeURIComponent(obj.id) === encodeURIComponent(selectedSessionType))
        ?.label) ||
    AppointmentTypeList[0].label;

  const massageAvailabilityData = noFilter
    ? nextAvailabilityDetails
    : nextAvailabilityDetails?.filter(
        (obj) =>
          encodeURIComponent(obj.appointmentTypeName?.toLowerCase()) ===
          encodeURIComponent(selectedAppointmentTypeName.toLowerCase())
      );

  const noSlotForNewClient =
    isAuthenticated &&
    practitionerDetails.helmControl?.shouldUseCaseLoad &&
    practitionerDetails.caseLoad?.remainingSlots;

  const daySlotLimit = cardWidth <= 467 ? 2 : 3;

  return (
    <div className={styles.container}>
      {noSlotForNewClient ? (
        <div className={styles.noAvailableWrapper}>
          <div className={`material-symbols-outlined ${styles.noAvailIcon}`}>event_busy</div>
          <div className={styles.noAvailDesc}>
            {practitionerDetails.name} has no availability for new appointments in the next 4 weeks.
          </div>
        </div>
      ) : massageAvailabilityData && massageAvailabilityData.some(({ availabilities }) => availabilities.length) ? (
        <>
          {massageAvailabilityData?.map((availabilityAppointmentTypeObj, index) => (
            <div className={styles.calendarCardWrapper} key={index}>
              {availabilityAppointmentTypeObj.availabilities.slice(0, daySlotLimit).map((availabilitiesObj, index) => {
                const availabilityDate = dayjs(availabilitiesObj.date);
                const dayDifference = availabilityDate.diff(todayDate, 'days', true) + 1;

                const massageAvailabilityTimeSlot =
                  dayDifference <= 0
                    ? availabilitiesObj.timeSlots.filter((obj) => dayjs(obj.startTime, 'hh:mma').isAfter(todayDate))
                    : availabilitiesObj.timeSlots;

                const availabilityTimeSlot = massageAvailabilityTimeSlot[0];
                const startTime = dayjs(availabilityTimeSlot.startTime, 'hh:mmA').format('h:mmA');
                const selectedDateTime = `selectedDateTime=${availabilitiesObj.date},${availabilityTimeSlot.startTime},${availabilityTimeSlot.endTime}`;

                return (
                  availabilityTimeSlot && (
                    <Link key={index} className={styles.calendarSlotCard} to={`${detailPath}?${selectedDateTime}`}>
                      <div className={styles.calendarDateInfo}>
                        <div className={styles.date}>{dayjs(availabilitiesObj.date).format('DD')}</div>
                        <div className={styles.month}>{dayjs(availabilitiesObj.date).format('MMM')}</div>
                      </div>
                      <div className={styles.timeslotWrapper}>
                        <div className={styles.day}>{dayjs(availabilitiesObj.date).format('dddd').toUpperCase()}</div>
                        <div className={styles.time}>{startTime}</div>
                      </div>
                    </Link>
                  )
                );
              })}
            </div>
          ))}
        </>
      ) : (
        <div className={styles.noAvailableWrapper}>
          <div className={`material-symbols-outlined ${styles.noAvailIcon}`}>event_busy</div>
          <div className={styles.noAvailDesc}>
            {practitionerDetails.name} has no availability for new appointments in the next 4 weeks.
          </div>
        </div>
      )}
    </div>
  );
};

export default PsychologistAvailabilityCalendar;
