import { TFunction } from 'i18next';
import { MedicareInformation } from 'interfaces/Client';
import * as Yup from 'yup';

export const MEDICARE_CARD_DEFAULT_VALUE: MedicareInformation = {
  firstName: '',
  lastName: '',
  dateOfBirth: '',
  number: undefined,
  irn: undefined,
  expiryDate: ''
};

// interface InfoErrorInterface {
//   //Key contact
//   keyContactFirstName: string;
//   keyContactLastName: string;
//   relationship: string;
//   mobileNumber: string;
//   //GP
//   email: string;
//   gpPhoneNumber: string;

//   //Medicare
//   medicareFirstName: string;
//   medicareLastName: string;

//   //referral information
//   referralFirstName: string;
//   referralLastName: string;
//   referralEmail: string;
//   referralPhoneNumber: string;
// }

export interface EmergencyContactFieldErrors {
  firstName: string;
  lastName: string;
  mobileNumber: string;
  relationship: string;
  [key: string]: string; // Add index signature
}

export interface GPReferralErrors {
  email: string;
  [key: string]: string; // Add index signature
}

export interface ReferralDetailErrors {
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  [key: string]: string; // Add index signature
}

export interface MedicareInformationErrors {
  firstName: string;
  lastName: string;
  [key: string]: string; // Add index signature
}

export interface InfoFormInterface {
  emergencyContactFieldErrors: EmergencyContactFieldErrors;
  gpReferralErrors: GPReferralErrors;
  medicareInformationErrors: MedicareInformationErrors;
  referralDetailErrors: ReferralDetailErrors;
}

export const initialInfoFormError: InfoFormInterface = {
  emergencyContactFieldErrors: {
    firstName: '',
    lastName: '',
    mobileNumber: '',
    relationship: ''
  },
  gpReferralErrors: {
    email: '',
    phoneNumber: ''
  },
  medicareInformationErrors: {
    firstName: '',
    lastName: ''
  },
  referralDetailErrors: {
    firstName: '',
    lastName: '',
    email: '',
    phoneNumber: ''
  }
};

export const formDataValidationSchema = (t: TFunction, formKey: keyof InfoFormInterface) => {
  const mapYupValidator = {
    emergencyContactFieldErrors: {
      firstName: Yup.string().required('Please enter first name'),
      lastName: Yup.string().required('Please enter last name'),
      mobileNumber: Yup.string().required('Please enter mobile number'),
      relationship: Yup.string().required('Please select relationship')
    },
    gpReferralErrors: {
      email: Yup.string().required('Please enter email')
      //phoneNumber: Yup.string().required('Please enter phone number')
    },
    medicareInformationErrors: {
      firstName: Yup.string().required('Please enter first name'),
      lastName: Yup.string().required('Please enter last name')
    },
    referralDetailErrors: {
      firstName: Yup.string().required('Please enter first name'),
      lastName: Yup.string().required('Please enter last name'),
      email: Yup.string().required('Please enter email')
      //phoneNumber: Yup.string().required('Please enter phone number')
    }
  };

  return Yup.object().shape(
    {
      ...mapYupValidator[formKey]
    }
    //   {
    //   firstName: Yup.string().required('Please enter your first name'),
    //   lastName: Yup.string().required('Please enter your last name'),
    //   relationship: Yup.string().required('Please select relationship'),
    //   line1: Yup.string().required('Please enter your address'),
    //   suburb: Yup.string().required(`Please enter your ${t('form.client_address_suburb').toLowerCase()}`),
    //   state: Yup.string().required(`Please enter your ${t('form.client_address_state').toLowerCase()}`),
    //   postcode: Yup.string().required('Please enter your postcode'),
    //   country: Yup.string().required('Please enter your country')
    // }
  );
};

const mapFormToInitError: InfoFormInterface = {
  emergencyContactFieldErrors: {
    firstName: '',
    lastName: '',
    mobileNumber: '',
    relationship: ''
  },
  gpReferralErrors: {
    email: '',
    phoneNumber: ''
  },
  medicareInformationErrors: {
    firstName: '',
    lastName: ''
  },
  referralDetailErrors: {
    firstName: '',
    lastName: '',
    email: '',
    phoneNumber: ''
  }
};

export type InfoErrorType =
  | EmergencyContactFieldErrors
  | GPReferralErrors
  | MedicareInformationErrors
  | ReferralDetailErrors;

export const validationInfoForm = (form: InfoErrorType, t: TFunction, formKey: keyof InfoFormInterface) => {
  const validationErrors = { ...mapFormToInitError[formKey] };
  try {
    formDataValidationSchema(t, formKey).validateSync(form, { abortEarly: false });
    return validationErrors;
  } catch (ex) {
    if (ex instanceof Yup.ValidationError && ex.inner && ex.inner.length !== 0) {
      ex.inner.forEach((error: Yup.ValidationError) => {
        if (error?.path && Object.keys(validationErrors).includes(error.path)) {
          validationErrors[error.path] = error.message;
        }
      });
    }
  }

  console.log('validationErrors', validationErrors);

  return {
    validationErrors,
    cleanValidationError: Object.fromEntries(Object.entries(validationErrors).filter(([_k, value]) => value !== ''))
  };
  // for (const key in validateResult) {
  //   const validationErrors: Record<string, any> = mapFormToInitError[key];
  //   try {
  //     formDataValidationSchema(t).validateSync(validateResult[key as keyof typeof validateResult], {
  //       abortEarly: false
  //     });
  //   } catch (ex) {
  //     if (ex instanceof Yup.ValidationError && ex.inner && ex.inner.length !== 0) {
  //       ex.inner.forEach((error: any) => {
  //         if (Object.keys(validationErrors).includes(error.path)) {
  //           validationErrors[error.path as keyof typeof validationErrors] = error.message;
  //         }
  //       });

  //       validateResult[key as keyof typeof validateResult] = validationErrors;
  //     }
  //   }
  // }

  // return {
  //   validateResult
  //   //cleanValidationError: Object.fromEntries(Object.entries(validationErrors).filter(([_k, value]) => value !== ''))
  // };
};
