import styles from './ListingFilter.module.scss';

import {
  AgeRangeList,
  MENTAL_HEALTH_LIST_LABELS,
  MentalHealthList,
  NUMBER_AGE_RANGE_LIST_LABELS
} from 'interfaces/Clinician/enums';
import ContentLayout from '../../BaseComponent/ContentLayout/ContentLayout';
import FilterDropdownWrapper from './FilterDropdownWrapper/FilterDropdownWrapper';
import { listInterface } from '../FilterDropdown/FilterDropdown';

interface ListingFilterProps {
  selectedMentalHealthConcern: MentalHealthList[];
  onChangeMentalHealthConcern: (value: listInterface) => void;
  selectedAgeRange?: AgeRangeList;
  onChangeAgeRange: (value: listInterface | undefined) => void;
  handleResetValue: () => void;
  isLoading?: boolean;
}

const ListingFilter = ({
  selectedMentalHealthConcern,
  onChangeMentalHealthConcern,
  selectedAgeRange,
  onChangeAgeRange,
  handleResetValue,
  isLoading
}: ListingFilterProps) => {
  const massageMentalHealthConcern = selectedMentalHealthConcern.map((item) => {
    return {
      value: item,
      label: MENTAL_HEALTH_LIST_LABELS[item]
    };
  });

  let massageAgeRange = undefined;
  if (selectedAgeRange) {
    massageAgeRange = {
      value: selectedAgeRange,
      label: NUMBER_AGE_RANGE_LIST_LABELS[selectedAgeRange as keyof typeof AgeRangeList]
    };
  }

  return (
    <ContentLayout>
      <div className={styles.container}>
        <div className={styles.filterWrapper}>
          <div className={styles.header}>
            <div className={styles.label}>Find a psychologist</div>
          </div>
          <FilterDropdownWrapper
            classNames={styles.filter}
            mentalHealthConcernValue={massageMentalHealthConcern}
            onChangeMentalHealthConcernValue={onChangeMentalHealthConcern}
            onChangeAgeRangeValue={onChangeAgeRange}
            ageRangeValue={massageAgeRange}
            handleResetValue={handleResetValue}
            isLoading={isLoading}
          />
        </div>
      </div>
    </ContentLayout>
  );
};

export default ListingFilter;
