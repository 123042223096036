import classNames from 'classnames';
import { listInterface } from '../../FilterDropdown';
import styles from './FilterDropdownMultiSelectItem.module.scss';

interface FilterDropdownMultiSelectItemProps {
  selectedMultipleValue: listInterface[];
  item: listInterface;
  searchValue: string;
  handleSelect: (value: listInterface) => void;
  disabled?: boolean;
}
const FilterDropdownMultiSelectItem = ({
  selectedMultipleValue,
  item,
  searchValue,
  handleSelect,
  disabled
}: FilterDropdownMultiSelectItemProps) => {
  const matchedLabel = (value: string) => {
    const matchedIndex = value.toLowerCase().indexOf(searchValue.toLowerCase());
    return (
      <div className={styles.label}>
        {value.substring(0, matchedIndex)}
        <span className={styles.matchedValue}>{value.substring(matchedIndex, matchedIndex + searchValue.length)}</span>
        {value.substring(matchedIndex + searchValue.length)}
      </div>
    );
  };

  return (
    <div
      className={classNames(
        selectedMultipleValue.some((obj) => obj.value === item.value) ? styles.selectedListBox : styles.listBox,
        disabled && styles.disabled
      )}
      onClick={() => !disabled && handleSelect(item)}
    >
      {matchedLabel(item.label)}
    </div>
  );
};

export default FilterDropdownMultiSelectItem;
