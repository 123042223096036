import {
  FIT_PSYCHOLOGIST_GENDER_LIST_LABELS,
  FIT_DAYS_LIST_LABELS,
  FIT_DELIVERY_LIST_LABELS,
  FIT_STYLE_LIST_LABELS,
  FIT_TIMES_LIST_LABELS,
  FitPsychologistGenderList,
  FitDaysList,
  FitDeliveryList,
  FitLanguageList,
  FitStyleList,
  FitTimesList,
  FIT_LANGUAGE_LIST_LABELS
} from 'interfaces/Engage/fitFilter';

export const generateDaysList = () => {
  return Object.keys(FitDaysList).map((listObj) => ({
    value: listObj,
    label: FIT_DAYS_LIST_LABELS[listObj as keyof typeof FitDaysList]
  }));
};

export const generateGenderList = () => {
  return Object.keys(FitPsychologistGenderList).map((listObj) => ({
    value: listObj,
    label: FIT_PSYCHOLOGIST_GENDER_LIST_LABELS[listObj as keyof typeof FitPsychologistGenderList]
  }));
};

export const generateTimesList = () => {
  return Object.keys(FitTimesList)
    .filter((listObj) =>
      [FitTimesList.morning, FitTimesList.afternoon, FitTimesList.evening].includes(listObj as FitTimesList)
    )
    .map((listObj) => ({
      value: listObj,
      label: FIT_TIMES_LIST_LABELS[listObj as keyof typeof FitTimesList]
    }));
};

export const generateDeliveryList = () => {
  return Object.keys(FitDeliveryList).map((listObj) => ({
    value: listObj,
    label: FIT_DELIVERY_LIST_LABELS[listObj as keyof typeof FitDeliveryList]
  }));
};

export const generateLanguageList = () => {
  return Object.keys(FitLanguageList)
    .map((listObj) => ({
      value: listObj,
      label: FIT_LANGUAGE_LIST_LABELS[listObj as keyof typeof FitLanguageList]
    }))
    .sort((a, b) => a.label.localeCompare(b.label));
};

export const generateStylesList = () => {
  return Object.keys(FitStyleList).map((listObj) => ({
    value: listObj,
    label: FIT_STYLE_LIST_LABELS[listObj as keyof typeof FitStyleList]
  }));
};
