import { brandConfig } from 'brand/config';

export const useCustomLogo = () => {
  const { logo } = brandConfig;

  return {
    darkLogo: logo.darkLogo,
    lightLogo: logo.whiteLogo,
    isLogoLoading: false
  };
};
