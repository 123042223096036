import { BrandConfig } from '../config';
import { CountryISO } from 'interfaces/countryInterface';
import { CLIENT_BRAND_LABEL, CLIENT_BRAND_LOWER_CASE } from 'interfaces/ClientBrandInterface';
import { InfoFieldType, PaymentOption } from 'interfaces/signupConfigInterface';

export const cawConfig: BrandConfig = {
  accountId: '60efad738399fd000ad6eae6',
  brand: CLIENT_BRAND_LOWER_CASE,
  slugUrl: 'caw',
  favIcon: '',
  defaultTimezone: 'Australia/Melbourne',
  metaInfo: {
    title: CLIENT_BRAND_LABEL.CAW,
    desc: 'caw caw'
  },
  logo: {
    darkLogo: '',
    whiteLogo: ''
  },
  tracking: {
    gtmId: '',
    ga4Id: ''
  },
  region: CountryISO.AU,
  link: {
    contactUsText:
      'Our team are available and happy to answer your questions Monday - Friday 9am - 5pm (4.30pm on a Friday).',
    contactUsUrl: 'https://www.tacklit.com'
  },
  contactLink: {
    phoneNumber: '023 0443 2343',
    email: 'hello@tacklit.com',
    address: '1 Main Street, London EC1R 4RT'
  },
  pageConfig: {
    headerMenu: {
      allowEditStripeCard: true,
      useDarkLogo: true,
      allowBookNewAppointment: true
    },
    engageFooter: {
      useDarkLogo: false,
      footerDescription: 'Mindbalance provide multiple '
    }
  },
  engageConfig: {
    timer: 0,
    createAsRecordOnly: false,
    paymentMethod: {
      paymentOption: PaymentOption.SaveCardOnly
    },
    infoFields: [
      InfoFieldType.EmergencyContact,
      InfoFieldType.GPInformation,
      InfoFieldType.Medicare,
      InfoFieldType.ReferralInformation,
      InfoFieldType.CommunicationPreference
    ],
    welcomeMessage: 'Welcome to Tacklit'
  }
};
