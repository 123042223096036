import { CountryISO } from 'interfaces/countryInterface';
import { tacklitConfig } from './tacklit/config';
import { cawConfig } from './caw/config';
import { InfoFieldType, PaymentOption } from 'interfaces/signupConfigInterface';

export interface BrandConfig {
  brand: string;
  accountId: string;
  slugUrl: string;
  favIcon: string;
  defaultTimezone: string;
  metaInfo: {
    title: string;
    desc: string;
  };
  logo: {
    darkLogo: string;
    whiteLogo: string;
  };
  tracking: {
    gtmId: string;
    ga4Id: string;
  };
  region: CountryISO;
  link: {
    contactUsText: string;
    contactUsUrl: string;
  };
  contactLink: {
    phoneNumber: string;
    email: string;
    address: string;
  };
  pageConfig: {
    headerMenu: {
      allowEditStripeCard: boolean;
      useDarkLogo: boolean;
      allowBookNewAppointment: boolean;
    };
    engageFooter: {
      useDarkLogo: boolean;
      footerDescription: string;
    };
  };
  engageConfig: {
    timer: number;
    createAsRecordOnly: boolean;
    paymentMethod: {
      paymentOption: PaymentOption;
    };
    infoFields: InfoFieldType[]; // Array of InfoFieldType keys, so can control the reorder
    welcomeMessage: string;
  };
}

interface countryObject {
  [key: string]: BrandConfig;
}

export const BRAND = process.env.REACT_APP_BRAND || 'tacklit';

const configMapping: countryObject = {
  tacklit: tacklitConfig,
  caw: cawConfig
};

export const brandConfig = {
  ...configMapping[BRAND],
  userSessionKey: `${BRAND}UserSession`
};
