import classNames from 'classnames';
import styles from './ErrorMessage.module.scss';

interface ErrorMessageProps {
  error?: string | JSX.Element;
  visible?: boolean;
  padLeft?: boolean;
}

const ErrorMessage = ({ error, visible, padLeft }: ErrorMessageProps) => {
  return visible && <div className={classNames(styles.container, padLeft && styles.padLeft)}>{error}</div>;
};

export default ErrorMessage;
