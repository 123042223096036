import Select, { StylesConfig } from 'react-select';
import CountryCodeOption from './components/CountryCodeOption/CountryCodeOption';
import countryCodes from './countryCodes.json';
import CountryCodeSingleValue from './components/CountryCodeSingleValue/CountryCodeSingleValue';
import DropdownIndicator from '../../../Select/components/DropdownIndicator/DropdownIndicator';

export interface CountryCodeOptionInterface {
  label: string;
  value: string;
}

const countryCodeOptions: CountryCodeOptionInterface[] = countryCodes.map(({ name, iso2, countryCode }) => ({
  label: `${name} (+${countryCode})`,
  value: iso2
}));

const styles: StylesConfig<CountryCodeOptionInterface> = {
  container: (base) => ({
    ...base,
    width: 150
  }),
  control: (base, state) => ({
    ...base,
    minHeight: 'unset',
    border: 'none',
    borderRadius: 0,
    boxShadow: 'none'
  }),
  valueContainer: (base) => ({
    ...base,
    padding: 0
  }),
  singleValue: (base) => ({
    ...base,
    margin: 0,
    color: 'var(--color-primary-text)'
  }),
  input: (base) => ({
    ...base,
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    margin: 0,
    padding: 0,
    color: 'var(--color-primary-text)',
    fontSize: 10,
    fontWeight: 600,

    input: {
      textAlign: 'right'
    },
    '::after': {
      display: 'none'
    }
  }),
  placeholder: (base) => ({
    ...base,
    color: 'var(--color-grey)',
    fontSize: 10,
    fontWeight: 600,
    textAlign: 'right'
  }),
  indicatorsContainer: (base) => ({
    ...base,
    '> div': {
      padding: 0
    }
  }),
  indicatorSeparator: () => ({
    display: 'none'
  }),
  menu: (base) => ({
    ...base,
    marginTop: 2,
    marginBottom: 0,
    overflow: 'hidden'
  }),
  menuList: (base) => ({
    ...base,
    padding: 0
  }),
  option: (base, state) => ({
    ...base,
    backgroundColor: state.isSelected
      ? 'var(--color-primary-50)'
      : state.isFocused
        ? 'var(--color-primary-10)'
        : base.backgroundColor
  })
};

interface CountryCodeSelectProps {
  id?: string;
  value: string;
  onChange: (value: string) => void;
}

const CountryCodeSelect = ({ id, value, onChange }: CountryCodeSelectProps) => {
  return (
    <Select<CountryCodeOptionInterface, false>
      inputId={`${id ? `${id}-` : ''}country-code-select`}
      components={{
        DropdownIndicator: DropdownIndicator,
        Option: CountryCodeOption,
        SingleValue: CountryCodeSingleValue
      }}
      styles={styles}
      options={countryCodeOptions}
      isSearchable
      value={countryCodeOptions.find((option) => option.value === value) || { label: value, value }}
      onChange={(value) => onChange(value?.value || '')}
    />
  );
};

export default CountryCodeSelect;
