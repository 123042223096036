import styles from './ProfileDetails.module.scss';
import { useAuth0 } from '@auth0/auth0-react';
import Button, { ButtonVariant } from 'components/Button/Button';
import ClientProfileAvatar from 'components/ClientProfileAvatar/ClientProfileAvatar';
import { UserContext } from 'contexts/UserContextProvider';
import { useCallback, useContext, useState } from 'react';
import { useGetAccessToken } from 'utils/hooks/useGetAccessToken';
import Input from 'components/Input/Input';
import {
  COMMUNICATION_PREFERENCE_OPTIONS,
  ProfileDetailsForm,
  getProfileDetailsFormFromPatientProfile
} from './constants';
import DateInput from 'components/DateInput/DateInput';
import { DateFormat } from 'utils/dayjsExtended';
import MobilePhoneInput from 'components/MobilePhoneInput/MobilePhoneInput';
import ResetPasswordWithFakeInput from 'components/ResetPasswordWithFakeInput/ResetPasswordWithFakeInput';
import AddressForm from 'components/AddressForm/AddressForm';
import { IS_AU_REGION } from 'utils/featureToggle';
import Select from 'components/Select/Select';
import { AU_TIMEZONE_OPTIONS } from 'utils/timezone';
import { CommunicationPreference } from 'interfaces/Client/enums';
import ChangeAvatarModal from './components/ChangeAvatarModal/ChangeAvatarModal';

const ProfileDetails = () => {
  const { user } = useAuth0();
  const {
    userInfo: { initials },
    isQueryParamToken
  } = useGetAccessToken(true);
  const { clientProfile } = useContext(UserContext);

  const [profileDetailsForm, setProfileDetailsForm] = useState(getProfileDetailsFormFromPatientProfile(clientProfile));
  const [isChangeAvatarModalOpen, setIsChangeAvatarModalOpen] = useState(false);

  const handleChangeProfileDetailsForm = useCallback(
    (
      // so you can't assign '' to address, for example
      ...[key, value]:
        | [keyof Omit<ProfileDetailsForm, 'address'>, ProfileDetailsForm[keyof Omit<ProfileDetailsForm, 'address'>]]
        | ['address', ProfileDetailsForm['address']]
    ) =>
      setProfileDetailsForm((profileDetailsForm) => ({
        ...profileDetailsForm,
        [key]: value
      })),
    []
  );

  const handleChangeDate = useCallback(
    (value: string) => handleChangeProfileDetailsForm('dateOfBirth', value),
    [handleChangeProfileDetailsForm]
  );
  const handleChangeMobileNumber = useCallback(
    (value: string) => handleChangeProfileDetailsForm('mobileNumber', value),
    [handleChangeProfileDetailsForm]
  );

  const handleChangeAvatarClick = () => {
    setIsChangeAvatarModalOpen(true);
  };

  return (
    <>
      <ChangeAvatarModal
        open={isChangeAvatarModalOpen}
        onClose={() => setIsChangeAvatarModalOpen(false)}
        onSelectAvatar={(value) => handleChangeProfileDetailsForm('avatar', value)}
      />
      <div>
        <div className={styles.mobileHeader}>
          <span className={styles.title}>Profile</span>
          <div className={styles.avatarContainer}>
            <Button className={styles.editAvatarButton} variant={ButtonVariant.Text} onClick={handleChangeAvatarClick}>
              Change
            </Button>
            <div className={styles.avatar}>
              <ClientProfileAvatar
                avatarUrl={profileDetailsForm.avatar || clientProfile?.avatar || user?.picture}
                initialsName={initials}
                tokenUser={isQueryParamToken}
              />
            </div>
          </div>
        </div>
        <div className={styles.card}>
          <div className={styles.desktopHeader}>
            <span className={styles.title}>Profile</span>
            <Button>Save Profile</Button>
          </div>
          <div className={styles.desktopRow}>
            <div className={styles.desktopAvatar} onClick={handleChangeAvatarClick}>
              <ClientProfileAvatar
                avatarUrl={profileDetailsForm.avatar || clientProfile?.avatar || user?.picture}
                initialsName={initials}
                tokenUser={isQueryParamToken}
              />
            </div>
            <div className={styles.form}>
              <div className={styles.desktopRow1}>
                <Input
                  label={'First Name'}
                  id={'firstName'}
                  onChange={(e) => handleChangeProfileDetailsForm('firstName', e.target.value)}
                  value={profileDetailsForm.firstName}
                  required
                  autoComplete={'off'}
                />
                <Input
                  label={'Last Name'}
                  id={'lastName'}
                  onChange={(e) => handleChangeProfileDetailsForm('lastName', e.target.value)}
                  value={profileDetailsForm.lastName}
                  required
                  autoComplete={'off'}
                />
                <Input
                  label={'Preferred Name'}
                  id={'name'}
                  onChange={(e) => handleChangeProfileDetailsForm('name', e.target.value)}
                  value={profileDetailsForm.name}
                  required
                  autoComplete={'off'}
                />
                <Input
                  label={'Pronouns'}
                  id={'pronouns'}
                  onChange={(e) => handleChangeProfileDetailsForm('pronouns', e.target.value)}
                  value={profileDetailsForm.pronouns}
                  required
                  autoComplete={'off'}
                />
              </div>
              <div className={styles.desktopRow2}>
                <DateInput
                  id="dateOfBirth"
                  label="Date of Birth"
                  value={profileDetailsForm.dateOfBirth}
                  format={DateFormat.DAY_MONTH_YEAR}
                  onChange={handleChangeDate}
                />
                <MobilePhoneInput
                  id="mobileNumber"
                  inputClass={styles.mobileInput}
                  label="Mobile Phone Number"
                  value={profileDetailsForm.mobileNumber}
                  onChange={handleChangeMobileNumber}
                />
              </div>
              <div className={styles.desktopRow3}>
                <Input
                  label={'Email'}
                  id={'email'}
                  onChange={(e) => handleChangeProfileDetailsForm('email', e.target.value)}
                  value={profileDetailsForm.email}
                  required
                  autoComplete={'off'}
                />
                {/* TODO: reset password */}
                <ResetPasswordWithFakeInput />
              </div>
              <AddressForm
                value={profileDetailsForm.address}
                onChange={(value) => handleChangeProfileDetailsForm('address', value)}
              />
              <div className={styles.desktopRow4}>
                {IS_AU_REGION && (
                  <div>
                    <Select
                      id={'timeZone'}
                      label={'Your Timezone'}
                      isSearchable={false}
                      options={AU_TIMEZONE_OPTIONS}
                      value={profileDetailsForm.timeZone || ''}
                      onChange={(value) => handleChangeProfileDetailsForm('timeZone', value?.value)}
                    />
                    <div className={styles.timezoneTip}>
                      Please set your timezone for notifications and calendar invites
                    </div>
                  </div>
                )}
                <div>
                  <Select
                    id={'communicationPreference'}
                    label={'Communication Preference'}
                    isSearchable={false}
                    options={COMMUNICATION_PREFERENCE_OPTIONS}
                    value={profileDetailsForm.communicationPreference || ''}
                    onChange={(value) => handleChangeProfileDetailsForm('communicationPreference', value?.value)}
                  />
                  {profileDetailsForm.communicationPreference === CommunicationPreference.None && (
                    <div className={styles.communicationPreferenceTip}>
                      You won’t be able to receive any digital messages on this setting
                    </div>
                  )}
                </div>
              </div>
              <Button className={styles.mobileSaveButton}>Save Profile</Button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ProfileDetails;
