import { MouseEvent, useCallback, useEffect, useLayoutEffect, useRef, useState } from 'react';

import styles from './FilterDropdownMultiSelect.module.scss';
import classnames from 'classnames';
import { listInterface } from '../../FilterDropdown';
import FilterDropdownMultiSelectItem from '../FilterDropdownMultiSelectItem/FilterDropdownMultiSelectItem';

interface FilterDropdownMultiSelectProps {
  id: string;
  listData: listInterface[];
  selectedMultipleValue: listInterface[];
  onChangeValue: (val: listInterface) => void;
  onClick?: () => void;
  label?: string;
  className?: string;
  dropdownClassName?: string;
  withOtherSection?: boolean;
  withSearch?: boolean;
  // Able to search in both main list and other list after type something to search box
  ableToSearchAll?: boolean;
  disabled?: boolean;
  otherSectionLabel?: string;
  menuWrapperClass?: string;
  moreLabel?: string;
  hideTitle?: boolean;
  maxDisplayItem?: number;
}

const FilterDropdownMultiSelect = ({
  id,
  listData,
  selectedMultipleValue,
  onChangeValue,
  onClick,
  label,
  className,
  dropdownClassName,
  withOtherSection,
  withSearch,
  ableToSearchAll,
  disabled,
  otherSectionLabel,
  menuWrapperClass,
  moreLabel,
  hideTitle,
  maxDisplayItem = 3
}: FilterDropdownMultiSelectProps) => {
  const listMenuMultiSelectRef = useRef<HTMLDivElement>(null);
  const [showList, setShowList] = useState(false);
  const [showOtherMenu, setShowOtherMenu] = useState(false);
  const [dropdownList, setDropdownList] = useState(listData);
  const [searchValue, setSearchValue] = useState('');

  const handleCloseMenu = () => {
    setShowList(false);
    document.removeEventListener('click', handleCloseMenu);
  };

  const handleSelectButtonClick = () => {
    if (disabled) {
      return;
    }
    // Only accept onClick() when screen size is <=1020px
    if (window.innerWidth <= 1020 && onClick) {
      onClick();
      return;
    }
    setShowList(!showList);
  };

  const handleClick = (e: any) => {
    if (listMenuMultiSelectRef.current?.contains(e.target)) {
      return;
    }
    setShowList(false);
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClick);
    return () => {
      document.removeEventListener('mousedown', handleClick);
    };
  });

  const handleChangeSearch = useCallback(() => {
    let filterData: listInterface[] = [];
    if (ableToSearchAll) {
      filterData = listData.filter((obj) => obj.label.toLowerCase().includes(searchValue.toLowerCase()));
    } else {
      filterData = listData.filter((obj) => {
        if (showOtherMenu) {
          return obj.inOtherList && obj.label.toLowerCase().includes(searchValue.toLowerCase());
        }
        return !obj.inOtherList && obj.label.toLowerCase().includes(searchValue.toLowerCase());
      });
    }

    setDropdownList(filterData);
  }, [listData, searchValue, showOtherMenu, ableToSearchAll]);

  useLayoutEffect(() => {
    handleChangeSearch();
  }, [showOtherMenu, searchValue, handleChangeSearch]);

  const handleChangeFilter = (val: listInterface) => {
    onChangeValue(val);
    handleCloseMenu();
  };

  const handleRemoveFilter = (e: MouseEvent<HTMLDivElement, MouseEvent>, val: listInterface) => {
    e.preventDefault();
    e.stopPropagation();
    onChangeValue(val);
  };

  return (
    <div ref={listMenuMultiSelectRef} className={classnames(styles.container, className)}>
      {!hideTitle && (
        <div className={styles.filterTitleWrapper}>
          <div className={classnames(styles.filterTitle, selectedMultipleValue.length > 0 && styles.showTitle)}>
            {label}
          </div>
        </div>
      )}
      <div
        className={classnames(
          styles.selectButton,
          showList && styles.openMenu,
          dropdownClassName,
          disabled && styles.disabled
        )}
        onClick={handleSelectButtonClick}
      >
        <div className={styles.selectedPillWrapper}>
          {selectedMultipleValue.length > 0 ? (
            <>
              {selectedMultipleValue.slice(0, maxDisplayItem).map((obj, index) => (
                <div key={index} className={styles.selectedPill} title={obj.label}>
                  <button
                    disabled={disabled}
                    className={styles.closeBtn}
                    onClick={(e: any) => handleRemoveFilter(e, obj)}
                    title=""
                  >
                    <i className={`material-icons-outlined ${styles.closeIcon}`}>clear</i>
                  </button>
                  <div className={styles.label}>{obj.label}</div>
                </div>
              ))}
              {selectedMultipleValue.length > maxDisplayItem && (
                <div className={styles.plusLabel}>
                  ...+{selectedMultipleValue.length - maxDisplayItem} more {moreLabel || 'topics'}
                </div>
              )}
            </>
          ) : (
            label
          )}
        </div>
        <i className={`material-icons-outlined ${styles.arrow}`}>keyboard_arrow_down</i>
      </div>
      <div className={classnames(styles.menuWrapper, menuWrapperClass && menuWrapperClass)}>
        <div className={showList ? styles.menuBoxShow : styles.menuBoxHide}>
          {withSearch && (
            <div className={styles.searchWrapper}>
              <i className={`material-icons-outlined ${styles.searchIcon}`}>search</i>
              <input
                name={id}
                id={id}
                className={styles.searchInput}
                type={'text'}
                autoComplete={'off'}
                onChange={(e) => setSearchValue(e.target.value)}
              />
            </div>
          )}
          {withOtherSection ? (
            <>
              {searchValue.length > 0 ? (
                <div className={styles.displayMenu}>
                  {dropdownList.length > 0 ? (
                    dropdownList.map((listObj, index) => (
                      <FilterDropdownMultiSelectItem
                        key={index}
                        item={listObj}
                        searchValue={searchValue}
                        handleSelect={handleChangeFilter}
                        selectedMultipleValue={selectedMultipleValue}
                        disabled={disabled}
                      />
                    ))
                  ) : (
                    <div className={styles.emptyList}>No matching items found</div>
                  )}
                </div>
              ) : (
                <>
                  <div className={showOtherMenu ? styles.hideMenu : styles.displayMenu}>
                    {dropdownList
                      .filter((obj) => !obj.inOtherList)
                      .map((listObj, index) => (
                        <FilterDropdownMultiSelectItem
                          key={index}
                          item={listObj}
                          searchValue={searchValue}
                          handleSelect={handleChangeFilter}
                          selectedMultipleValue={selectedMultipleValue}
                          disabled={disabled}
                        />
                      ))}
                    <div className={styles.listBox} onClick={() => setShowOtherMenu(true)}>
                      {otherSectionLabel || 'Something else'}
                      <i className={`material-icons-outlined ${styles.rightArrow}`}>arrow_forward_ios</i>
                    </div>
                  </div>
                  <div className={showOtherMenu ? styles.displayMenu : styles.hideMenu}>
                    <div className={styles.backButton} onClick={() => setShowOtherMenu(false)}>
                      <i className={`material-icons-outlined ${styles.leftArrow}`}>arrow_back_ios</i>
                      Back
                    </div>
                    <div className={styles.otherList}>
                      {dropdownList
                        .filter((obj) => obj.inOtherList)
                        .map((listObj, index) => (
                          <FilterDropdownMultiSelectItem
                            key={index}
                            item={listObj}
                            searchValue={searchValue}
                            handleSelect={handleChangeFilter}
                            selectedMultipleValue={selectedMultipleValue}
                            disabled={disabled}
                          />
                        ))}
                    </div>
                  </div>
                </>
              )}
            </>
          ) : (
            <>
              {dropdownList.map((listObj, index) => (
                <FilterDropdownMultiSelectItem
                  key={index}
                  item={listObj}
                  searchValue={searchValue}
                  handleSelect={handleChangeFilter}
                  selectedMultipleValue={selectedMultipleValue}
                  disabled={disabled}
                />
              ))}
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default FilterDropdownMultiSelect;
