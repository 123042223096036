import { Address, MedicareInformation, PatientProfile, ReferralDetail } from 'interfaces/Client';
import { clinicianProfileServicesApiSlice, CPSTagTypes } from '../../services/clinicianProfileServicesApiSlice';
import { GetAttachedClinicianDetails } from 'interfaces/Clinician';
import dayjs, { DateFormat } from 'utils/dayjsExtended';
import { CommunicationPreference } from 'interfaces/Client/enums';

interface SurveyItem {
  _id: string;
  createdAt: string;
  title: string;
  totalQuestions: number;
}

interface FailedPaymentItem {
  _id: string;
  amount: number;
  systemRetryCount: number;
  accountId: string;
  clientRecordId: string;
  status: string;
  type: string;
  method: string;
  appointmentId: string;
  description: string;
  createdAt: string;
  updatedAt: string;
}

export interface TaskListInterface {
  consentList: { _id: string; name: string }[];
  onboardingQuestionCount: number;
  assessmentList: SurveyItem[];
  failedPayments: FailedPaymentItem[];
}

const clientRecordsApiSlice = clinicianProfileServicesApiSlice.injectEndpoints({
  endpoints: (builder) => ({
    // Get client record details
    getClientDetails: builder.query<PatientProfile, null>({
      query: () => ({
        url: '/client-records/me'
      }),
      transformResponse: (response: PatientProfile) => {
        return {
          ...response,
          ...(response.medicare && {
            medicare: {
              ...response.medicare,
              dateOfBirth: dayjs(response.medicare?.dateOfBirth, DateFormat.YEAR_MONTH_DAY).format(
                DateFormat.DAY_MONTH_YEAR
              )
            }
          })
        };
      },
      providesTags: [CPSTagTypes.ClientRecordsMe]
    }),

    // Get attached clinician details
    getAttachedClinicianDetails: builder.query<GetAttachedClinicianDetails, null>({
      query: () => ({
        url: '/client-records/me:getAttachedAccountAndClinician'
      }),
      providesTags: [CPSTagTypes.AttachedClinicianDetails]
    }),

    // Get tasks
    getTasks: builder.query<TaskListInterface, { clientRecordId: string }>({
      query: ({ clientRecordId }) => ({
        url: `/client-records/${clientRecordId}/tasks`
      }),
      transformResponse: (response: any) => {
        return {
          ...response,
          onboardingQuestionCount: response.onboarding,
          isTaskCompleted:
            !response.consent && response.assessmentList.length === 0 && response.failedPayments.length === 0
        } as TaskListInterface;
      },
      keepUnusedDataFor: 5,
      providesTags: [CPSTagTypes.ClientRecordTasks]
    }),

    //Check email exist
    checkEmailAlreadyExisted: builder.mutation<
      { used: boolean; isFullProfileType?: boolean },
      { accountId: string; email: string }
    >({
      query: ({ accountId, email }) => ({
        url: `/accounts/${accountId}/client-records:checkEmailAlreadyExisted`,
        method: 'PATCH',
        body: { email }
      })
    }),

    //Key contact
    postKeyContact: builder.mutation<
      null,
      {
        firstName: string;
        lastName: string;
        mobileNumber?: string;
        relationship: string;
        tags?: string[];
      }
    >({
      query: (payload) => ({
        url: '/client-records/me/key-client-contacts',
        method: 'POST',
        body: payload
      })
    }),

    //Medicare
    putClientMedicare: builder.mutation<MedicareInformation, MedicareInformation>({
      query: (body) => ({
        url: `/client-records/me/medicare`,
        method: 'PUT',
        body
      })
    }),

    //GP
    putClientReferralDetail: builder.mutation<null, ReferralDetail>({
      query: (body) => ({
        url: `/client-records/me/referral`,
        method: 'PUT',
        body
      })
    }),

    //Address
    putClientAddress: builder.mutation<null, Address>({
      query: (body) => ({
        url: `/client-records/me/address`,
        method: 'PUT',
        body
      })
    }),

    //Communication Preference
    patchClientCommunicationPreference: builder.mutation<null, { communicationPreference: CommunicationPreference }>({
      query: (body) => ({
        url: `/client-records/me/communication-preference`,
        method: 'PATCH',
        body
      })
    })
  })
});

export const {
  useGetClientDetailsQuery,
  useGetAttachedClinicianDetailsQuery,
  useGetTasksQuery,
  useCheckEmailAlreadyExistedMutation,
  usePostKeyContactMutation,
  usePutClientMedicareMutation,
  usePutClientReferralDetailMutation,
  usePutClientAddressMutation,
  usePatchClientCommunicationPreferenceMutation
} = clientRecordsApiSlice;
