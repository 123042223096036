import { ButtonHTMLAttributes } from 'react';
import styles from './Button.module.scss';
import { useNavigate } from 'react-router-dom';
import { get } from 'lodash';
import classNames from 'classnames';
import LoadingSpinner from './components/LoadingSpinner/LoadingSpinner';
import Finished from './components/Finished/Finished';

export enum ButtonStatus {
  Default = '',
  Active = 'active',
  Finished = 'finished'
}

export enum ButtonVariant {
  Contained = 'contained',
  Outlined = 'outlined',
  Text = 'text',
  ContainedFab = 'contained-fab'
}

export enum ButtonSize {
  Small = 'small',
  Medium = 'medium',
  Large = 'large'
}

export enum IconVariant {
  Filled = 'material-icons',
  Outlined = 'material-icons-outlined'
}

interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  status?: ButtonStatus;
  contentClassName?: string;
  activeClassName?: string;
  finishedClassName?: string;
  iconClassName?: string;
  size?: ButtonSize;
  variant?: ButtonVariant;
  fab?: boolean;
  icon?: string;
  iconVariant?: IconVariant;
  iconPostFix?: boolean;
  id?: string;
  noTransition?: boolean;
  fullWidth?: boolean;
  to?: string;
  loadingWord?: string;
  completedWord?: string;
  error?: boolean;
}

const Button = ({
  status = ButtonStatus.Default,
  contentClassName,
  activeClassName,
  finishedClassName,
  iconClassName,
  size = ButtonSize.Large,
  variant = ButtonVariant.Contained,
  fab,
  icon,
  iconVariant,
  iconPostFix,
  id,
  noTransition,
  children,
  fullWidth,
  to,
  loadingWord,
  completedWord,
  error,
  disabled,
  className,
  ...props
}: ButtonProps) => {
  const navigate = useNavigate();
  const statusClass = get(
    {
      active: classNames(styles.active, activeClassName),
      finished: classNames(styles.finished, finishedClassName)
    },
    status,
    ''
  );

  const handleNavigate = (value: string) => {
    navigate(value);
  };

  const round = !children && fab;

  return (
    <div className={classNames(error ? styles.errorTheme : styles.userTheme)}>
      <div className={classNames(fab && styles.fab, variant && styles[variant], round && styles.round)}>
        <button
          {...props}
          onClick={status === '' ? (to ? () => handleNavigate(to) : props.onClick) : undefined}
          className={classNames(
            styles.container,
            className,
            statusClass,
            styles[size],
            fullWidth && styles.fullWidth,
            noTransition && styles.noTransition
          )}
          disabled={status !== '' || disabled}
        >
          <span className={classNames(styles.submit, contentClassName)}>
            {!iconPostFix && icon && (
              <i
                className={classNames(
                  iconVariant ? iconVariant : IconVariant.Outlined,
                  styles.icon,
                  children && styles.iconLeft,
                  iconClassName
                )}
              >
                {icon}
              </i>
            )}
            {children}
            {iconPostFix && icon && (
              <i
                className={classNames(
                  iconVariant || IconVariant.Outlined,
                  styles.icon,
                  children && styles.iconRight,
                  iconClassName
                )}
              >
                {icon}
              </i>
            )}
          </span>
          <span className={styles.loading}>
            <LoadingSpinner className={styles.loadingSpinner} />
            <div>{loadingWord ? loadingWord : 'Loading'}</div>
          </span>
          <span className={styles.check}>
            <Finished />
            <div>{completedWord ? completedWord : 'Loading'}</div>
          </span>
        </button>
      </div>
    </div>
  );
};

export default Button;
