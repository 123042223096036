export enum ClientRecordType {
  Adult = 'adult',
  Child = 'child',
  Couple = 'couple',
  YoungPerson = 'youngPerson'
}

export enum CommunicationPreference {
  NoPreference = 'noPreference',
  Email = 'email',
  SMS = 'sms',
  None = 'none'
}

export enum DvaCardType {
  Gold = 'gold',
  White = 'white',
  Orange = 'orange'
}

export enum ProfileType {
  RecordOnly = 'recordOnly',
  Full = 'full'
}

export enum RecordStatus {
  Active = 'active',
  Closed = 'closed',
  Waitlist = 'waitlist',
  Merged = 'merged'
}
