import { InputHTMLAttributes, RefObject, useState } from 'react';
import classnames from 'classnames';

import styles from './Input.module.scss';
import ErrorMessage from 'components/ErrorMessage/ErrorMessage';
import Button, { ButtonVariant } from 'components/Button/Button';

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  containerClass?: string;
  containerRef?: RefObject<HTMLDivElement>;
  labelClass?: string;
  label: string;
  errorMessage?: string | JSX.Element;
  isError?: boolean;
  showPasswordButtonClass?: string;
  allowShowPassword?: boolean;
}

const Input = ({
  id,
  label,
  className,
  containerClass,
  containerRef,
  value,
  onChange,
  type = 'text',
  labelClass,
  errorMessage,
  isError,
  allowShowPassword,
  showPasswordButtonClass,
  ...props
}: InputProps) => {
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);

  return (
    <div className={classnames(styles.container, containerClass)} ref={containerRef}>
      <div className={styles.inputContainer}>
        <input
          {...props}
          id={id}
          name={id}
          className={classnames(styles.field, errorMessage && styles.error, className)}
          autoComplete={'off'}
          value={value}
          onChange={onChange}
          {...(allowShowPassword && type === 'password' && isPasswordVisible ? { type: '' } : { type })}
        />
        <label className={classnames(styles.label, labelClass)} htmlFor={id}>
          {label}
        </label>
        {allowShowPassword && type === 'password' && (
          <Button
            className={classnames(styles.showPasswordButton, showPasswordButtonClass)}
            variant={ButtonVariant.Text}
            icon={isPasswordVisible ? 'visibility' : 'visibility_off'}
            type="button"
            onClick={() => setIsPasswordVisible(!isPasswordVisible)}
          />
        )}
      </div>
      {!!errorMessage && <ErrorMessage error={errorMessage} visible={!!errorMessage || !!isError} />}
    </div>
  );
};

export default Input;
