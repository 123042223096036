import styles from './ClientProfileAvatar.module.scss';
import classnames from 'classnames';

const CLIENT_AVATAR_COLOR = '#8AA8D3';
interface ClientProfileAvatarProps {
  avatarUrl?: string;
  initialsName?: string;
  initialClassName?: string;
  avatarClassName?: string;
  tokenUser?: boolean;
  fullAvatarBg?: boolean;
}

const ClientProfileAvatar = ({
  avatarUrl,
  initialsName,
  initialClassName,
  avatarClassName,
  tokenUser,
  fullAvatarBg
}: ClientProfileAvatarProps) => {
  return tokenUser && initialsName ? (
    <div
      style={{ backgroundColor: CLIENT_AVATAR_COLOR }}
      className={classnames(styles.initialProfile, initialClassName)}
    >
      {initialsName}
    </div>
  ) : avatarUrl ? (
    <img
      src={avatarUrl}
      className={classnames(styles.imgProfile, fullAvatarBg && styles.avatarBg, avatarClassName)}
      alt={'profile'}
    />
  ) : (
    <div className={styles.initialProfile}>
      <i className={`material-icons ${styles.icon}`}>account_circle</i>
    </div>
  );
};

export default ClientProfileAvatar;
