import styles from './EngageFooter.module.scss';
import { PORTAL_ROUTES_LIST } from 'routes/constants';
import CardWrapper from 'components/BaseComponent/CardWrapper/CardWrapper';
import ContentLayout from 'components/BaseComponent/ContentLayout/ContentLayout';
import { useCustomLogo } from 'utils/hooks/useCustomLogo';
import { Skeleton } from 'antd';
import { Link } from 'react-router-dom';
import { brandConfig } from 'brand/config';
import classNames from 'classnames';

const { HOME } = PORTAL_ROUTES_LIST;

const EngageFooter = () => {
  const { darkLogo: darkCustomLogo, lightLogo: lightCustomLogo, isLogoLoading } = useCustomLogo();
  const {
    brand,
    pageConfig: { engageFooter },
    contactLink
  } = brandConfig;

  return (
    <div className={styles.container}>
      <ContentLayout>
        <CardWrapper>
          <div className={styles.footerContent}>
            <div className={styles.leftContent}>
              {isLogoLoading ? (
                <div className={styles.logoLoading}>
                  <Skeleton.Input active className={styles.logoLoadingImg} />
                </div>
              ) : (
                <Link to={HOME}>
                  <img
                    className={styles.logoImage}
                    src={engageFooter.useDarkLogo ? darkCustomLogo : lightCustomLogo}
                    alt={`${brand} logo`}
                  />
                </Link>
              )}
              <div className={styles.description}>{engageFooter.footerDescription}</div>
            </div>
            <div className={styles.rightContent}>
              <div className={styles.contactUsLabel}>Contact Us</div>
              <div className={styles.infoBox}>
                <div>
                  <i className={`material-symbols-outlined ${styles.infoIcon}`}>call</i>
                </div>
                <div>{contactLink.phoneNumber}</div>
              </div>
              <div className={styles.infoBox}>
                <div>
                  <i className={`material-symbols-outlined ${styles.infoIcon}`}>email</i>
                </div>
                <div>{contactLink.email}</div>
              </div>
              <div className={styles.infoBox}>
                <div>
                  <i className={`material-symbols-outlined ${styles.infoIcon}`}>home_work</i>
                </div>
                <div>{contactLink.address}</div>
              </div>
              <div className={classNames(styles.infoBox, styles.crisisBox)}>
                <div>
                  <i className={`material-symbols-outlined ${styles.infoIcon}`}>emergency</i>
                </div>
                <div>Need urgent help? Crisis support</div>
              </div>
            </div>
          </div>
        </CardWrapper>
      </ContentLayout>
    </div>
  );
};

export default EngageFooter;
