/* eslint-disable react/no-multi-comp */
import { ReactElement, useMemo, useState } from 'react';
import classnames from 'classnames';
import { flatMapDeep } from 'lodash';
import Select, { Props, StylesConfig } from 'react-select';

import styles from './Select.module.scss';
import { Skeleton } from 'antd';
import DropdownIndicator from './components/DropdownIndicator/DropdownIndicator';

interface Option {
  label: string;
  value: string;
}

export interface SelectProps<OptionType extends Option = Option, isMulti extends boolean = false>
  extends Omit<Props<OptionType, isMulti>, 'value'> {
  labelClass?: string;
  label?: string;
  hasError?: boolean;
  noSpacing?: boolean;
  styles?: Props<OptionType, isMulti>['styles'];
  value?: string;
  smallCaretDown?: boolean;
  loading?: boolean;
  onChange?: Props<OptionType, isMulti>['onChange'];
  onInputChange?: Props<OptionType, isMulti>['onInputChange'];
}

const innerStyles: StylesConfig = {
  control: (base, { isFocused, menuIsOpen }) => ({
    ...base,
    backgroundColor: 'transparent',
    border: 'none',
    borderBottom: `1px solid ${isFocused || menuIsOpen ? 'var(--color-primary)' : '#b5b8bd'}`,
    borderRadius: 0,
    boxShadow: 'none',
    minHeight: 'unset'
  }),
  indicatorsContainer: (base) => ({
    ...base,
    '> div': {
      padding: 0
    }
  }),
  indicatorSeparator: () => ({
    display: 'none'
  }),
  valueContainer: (base) => ({
    ...base,
    padding: 0
  }),
  placeholder: (base) => ({
    ...base,
    color: 'var(--color-grey)',
    fontSize: 14,
    fontWeight: 600
  }),
  singleValue: (base) => ({ ...base, fontSize: 14, fontWeight: 600, color: 'var(--color-primary-text)' }),
  // valueContainer: (base) => ({ ...base, paddingLeft: 15 }),
  multiValue: (base) => ({
    ...base,
    backgroundColor: 'var(--color-primary)',
    borderRadius: 25,
    color: 'var(--color-primary-inverted)',
    padding: 4
  }),
  multiValueLabel: (base) => ({ ...base, color: 'white' }),
  menu: (base) => ({ ...base, marginTop: 0, width: '100% !important', zIndex: 3 }),
  option: (base, state) => ({
    ...base,
    backgroundColor: state.isSelected
      ? 'var(--color-primary-50)'
      : state.isFocused
        ? 'var(--color-primary-10)'
        : base.backgroundColor
  })
};

type CountryCodeSelectType = <OptionType extends Option = Option, IsMulti extends boolean = false>(
  props: SelectProps<OptionType, IsMulti>
) => ReactElement;

const StyledSelect: CountryCodeSelectType = ({
  className,
  labelClass,
  label,
  hasError,
  noSpacing,
  options,
  styles: propStyles,
  value,
  smallCaretDown,
  loading,
  ...props
}) => {
  const selectClasses = classnames(styles.select, hasError && styles.error, noSpacing && styles.noSpacing);
  const [isFocusing, setIsFocusing] = useState(false);

  const flattenedOptions = useMemo(() => {
    if (options) {
      return flatMapDeep([...options], (option: any) => option.options ?? [option]);
    } else {
      return [];
    }
  }, [options]);

  return (
    <div className={classnames(styles.container, className)}>
      {label && (
        <div className={classnames(styles.label, labelClass, isFocusing && styles.focusLabelClass)}>{label}</div>
      )}
      {loading ? (
        <div className={styles.loadingWrapper}>
          <Skeleton.Input style={{ width: '300px', height: '30px' }} active />
        </div>
      ) : (
        <Select
          className={selectClasses}
          styles={{ ...(innerStyles as any), ...propStyles }}
          value={flattenedOptions.find((option) => option.value === value) || value}
          options={options}
          components={{ DropdownIndicator }}
          {...props}
          onFocus={() => setIsFocusing(true)}
          onBlur={() => {
            setIsFocusing(false);
          }}
        />
      )}
    </div>
  );
};

export default StyledSelect;
