import styles from './Timer.module.scss';
import { useState } from 'react';
import classNames from 'classnames';
import CircularTimer from 'components/CircularTimer/CircularTimer';
import TimeExpireAlert from './components/TimeExpireAlert/TimeExpireAlert';

interface CaWTimerProps {
  isReservedAppointmentFetching: boolean;
  //reservedAppointments: AppointmentSlot[];
  hideTimer?: boolean;
  onlyText?: boolean;
}

const Timer = ({ isReservedAppointmentFetching, hideTimer, onlyText }: CaWTimerProps) => {
  //const navigate = useNavigate();
  //const { PRACTITIONER } = useCaWRoutesGenerator();
  //const { clinicianId, reserveId } = useReserveAppointmentData();
  //const { removeReserveAppointmentData } = useSetReserveAppointmentData();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [showExpiredAlert, setShowExpiredAlert] = useState(false);
  //const { CaWHomeURL } = cawEnvironment();

  // if (!reserveId) {
  //   if (clinicianId) {
  //     const detailsPagePath = generatePath(PRACTITIONER.DETAILS, {
  //       clinicianId
  //     });
  //     navigate(detailsPagePath);
  //   } else {
  //     if (isDevelopmentENV()) {
  //       navigate(PRACTITIONER.LISTING);
  //     } else {
  //       window.location.href = `${CaWHomeURL}counsellor`;
  //     }
  //   }
  // }

  // const handleExpire = useCallback(() => {
  //   setShowExpiredAlert(true);
  //   removeReserveAppointmentData();
  // }, [removeReserveAppointmentData]);

  // useEffect(() => {
  //   if (!isReservedAppointmentFetching && reservedAppointments.length < 1) {
  //     handleExpire();
  //   } else {
  //     setShowExpiredAlert(false);
  //   }
  // }, [handleExpire, isReservedAppointmentFetching, reservedAppointments]);

  return (
    <div className={classNames(hideTimer && styles.hidden)}>
      <CircularTimer
        // startTime={reservedAppointments?.[0]?.updatedAt}
        startTime={new Date().toISOString()}
        onlyText={onlyText}
        timeLabelClass={classNames(onlyText && styles.timeLabel)}
        minutes={15}
        //onExpire={handleExpire}
        className={styles.circularTimer}
        strokeColor={'#363D78'}
      />
      <TimeExpireAlert showExpiredAlert={showExpiredAlert} />
    </div>
  );
};

export default Timer;
