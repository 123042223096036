export enum CountryISO {
  AU = 'au',
  GB = 'gb',
  NZ = 'nz',
  SA = 'sa'
}

export const COUNTRY_ISO_LABELS: Record<CountryISO, string> = {
  [CountryISO.AU]: 'Australia',
  [CountryISO.GB]: 'United Kingdom',
  [CountryISO.NZ]: 'New Zealand',
  [CountryISO.SA]: 'Saudi Arabia'
};
