import classNames from 'classnames';
import styles from './MobilePhoneInput.module.scss';
import { ChangeEvent, FocusEvent, useCallback, useEffect, useState } from 'react';
import ErrorMessage from 'components/ErrorMessage/ErrorMessage';
import CountryCodeSelect from './components/CountryCodeSelect/CountryCodeSelect';
import { getMobileNumberCountry, getRawMobileNumber } from 'utils/mobileNumber';
import { IS_AU_REGION } from 'utils/featureToggle';
import parsePhoneNumber, { CountryCode, isValidPhoneNumber, validatePhoneNumberLength } from 'libphonenumber-js';

interface MobilePhoneInputProps {
  id?: string;
  inputClass?: string;
  label: string;
  value: string;
  error?: string;
  onChange: (value: string) => void;
  onError?: (hasError: boolean) => void;
}

const MobilePhoneInput = ({
  id,
  inputClass,
  label,
  value,
  error: propsError,
  onChange,
  onError
}: MobilePhoneInputProps) => {
  const [selectedCountryCode, setSelectedCountryCode] = useState(getMobileNumberCountry(value));
  const [rawMobileNumber, setRawMobileNumber] = useState(getRawMobileNumber(value));

  const [error, setError] = useState('');
  const [touched, setTouched] = useState(false);

  const validatePhoneNumber = useCallback(
    (phoneNumber: string, countryCode?: string) => {
      const updateError = (error: string = '') => {
        setError(error);
        onError?.(!!error);
      };

      const parsedPhoneNumber = parsePhoneNumber(phoneNumber, (countryCode as CountryCode) || undefined);

      // parsedPhoneNumber.formatInternational includes country code and removes unnecessary 0 at start if exists
      const formattedNumber = parsedPhoneNumber?.formatInternational().replaceAll(/[^\d+]/g, '');

      if (formattedNumber && formattedNumber !== value) {
        onChange(formattedNumber);
      }

      if (parsedPhoneNumber?.country !== countryCode) {
        updateError('Invalid Number');
        return;
      }

      const phoneNumberLengthValidation = validatePhoneNumberLength(
        phoneNumber,
        (countryCode as CountryCode) || undefined
      );

      if (phoneNumberLengthValidation) {
        switch (phoneNumberLengthValidation) {
          case 'NOT_A_NUMBER':
            updateError('Please enter numbers only');
            return;
          case 'TOO_LONG':
            updateError('Number is too long');
            return;
          case 'TOO_SHORT':
            updateError('Number is too short');
            return;
          case 'INVALID_COUNTRY':
            console.error(`Error while parsing country code ${countryCode}`);
            updateError('Error while parsing this mobile number with country code, please contact an administrator');
            return;
        }
      }

      // isValidPhoneNumber checks length as well as format, so we check length first
      const isValid = isValidPhoneNumber(phoneNumber, (countryCode as CountryCode) || undefined);

      if (!isValid) {
        updateError('Invalid mobile number');
        return;
      }

      updateError();
    },
    [onChange, onError, value]
  );

  useEffect(() => {
    if (rawMobileNumber.length > 6) {
      validatePhoneNumber(rawMobileNumber, selectedCountryCode);
    } else {
      setError('');
      onError?.(false);
    }
  }, [onError, rawMobileNumber, selectedCountryCode, validatePhoneNumber]);

  const handleMobileNumberBlur = (e: FocusEvent<HTMLInputElement>) => {
    if (e.target.value) {
      validatePhoneNumber(e.target.value.replaceAll(/\D/g, ''), selectedCountryCode);
    }
  };

  const handleMobileNumberChange = (e: ChangeEvent<HTMLInputElement>) => {
    const formattedNumber = e.target.value.replaceAll(/\D/g, '');

    setRawMobileNumber(formattedNumber);
  };

  const hasError = !!(error || propsError) && touched;

  return (
    <div className={classNames(styles.container, hasError && styles.error)} id={id}>
      <div className={styles.labelContainer}>
        <label className={styles.label} htmlFor={`${id}-input`}>
          {label}
        </label>
        <CountryCodeSelect id={id} value={selectedCountryCode} onChange={setSelectedCountryCode} />
      </div>
      <input
        className={classNames(styles.input, inputClass)}
        id={`${id}-input`}
        type="tel"
        placeholder={IS_AU_REGION ? '04xx xxx xxx' : '07xxx xxx xxx'}
        value={rawMobileNumber}
        onBlur={handleMobileNumberBlur}
        onFocus={() => setTouched(true)}
        onChange={handleMobileNumberChange}
      />
      <ErrorMessage error={error || propsError} visible={hasError} padLeft />
    </div>
  );
};

export default MobilePhoneInput;
