import { useAuth0 } from '@auth0/auth0-react';
import { useEffect, useRef, useState } from 'react';
import { useLogout } from 'utils/hooks/logout';
import styles from './HeaderMenu.module.scss';
import { Link, useNavigate } from 'react-router-dom';
import classNames from 'classnames';
import { useGetAccessToken } from 'utils/hooks/useGetAccessToken';
import ClientProfileAvatar from 'components/ClientProfileAvatar/ClientProfileAvatar';
import { useGetClientProfileDetails } from 'utils/hooks/clinicianProfileService/getClientDetails';
import Button, { IconVariant } from 'components/Button/Button';
import { Skeleton } from 'antd';
import { useCustomLogo } from 'utils/hooks/useCustomLogo';
import TacklitFooter from 'components/TacklitFooter/TacklitFooter';
import TaskItem from 'components/TaskItem/TaskItem';
import { brandConfig } from 'brand/config';

export interface HeaderMenuItem {
  name: string;
  url?: string;
  isExternal?: boolean;
  onClick?: () => void;
}

interface HeaderMenuProps {
  bookNewAppointmentUrl?: string;
  menuItems: HeaderMenuItem[];
}

const HeaderMenu = ({ bookNewAppointmentUrl, menuItems }: HeaderMenuProps) => {
  const { user, isAuthenticated } = useAuth0();
  const { logout } = useLogout();
  const navigate = useNavigate();

  const { darkLogo: darkCustomLogo, lightLogo: lightCustomLogo, isLogoLoading } = useCustomLogo();
  const {
    pageConfig: { headerMenu: headerMenuConfig }
  } = brandConfig;
  const {
    userInfo: { initials },
    isQueryParamToken
  } = useGetAccessToken(true, true);
  const { clientProfileData, isClientProfileLoading } = useGetClientProfileDetails();

  const [showMenu, setShowMenu] = useState(false);

  const containerRef = useRef<HTMLDivElement>(null);
  const menuRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClick = (e: any) => {
      if (menuRef.current?.contains(e.target) || containerRef.current?.contains(e.target)) {
        return;
      }
      setShowMenu(false);
    };

    document.addEventListener('mousedown', handleClick);
    return () => {
      document.removeEventListener('mousedown', handleClick);
    };
  });

  const handleTaskItemClick = (isExternal: HeaderMenuItem['isExternal']) => {
    !isExternal && setShowMenu(false);
  };

  return (
    <div className={styles.container} ref={containerRef}>
      <div
        className={classNames(styles.menuTrigger, showMenu && styles.activeMenu)}
        onClick={() => setShowMenu(!showMenu)}
      >
        <i className={`material-icons ${styles.menuIcon}`}>{showMenu ? 'close' : 'menu'}</i>
      </div>

      <div className={classNames(styles.menu, showMenu && styles.showMenu)} ref={menuRef}>
        <div className={styles.menuWrapper}>
          <div className={styles.menuBox}>
            <div className={styles.header}>
              <div>
                {isLogoLoading ? (
                  <div className={styles.logoLoading}>
                    <Skeleton.Input active className={styles.logoLoadingImg} />
                  </div>
                ) : (
                  <Link to={'/'}>
                    <img
                      className={styles.logoImage}
                      src={headerMenuConfig.useDarkLogo ? darkCustomLogo : lightCustomLogo}
                      alt={'logo'}
                      onClick={() => setShowMenu(!showMenu)}
                    />
                  </Link>
                )}
              </div>
              <div className={styles.menuTrigger} onClick={() => setShowMenu(false)}>
                <i className={`material-icons ${styles.menuIcon}`}>{showMenu ? 'close' : 'menu'}</i>
              </div>
            </div>
            {(initials || isAuthenticated) && (
              <div className={styles.profileWrapper}>
                <div className={styles.profile}>
                  <ClientProfileAvatar
                    avatarClassName={styles.avatar}
                    avatarUrl={clientProfileData?.avatar || user?.picture}
                    initialsName={initials}
                    tokenUser={isQueryParamToken}
                  />
                </div>
                {!isClientProfileLoading && clientProfileData && (
                  <>
                    {clientProfileData.firstName && <div className={styles.name}>{clientProfileData.firstName}</div>}
                    {clientProfileData.dateOfBirth && (
                      <div className={styles.dateOfBirth}>{clientProfileData.dateOfBirth}</div>
                    )}
                    {clientProfileData.email && <div className={styles.email}>{clientProfileData.email}</div>}
                  </>
                )}
              </div>
            )}

            {headerMenuConfig.allowBookNewAppointment && bookNewAppointmentUrl && (
              <Button
                className={styles.bookAppointment}
                icon="add_circle_outline"
                iconClassName={styles.icon}
                iconVariant={IconVariant.Outlined}
                onClick={() => navigate(bookNewAppointmentUrl)}
              >
                Book new appointment
              </Button>
            )}
            {menuItems.map((item, index) => (
              <TaskItem key={index} {...item} onClickDefault={() => handleTaskItemClick(item.isExternal)} />
            ))}
            <div className={styles.signOut} onClick={logout}>
              Log out
            </div>
          </div>
          <TacklitFooter wrapperClassName={styles.footer} darkFooter />
        </div>
      </div>
    </div>
  );
};

export default HeaderMenu;
