import Button, { ButtonVariant } from 'components/Button/Button';
import styles from './ContactSupport.module.scss';
import classNames from 'classnames';
import { brandConfig } from 'brand/config';

interface ContactSupportProps {
  className?: string;
}

const ContactSupport = ({ className }: ContactSupportProps) => {
  return (
    brandConfig.link.contactUsUrl && (
      <div className={classNames(styles.container, className)}>
        <div className={styles.title}>Have a question?</div>
        {/* dangerouslySetInnerHTML? */}
        {brandConfig.link.contactUsText && <div className={styles.description}>{brandConfig.link.contactUsText}</div>}
        <Button
          className={styles.contactButton}
          variant={ButtonVariant.Outlined}
          onClick={() => (window.location.href = brandConfig.link.contactUsUrl)}
        >
          Get in touch with our support team
        </Button>
      </div>
    )
  );
};

export default ContactSupport;
