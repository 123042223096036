import { useCallback, useState } from 'react';
import styles from './SignupForm.module.scss';
import Input from 'components/Input/Input';
import DateInput from 'components/DateInput/DateInput';
import { DateFormat } from 'utils/dayjsExtended';
import MobilePhoneInput from 'components/MobilePhoneInput/MobilePhoneInput';
import PasswordRules from './components/PasswordRules/PasswordRules';
import CheckBox from 'components/CheckBox/CheckBox';
import Button, { ButtonStatus, ButtonVariant } from 'components/Button/Button';
import { useCustomLogo } from 'utils/hooks/useCustomLogo';
import { initialClient, SignUpErrorInterface, validationSignUpForm } from './constants';
import { brandConfig } from 'brand/config';
import { useCheckEmailAlreadyExistedMutation } from 'reduxToolkit/endpoints/clinicianProfileService/accounts';
import {
  CreateClientRecordPayload,
  useCreateClientRecordWithoutInvitationMutation,
  useCreateRecordOnlyWithoutInvitationMutation
} from 'reduxToolkit/endpoints/patientProfileService/client';
import { CommunicationPreference } from 'interfaces/Client/enums';
import { storeUserSession } from 'utils/userSession';
import { notification } from 'antd';
import ErrorMessageWithHelpLink from '../ErrorMessageWithHelpLink/ErrorMessageWithHelpLink';
import { useNavigate } from 'react-router-dom';
import { ENGAGE_ROUTES_LIST } from 'routes/constants';
import ErrorMessage from 'components/ErrorMessage/ErrorMessage';
import { capitalizeString } from 'utils/capitalizeString';

const SignupForm = () => {
  const navigate = useNavigate();
  const { SIGNUP } = ENGAGE_ROUTES_LIST;
  const [isPwValid, setIsPwValid] = useState(false);
  const [showPrivacyPolicyError, setShowPrivacyPolicyError] = useState(false);
  const [checkEmailAlreadyExisted] = useCheckEmailAlreadyExistedMutation();
  const [showPwError, setShowPwError] = useState(false);
  const [values, setValues] = useState<SignUpErrorInterface>(initialClient);
  const [checkValidation, setCheckValidation] = useState<boolean>(false);
  const [errors, setErrors] = useState<SignUpErrorInterface>();
  // const [receiveSMS, setReceiveSMS] = useState(true);
  const { darkLogo: darkCustomLogo } = useCustomLogo();
  const [agreePolicy, setAgreePolicy] = useState(true);
  const { brand } = brandConfig;
  // const navigate = useNavigate();
  const [buttonStatus, setButtonStatus] = useState<ButtonStatus>(ButtonStatus.Default);
  const { accountId, userSessionKey } = brandConfig;
  const [createClientRecordWithoutInvitation] = useCreateClientRecordWithoutInvitationMutation();

  const [createRecordOnlyWithoutInvitation] = useCreateRecordOnlyWithoutInvitationMutation();

  const [emailErrorMsg, setEmailErrorMsg] = useState<JSX.Element>();

  const validateField = useCallback((newVal: SignUpErrorInterface) => {
    const { validationErrors, cleanValidationError } = validationSignUpForm(newVal);
    setErrors(validationErrors);
    return cleanValidationError;
  }, []);

  const handleFieldChange = useCallback(
    (fieldName: keyof SignUpErrorInterface, value: string) => {
      const updatedField = {
        ...values,
        [fieldName]: value
      };
      validateField(updatedField);
      setValues(updatedField);
    },
    [validateField, values]
  );

  const handleCreateSecureAccount = async (isRecordOnly?: boolean) => {
    setShowPwError(true);
    setCheckValidation(true);
    setShowPrivacyPolicyError(true);

    const validate = validateField(values);

    //scrollErrorsIntoView(validate);
    const checkFieldHaveError = Object.values(validate).some((value) => value !== '');

    if (!checkFieldHaveError && agreePolicy && isPwValid && values.password) {
      setButtonStatus(ButtonStatus.Active);
      const checkEmailExisted = await checkEmailAlreadyExisted({
        accountId,
        email: values?.email || ''
      }).unwrap();

      if (checkEmailExisted.used === false) {
        try {
          const { email, firstName, lastName, password, dateOfBirth } = values;

          const payloadMassage: CreateClientRecordPayload['payload'] = {
            clinicianId: '',
            clientRecord: {
              avatar: '',
              mobile: values.mobileNumber,
              email,
              name: firstName,
              password,
              dateOfBirth,
              firstName,
              lastName,
              postcode: '',
              timeZone: brandConfig.defaultTimezone,
              communicationPreference: CommunicationPreference.None
            }
          };
          const callPatientSignUp = !isRecordOnly
            ? await createClientRecordWithoutInvitation({
                accountId,
                payload: payloadMassage
              }).unwrap()
            : await createRecordOnlyWithoutInvitation({
                accountId,
                payload: payloadMassage
              }).unwrap();

          const { clientRecord, authToken } = callPatientSignUp;
          setButtonStatus(ButtonStatus.Finished);
          storeUserSession({
            storageKey: userSessionKey,
            session: { clientRecord, authToken },
            dateOfBirthValue: dateOfBirth
          });
          //scrollToView('Header');
          navigate(SIGNUP.REQUIRED_INFO);
        } catch (ex) {
          setButtonStatus(ButtonStatus.Default);
          console.error(ex);
          notification.error({
            message: 'Something went wrong while trying to sign up account'
          });
        } finally {
          setTimeout(() => {
            setButtonStatus(ButtonStatus.Default);
          }, 1000);
        }
      } else {
        setButtonStatus(ButtonStatus.Default);
        //scrollErrorsIntoView({ email: 'Email address is already used' });
        setEmailErrorMsg(<ErrorMessageWithHelpLink errorMessage="Email address is already used." />);
      }
    }
  };

  return (
    <div className={styles.container}>
      <div>
        <div className={styles.basicFormContainer}>
          <div className={styles.nameWrapper}>
            <Input
              id="firstName"
              className={styles.nameInput}
              label="First Name*"
              name="firstName"
              required
              labelClass={styles.inputLabel}
              onChange={(e) => {
                handleFieldChange('firstName', e.target.value);
              }}
              errorMessage={checkValidation ? errors?.firstName : ''}
            />
            <Input
              id="lastName"
              className={styles.nameInput}
              label="Last Name*"
              name="lastName"
              required
              labelClass={styles.inputLabel}
              onChange={(e) => {
                handleFieldChange('lastName', e.target.value);
              }}
              errorMessage={checkValidation ? errors?.lastName : ''}
            />
          </div>
          <div className={styles.dobInputWrapper}>
            <DateInput
              id={'dateOfBirth'}
              label={'Date of Birth'}
              value={values.dateOfBirth || ''}
              format={DateFormat.DAY_MONTH_YEAR}
              onChange={(value) => {
                handleFieldChange('dateOfBirth', value);
              }}
            />
          </div>
          <div className={styles.phoneInputWrapper}>
            <MobilePhoneInput
              id={'mobileNumber'}
              label={'Mobile Phone Number'}
              value={values.mobileNumber || ''}
              onChange={(value) => {
                handleFieldChange('mobileNumber', value);
              }}
              error={checkValidation ? errors?.mobileNumber : ''}
            />
          </div>
          <Input
            id="email"
            className={styles.emailInput}
            label="Email address*"
            name="email"
            required
            onChange={(e) => {
              handleFieldChange('email', e.target.value);
              setEmailErrorMsg(<></>);
            }}
            labelClass={styles.inputLabel}
            errorMessage={checkValidation ? errors?.email || emailErrorMsg : ''}
          />
        </div>
        <div className={styles.createAccountContainer}>
          <div className={styles.createAccountHeader}>
            <div className={styles.createAccountTitle}>
              Create secure account
              <img className={styles.logoImage} src={darkCustomLogo} alt={`logo`} />
            </div>
            <div className={styles.createAccountSubTitle}>
              {capitalizeString(brand)} partner with health technology specialists Tacklit to power your experience.
              Create an encrypted password to securely access your account. Learn more about Tacklit
            </div>
          </div>
          <Input
            id="password"
            className={styles.passwordInput}
            label="Set Secure Password*"
            name="password"
            type="password"
            required
            value={values.password}
            errorMessage={
              checkValidation && showPwError ? errors?.password || (!isPwValid ? 'This password is too weak' : '') : ''
            }
            labelClass={styles.inputLabel}
            allowShowPassword
            showPasswordButtonClass={styles.showPasswordButtonClass}
            onChange={(e) => {
              handleFieldChange('password', e.target.value);
            }}
          />
          {/* <div className={classNames(styles.passwordHint, showPwError && styles.passwordHintWithError)}> */}
          {/* <div className={classNames(styles.passwordHint)}></div> */}
          It must meet the following password rules:
          <div className={styles.hints}>
            <PasswordRules
              onChangePasswordValid={(value) => {
                setIsPwValid(value);
              }}
              password={values.password || ''}
            />
          </div>
          <div className={styles.checkBoxText}>
            <CheckBox
              id="agreePolicy"
              value={agreePolicy}
              onChange={(e) => setAgreePolicy(e.target.checked)}
              className={styles.checkBox}
              label="Agree to Tacklit Privacy Policy and Terms of Use"
            />
            <ErrorMessage
              error={'Please agree to Privacy Policy and Terms of Use'}
              visible={showPrivacyPolicyError && !agreePolicy}
            />
          </div>
          <div className={styles.buttonWrapper}>
            <Button
              className={styles.submitButton}
              status={buttonStatus}
              type="submit"
              onClick={() => {
                handleCreateSecureAccount();
              }}
            >
              Create secure account
            </Button>

            <Button
              // containerClassName={styles.submitButtonWrapper}
              className={styles.submitButton}
              //status={buttonStatus}
              // type="submit"
              onClick={() => {
                handleCreateSecureAccount();
              }}
              variant={ButtonVariant.Outlined}
            >
              Skip for now
            </Button>
          </div>
        </div>
      </div>
    </div>
    // </div>
  );
};

export default SignupForm;
