import { FitTimesList } from 'interfaces/Engage/fitFilter';
import { defaultClinicianTimezone } from 'utils/constants/timezone';
import dayjs from 'utils/dayjsExtended';

export const getFitTimeFilter = ({ time, timezone }: { time?: FitTimesList; timezone: string }) => {
  if (time === FitTimesList.morning) {
    return {
      startTime: '00:00',
      endTime: dayjs.tz('12:00', 'HH:mm', timezone).tz(defaultClinicianTimezone).format('HH:mm')
    };
  }

  if (time === FitTimesList.afternoon) {
    return {
      startTime: dayjs.tz('12:00', 'HH:mm', timezone).tz(defaultClinicianTimezone).format('HH:mm'),
      endTime: dayjs.tz('18:00', 'HH:mm', timezone).tz(defaultClinicianTimezone).format('HH:mm')
    };
  }

  if (time === FitTimesList.evening) {
    return {
      startTime: dayjs.tz('18:00', 'HH:mm', timezone).tz(defaultClinicianTimezone).format('HH:mm'),
      endTime: '24:00'
    };
  }

  return {};
};
