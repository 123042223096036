import ContentLayout from 'components/BaseComponent/ContentLayout/ContentLayout';
import queryString from 'query-string';
import { useLocation, useNavigate } from 'react-router-dom';
import styles from './404.module.scss';
import HelmetWrapper from 'components/HelmetWrapper/HelmetWrapper';

const NotFound404 = () => {
  const navigate = useNavigate();
  const { search } = useLocation();
  const { returnTo } = queryString.parse(search);

  const onClickBackBtn = () => {
    navigate('/');
  };

  return (
    <HelmetWrapper>
      <ContentLayout>
        <h1>404 Not Found</h1>
        <h2>The resource you are trying to access cannot be found or does not exist.</h2>
        <div className={styles.back}>
          {returnTo === 'home' ? (
            <div className={styles.backBtn} onClick={() => navigate('/')}>
              <i className={`material-icons ${styles.icon}`}>keyboard_arrow_left</i>
              Return to the home page
            </div>
          ) : (
            <div className={styles.backBtn} onClick={onClickBackBtn}>
              <i className={`material-icons ${styles.icon}`}>keyboard_arrow_left</i>
              Return to the previous page
            </div>
          )}
        </div>
      </ContentLayout>
    </HelmetWrapper>
  );
};

export default NotFound404;
