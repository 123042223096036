import { debounce } from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import usePlacesService from 'react-google-autocomplete/lib/usePlacesAutocompleteService';

const parseAUGooglePlaceAddress = (place: google.maps.places.PlaceResult | undefined | null) => {
  const line1Candidates: {
    subpremise?: string;
    streetNumber?: string;
    route?: string;
  } = {};
  let state = '';
  let suburb = '';
  let postcode = '';
  let country = '';

  for (const component of place?.address_components || []) {
    const componentType = component.types[0];
    switch (componentType) {
      case '"subpremise"': {
        line1Candidates.subpremise = component.short_name;
        break;
      }

      case 'street_number': {
        line1Candidates.streetNumber = component.long_name;
        break;
      }

      case 'route': {
        line1Candidates.route = component.short_name;
        break;
      }

      case 'postal_code': {
        postcode = `${component.long_name}${postcode}`;
        break;
      }

      case 'postal_code_suffix': {
        postcode = `${postcode}-${component.long_name}`;
        break;
      }

      case 'locality': {
        suburb = component.long_name;
        break;
      }

      case 'administrative_area_level_1': {
        state = component.short_name;
        break;
      }

      // if uk then always use postal_town, ignore administrative_area_level_1
      case 'postal_town': {
        state = component.short_name;
        break;
      }

      case 'country': {
        country = component.long_name;
        break;
      }
    }
  }
  const newAddress = {
    line1:
      place?.name ||
      `${line1Candidates.subpremise || ''}${line1Candidates.subpremise && ' '}${line1Candidates.streetNumber || ''}${
        line1Candidates.streetNumber && ' '
      }${line1Candidates.route || ''}`,
    line2: '',
    state,
    suburb,
    postcode,
    country
  };

  return {
    description: place?.formatted_address ?? '',
    details: newAddress
  };
};

const parseUKGooglePlaceAddress = (place: google.maps.places.PlaceResult | undefined | null) => {
  const line1Candidates: {
    subpremise?: string;
    streetNumber?: string;
    route?: string;
  } = {};
  let state = '';
  let suburb = '';
  let postcode = '';
  let country = '';

  for (const component of place?.address_components || []) {
    const componentType = component.types[0];
    switch (componentType) {
      case '"subpremise"': {
        line1Candidates.subpremise = component.short_name;
        break;
      }

      case 'street_number': {
        line1Candidates.streetNumber = component.long_name;
        break;
      }

      case 'route': {
        line1Candidates.route = component.short_name;
        break;
      }

      case 'postal_code': {
        postcode = `${component.long_name}${postcode}`;
        break;
      }

      case 'postal_code_suffix': {
        postcode = `${postcode}-${component.long_name}`;
        break;
      }

      case 'administrative_area_level_2': {
        state = component.long_name;
        break;
      }

      case 'administrative_area_level_1': {
        country = component.short_name;
        break;
      }

      case 'postal_town': {
        suburb = component.short_name;
        break;
      }

      case 'country': {
        if (!country) {
          country = component.long_name;
        }
        break;
      }
    }
  }
  const newAddress = {
    line1:
      place?.name ||
      `${line1Candidates.subpremise || ''}${line1Candidates.subpremise && ' '}${line1Candidates.streetNumber || ''}${
        line1Candidates.streetNumber && ' '
      }${line1Candidates.route || ''}`,
    line2: '',
    state,
    suburb,
    postcode,
    country
  };

  return {
    description: place?.formatted_address ?? '',
    details: newAddress
  };
};

export interface Address {
  description: string;
  details: {
    line1: string;
    line2: string;
    state: string;
    suburb: string;
    postcode: string;
    country: string;
  };
}

export const useAddressSuggestion = (keyword: string, region: string = 'au') => {
  const [addresses, setAddresses] = useState<Address[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  const { placesService, placesAutocompleteService } = usePlacesService({
    apiKey: process.env.REACT_APP_GOOGLE_API_KEY
  });

  const populateAddresses = useMemo(() => {
    console.log('memo');

    return debounce(async (keyword: string) => {
      if (!placesAutocompleteService || !placesService) {
        return;
      }

      const predictions = await placesAutocompleteService?.getPlacePredictions({
        input: keyword,
        componentRestrictions: {
          country: region
        }
      });

      const addresses = await Promise.all<google.maps.places.PlaceResult | null>(
        (predictions?.predictions || []).map((prediction) => {
          return new Promise((resolve) => {
            try {
              placesService?.getDetails(
                {
                  placeId: prediction.place_id
                },
                (placeDetails) => {
                  resolve(placeDetails);
                }
              );
            } catch (ex) {
              console.error(ex);
            }
          });
        })
      );

      setAddresses(
        addresses
          .filter((address) => address !== null)
          .map(process.env.REACT_APP_GAE_REGION === 'gb' ? parseUKGooglePlaceAddress : parseAUGooglePlaceAddress)
      );
      setIsLoading(false);
    }, 500);
  }, [placesAutocompleteService, placesService, region]);

  useEffect(() => {
    setIsLoading(!!keyword);

    if (keyword) {
      populateAddresses(keyword);
    } else {
      setAddresses([]);
    }
  }, [keyword, populateAddresses]);

  return {
    addresses: addresses,
    isLoading
  };
};
