import styles from './ResetPasswordWithFakeInput.module.scss';
import Button, { ButtonVariant } from 'components/Button/Button';

const ResetPasswordWithFakeInput = () => {
  const handleClick = () => {
    alert('alex pls fix me');
  };

  return (
    <div className={styles.container}>
      <div className={styles.fakeInputContainer}>
        <div className={styles.label}>Password</div>
        <div className={styles.fakeInput}>*********</div>
      </div>
      <Button className={styles.button} variant={ButtonVariant.Outlined} icon="lock" onClick={handleClick}>
        Reset Password
      </Button>
    </div>
  );
};

export default ResetPasswordWithFakeInput;
