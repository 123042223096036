import { brandConfig } from 'brand/config';

interface ErrorMessageWithHelpLinkProps {
  errorMessage: string;
  showEmailOnly?: boolean;
}

const ErrorMessageWithHelpLink = ({ errorMessage, showEmailOnly }: ErrorMessageWithHelpLinkProps) => {
  const { contactLink } = brandConfig;
  const { email } = contactLink;

  return (
    <>
      {errorMessage}{' '}
      {showEmailOnly ? (
        <a href={`mailto:${email}`} target="_blank" rel="noreferrer">
          {email}
        </a>
      ) : (
        <>
          Otherwise{' '}
          <a href={`mailto:${email}`} target="_blank" rel="noreferrer">
            contact our support team
          </a>{' '}
          for assistance.
        </>
      )}{' '}
    </>
  );
};

export default ErrorMessageWithHelpLink;
