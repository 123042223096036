import { Modal } from 'antd';
import styles from './FitFilterMobilePopup.module.scss';
import FitFilterMobileItem from '../FitFilterMobileItem/FitFilterMobileItem';
import { useEffect, useState } from 'react';
import { Options } from 'interfaces/common/general';
import Button, { ButtonVariant } from 'components/Button/Button';
import {
  FitDaysList,
  FitLanguageList,
  FitPsychologistGenderList,
  FitStyleList,
  FitTimesList,
  PsychologistFitFilter
} from 'interfaces/Engage/fitFilter';

export interface FitFilterInterface {
  gender?: Options;
  days?: Options[];
  time?: Options;
  language?: Options;
  style?: Options;
}

interface FitFilterMobilePopupProps {
  visible: boolean;
  filterIsSelected: string;
  genderOptions: Options[];
  dayOptions: Options[];
  timeOptions: Options[];
  languageOptions: Options[];
  styleOptions: Options[];
  selectedFiltersProp: FitFilterInterface;
  onView: (value: PsychologistFitFilter) => void;
  onClearAll: () => void;
  onCancel: () => void;
}

const FitFilterMobilePopup = ({
  visible,
  filterIsSelected,
  genderOptions,
  dayOptions,
  timeOptions,
  languageOptions,
  styleOptions,
  selectedFiltersProp,
  onView,
  onClearAll,
  onCancel
}: FitFilterMobilePopupProps) => {
  const [selectedFilters, setSelectedFilters] = useState<FitFilterInterface>(selectedFiltersProp);

  useEffect(() => {
    setSelectedFilters(selectedFiltersProp);
  }, [selectedFiltersProp]);

  const handleChangeFilters = (value: Options[], key: string, isMultiSelect?: boolean) => {
    setSelectedFilters({
      ...selectedFilters,
      [key]: isMultiSelect ? value : value[0]
    });
  };

  const handleView = () => {
    const values: PsychologistFitFilter = {
      gender: selectedFilters.gender?.value as FitPsychologistGenderList,
      days: selectedFilters.days?.map((item) => item.value) as FitDaysList[],
      time: selectedFilters.time?.value as FitTimesList,
      language: selectedFilters.language?.value as FitLanguageList,
      style: selectedFilters.style?.value as FitStyleList
    };

    onView(values);
    onCancel();
  };

  const handleClearAll = () => {
    onClearAll();
    onCancel();
  };

  const { gender, days, time, language, style } = selectedFilters;

  return (
    <Modal
      visible={visible}
      destroyOnClose
      zIndex={2147483648} // Chat Widget z-index is 2147483647
      onCancel={onCancel}
      className={styles.modalClass}
      width={'100%'}
      style={{ top: 0, margin: 0, maxWidth: '100%', padding: 0 }}
      title={
        <div className={styles.modalHeader}>
          <div className={styles.title}>Refine your fit</div>
          <div className={styles.subTitle}>Filter psychologists by...</div>
        </div>
      }
      footer={
        <div className={styles.modalFooter}>
          <Button fullWidth onClick={handleView}>
            View
          </Button>
          <Button variant={ButtonVariant.Outlined} className={styles.clearAll} fullWidth onClick={handleClearAll}>
            Clear all
          </Button>
        </div>
      }
    >
      <div className={styles.container}>
        <div className={styles.filterWrapper}>
          <div id="gender" className={styles.filterItem}>
            <FitFilterMobileItem
              classname={styles.filter}
              id="gender"
              optionList={genderOptions}
              filterLabel="Gender"
              selectedValues={gender ? [gender] : []}
              isExpandProp={filterIsSelected === 'gender'}
              onChangeValue={(value) => {
                handleChangeFilters(value, 'gender');
              }}
            />
          </div>
          <div className={styles.filterItem}>
            <FitFilterMobileItem
              id="days"
              optionList={dayOptions}
              filterLabel="Days"
              selectedValues={days}
              isExpandProp={filterIsSelected === 'days'}
              onChangeValue={(value) => {
                handleChangeFilters(value, 'days', true);
              }}
              isMultipleSelect
            />
          </div>
          <div id="time" className={styles.filterItem}>
            <FitFilterMobileItem
              id="item"
              optionList={timeOptions}
              filterLabel="Timing"
              selectedValues={time ? [time] : []}
              isExpandProp={filterIsSelected === 'time'}
              onChangeValue={(value) => {
                handleChangeFilters(value, 'time');
              }}
            />
          </div>
          <div id="language" className={styles.filterItem}>
            <FitFilterMobileItem
              id="language"
              optionList={languageOptions}
              filterLabel="Language"
              selectedValues={language ? [language] : []}
              isExpandProp={filterIsSelected === 'language'}
              onChangeValue={(value) => {
                handleChangeFilters(value, 'language');
              }}
            />
          </div>
          <div id="style" className={styles.filterItem}>
            <FitFilterMobileItem
              id="style"
              optionList={styleOptions}
              filterLabel="Style"
              selectedValues={style ? [style] : []}
              isExpandProp={filterIsSelected === 'style'}
              onChangeValue={(value) => {
                handleChangeFilters(value, 'style');
              }}
            />
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default FitFilterMobilePopup;
