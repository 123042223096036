import { components, OptionProps } from 'react-select';
import { CountryCodeOptionInterface } from '../../CountryCodeSelect';
import CountryCodeDetails from '../CountryCodeDetails/CountryCodeDetails';

const CountryCodeOption = ({ children, ...props }: OptionProps<CountryCodeOptionInterface>) => {
  return (
    <components.Option {...props}>
      <CountryCodeDetails data={props.data} />
    </components.Option>
  );
};

export default CountryCodeOption;
