import { clinicianProfileServicesApiSlice, CPSTagTypes } from '../../services/clinicianProfileServicesApiSlice';
import { PublicPracticeProfile } from 'interfaces/Practice';
import {
  AvailabilitiesInterface,
  NextAvailability,
  PractitionerDetailsInterface,
  TimeSlotsInterface
} from 'interfaces/Engage/publicProfile';
import queryString from 'query-string';
import { PsychologistFitFilter } from 'interfaces/Engage/fitFilter';
import dayjs from 'utils/dayjsExtended';

export interface ClinicianListParam extends PsychologistFitFilter {
  page?: number;
  perPage?: number;
  clinicianIds?: string;
  startTime?: string;
  endTime?: string;
  isNewClient?: boolean;
}

export interface ClinicianListRequestPayload {
  slugUrl: string;
  params?: ClinicianListParam;
  clientTimeZone: string;
  infiniteLoad?: boolean;
}

export interface ClinicianListResponse {
  clinicians: PractitionerDetailsInterface[];
  paging: { page: number; perPage: number; totalItem: number };
  matchedSpecialisations: string[];
}

export interface CheckEmailAlreadyExistedResponse {
  used: boolean;
  isFullProfileType?: boolean;
}

const massageTimeSlot = ({
  clientTimeZone,
  timeSlotList
}: {
  clientTimeZone: string;
  timeSlotList: TimeSlotsInterface[];
}) =>
  timeSlotList.map((timeSlotObj) => {
    // const clientStartTimeZone = momentTz.tz(timeSlotObj.startDateTime, clientTimeZone).format('hh:mmA');
    // const clientEndTimeZone = momentTz.tz(timeSlotObj.endDateTime, clientTimeZone).format('hh:mmA');
    const clientStartTimeZone = dayjs.tz(timeSlotObj.startDateTime, clientTimeZone).format('hh:mma'); // Lowercase 'a' ensures the AM/PM is displayed in lowercase

    const clientEndTimeZone = dayjs.tz(timeSlotObj.endDateTime, clientTimeZone).format('hh:mma');

    return {
      ...timeSlotObj,
      startTime: clientStartTimeZone,
      endTime: clientEndTimeZone
    };
  });

const massageAvailabilities = ({
  availabilitiesList,
  clientTimeZone
}: {
  availabilitiesList: AvailabilitiesInterface[];
  clientTimeZone: string;
}) =>
  availabilitiesList.map((availabilitiesObj) => ({
    ...availabilitiesObj,
    timeSlots: massageTimeSlot({
      clientTimeZone,
      timeSlotList: availabilitiesObj.timeSlots
    })
  }));

const massageNextAvailabilities = ({
  nextAvailabilitiesList = [],
  clientTimeZone
}: {
  nextAvailabilitiesList?: NextAvailability[];
  clientTimeZone: string;
}) =>
  nextAvailabilitiesList
    .filter(({ shouldHideFromListing }) => !shouldHideFromListing)
    .map((nextAvailabilitiesObj) => ({
      ...nextAvailabilitiesObj,
      availabilities: massageAvailabilities({
        availabilitiesList: nextAvailabilitiesObj.availabilities,
        clientTimeZone
      }).filter(({ timeSlots }) => timeSlots.length)
    }));

const clientRecordsApiSlice = clinicianProfileServicesApiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getPracticeInfo: builder.query<PublicPracticeProfile, { slugUrlOrAccountId: string }>({
      query: ({ slugUrlOrAccountId }) => ({
        url: `public/accounts/${slugUrlOrAccountId}/practiceInfo`
      }),
      providesTags: [CPSTagTypes.AccountPracticeInfo]
    }),
    getAccountClinicianList: builder.query<ClinicianListResponse, ClinicianListRequestPayload>({
      query: ({ slugUrl, params }) => {
        const stringifiedQueryString = params ? `?${queryString.stringify(params)}` : '';
        return {
          url: `/accounts/${slugUrl}/clinicians${stringifiedQueryString}`
        };
      },
      serializeQueryArgs: ({ endpointName, queryArgs }) => {
        const { page, perPage, ...filteredObject } = queryArgs.params || {};

        return queryArgs?.infiniteLoad
          ? `${endpointName}_${queryString.stringify(filteredObject || {})}`
          : `${endpointName}_${queryString.stringify(queryArgs?.params || {})}`;
      },
      merge: (currentCache, newItems, meta) => {
        if (meta.arg.infiniteLoad) {
          if (meta.arg.params?.page === 1) {
            return {
              clinicians: newItems.clinicians,
              matchedSpecialisations: newItems.matchedSpecialisations,
              paging: newItems.paging
            };
          }

          return {
            clinicians: [...currentCache.clinicians, ...newItems.clinicians],
            matchedSpecialisations: [
              ...(currentCache.matchedSpecialisations ?? []),
              ...(newItems.matchedSpecialisations ?? [])
            ],
            paging: newItems.paging
          };
        } else {
          return {
            clinicians: newItems.clinicians,
            matchedSpecialisations: newItems.matchedSpecialisations,
            paging: newItems.paging
          };
        }
      },
      transformResponse: (response: ClinicianListResponse, _meta, arg) => {
        return {
          ...response,
          clinicians: response.clinicians.map((practitionerObj) => {
            const nextAvailabilities = massageNextAvailabilities({
              nextAvailabilitiesList: practitionerObj.matchedNextAvailabilities || [],
              clientTimeZone: arg.clientTimeZone
            });

            const nextAvailability = nextAvailabilities
              .filter(({ availabilities }) => availabilities.length)
              .sort((availability1, availability2) => {
                const earliestAvailability1 = availability1.availabilities.filter(
                  ({ timeSlots }) => timeSlots.length
                )[0];
                const earliestAvailabilityTimeSlot1 = earliestAvailability1.timeSlots[0];

                const earliestAvailability2 = availability2.availabilities.filter(
                  ({ timeSlots }) => timeSlots.length
                )[0];
                const earliestAvailabilityTimeSlot2 = earliestAvailability2.timeSlots[0];

                if (earliestAvailability1.date < earliestAvailability2.date) {
                  return -1;
                } else if (earliestAvailability1.date > earliestAvailability2.date) {
                  return 1;
                } else if (earliestAvailabilityTimeSlot1.startTime < earliestAvailabilityTimeSlot2.startTime) {
                  return -1;
                } else if (earliestAvailabilityTimeSlot1.startTime > earliestAvailabilityTimeSlot2.startTime) {
                  return 1;
                }

                return 0;
              })[0]?.availabilities[0];
            const nextAvailableTimeSlot = nextAvailability?.timeSlots[0];

            return {
              ...practitionerObj,
              nextAvailabilities,
              ...(nextAvailability && {
                nextAvailableTimeSlot: {
                  date: nextAvailability?.date,
                  time: nextAvailableTimeSlot?.startTime
                }
              })
            };
          })
        };
      },
      forceRefetch: ({ currentArg, previousArg }) =>
        currentArg?.params?.page !== previousArg?.params?.page && currentArg?.params?.page !== 1,
      providesTags: [CPSTagTypes.ClinicianList]
    }),
    checkEmailAlreadyExisted: builder.mutation<CheckEmailAlreadyExistedResponse, { accountId: string; email: string }>({
      query: ({ accountId, email }) => ({
        url: `/accounts/${accountId}/client-records:checkEmailAlreadyExisted`,
        method: 'PATCH',
        body: {
          email
        }
      })
    })
  })
});

export const { useGetPracticeInfoQuery, useGetAccountClinicianListQuery, useCheckEmailAlreadyExistedMutation } =
  clientRecordsApiSlice;
