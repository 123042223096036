import { useGetAttachedClinicianDetails } from 'utils/hooks/clinicianProfileService/getClientDetails';
import styles from './Suggestions.module.scss';
import { PORTAL_ROUTES_LIST, ENGAGE_ROUTES_LIST } from 'routes/constants';
import { generatePath } from 'react-router-dom';
import TaskItem from 'components/TaskItem/TaskItem';

const { APPOINTMENT_OVERVIEW, UPDATE_PROFILE } = PORTAL_ROUTES_LIST;
const { PRACTITIONER } = ENGAGE_ROUTES_LIST;

const Suggestions = () => {
  const { attachedClinicianDetail } = useGetAttachedClinicianDetails();

  // TODO: rework this to make it target new appointment booking page
  const bookNewAppointmentUrl = attachedClinicianDetail?.clinician
    ? generatePath(PRACTITIONER.DETAILS, {
        clinicianId: attachedClinicianDetail.clinician._id
      })
    : PRACTITIONER.LISTING;

  return (
    <div className={styles.container}>
      {bookNewAppointmentUrl && (
        <div>
          <div className={styles.title}>Would you like to...</div>
          <TaskItem className={styles.taskButton} name="Book a new appointment" url={bookNewAppointmentUrl} />
        </div>
      )}
      <div>
        <div className={styles.title}>Or...</div>
        <TaskItem className={styles.taskButton} name="View my appointments and invoices" url={APPOINTMENT_OVERVIEW} />
        <TaskItem className={styles.taskButton} name="Manage my profile" url={UPDATE_PROFILE} />
      </div>
    </div>
  );
};

export default Suggestions;
