import dayjs from 'utils/dayjsExtended';
import * as Yup from 'yup';

export interface SignUpErrorInterface {
  firstName: string;
  lastName: string;
  email: string;
  mobileNumber: string;
  dateOfBirth: string;
  password?: string;
}

export const initialClient = {
  firstName: '',
  lastName: '',
  email: '',
  mobileNumber: '',
  dateOfBirth: '',
  password: ''
};

export const getUkMobile = (v: string) => (v ? '+44' + (v.startsWith('0') ? v.slice(1) : v) : `+${v}`);

export const formDataValidationSchema = () =>
  Yup.object().shape({
    firstName: Yup.string().required('Please enter your first name'),
    lastName: Yup.string().required('Please enter your last name'),
    dateOfBirth: Yup.string()
      .required('Please enter your date of birth')
      .test('Date of birth check', 'Please enter a valid date of birth', (dob) => dayjs(dob, 'DD/MM/YYYY').isValid())
      .test('Age check', "Be sure that you're over the age of 18", (dob) =>
        dayjs(dob, 'DD/MM/YYYY').add(18, 'years').isBefore(dayjs())
      ),
    mobileNumber: Yup.string().required('Please enter your mobile number'),
    email: Yup.string().required('Please enter your email').email('Email must be in valid example@gmail.com format.'),
    password: Yup.string()
      .matches(/.*[!@#$%^&*-].*/, 'Password must meet requirements')
      .matches(/.*[A-Z].*/, 'Password must meet requirements')
      .matches(/.*[a-z].*/, 'Password must meet requirements')
      .matches(/.*[\d].*/, 'Password must meet requirements')
      .nullable()
  });

export const validationSignUpForm = (signUpFormVal: SignUpErrorInterface) => {
  const validationErrors = {
    firstName: '',
    lastName: '',
    email: '',
    mobileNumber: '',
    dateOfBirth: '',
    password: ''
  };
  try {
    formDataValidationSchema().validateSync(signUpFormVal, { abortEarly: false });
  } catch (ex) {
    if (ex instanceof Yup.ValidationError && ex.inner && ex.inner.length !== 0) {
      ex.inner.forEach((error: any) => {
        if (Object.keys(validationErrors).includes(error.path)) {
          validationErrors[error.path as keyof typeof validationErrors] = error.message;
        }
      });
    }
  }

  return {
    validationErrors,
    cleanValidationError: Object.fromEntries(Object.entries(validationErrors).filter(([_k, value]) => value !== ''))
  };
};
