import ContentLayout from 'components/BaseComponent/ContentLayout/ContentLayout';
import { useNavigate } from 'react-router-dom';

import styles from './403.module.scss';
import HelmetWrapper from 'components/HelmetWrapper/HelmetWrapper';

const Forbidden403 = () => {
  const navigate = useNavigate();
  const onClickBackBtn = () => {
    navigate('/');
  };

  return (
    <HelmetWrapper>
      <ContentLayout>
        <h1>403 Forbidden</h1>
        <h2>You do not have the permission to access this resource.</h2>
        <div className={styles.back}>
          <div className={styles.backBtn} onClick={() => onClickBackBtn()}>
            <i className={`material-icons ${styles.icon}`}>keyboard_arrow_left</i>
            Return to the previous page
          </div>
        </div>
      </ContentLayout>
    </HelmetWrapper>
  );
};

export default Forbidden403;
